// Comments
.c-comments {
    background: $grey-light;
    padding:40px 0;
    max-width: 860px;
    margin: 0 auto;

    .section-title {
    	font-size: 21px;
    }

    &_write {
    	display: flex;
    	flex-direction: row;
    	flex-wrap: nowrap;
    	width: 100%;
    }

    &_write-profil {
    	display: block;
    	width: 45px;
    	height: 45px;
    	border-radius: 50%;
    	background-color: $purple;
    	position: relative;

    	.icon {
    		color: $white;
    		font-size: 22px;
    		position: absolute;
    		top: 50%;
    		left: 50%;
    		transform: translate(-50%,-50%);
    	}
    }

    &_form {
    	width: calc(100% - 65px);
    	margin: 0 auto 40px auto;

    	.c-search_line {
    		background-color: rgba($grey-medium, 0.3);
    		height: auto;

    		input {
    			font-size: 15px;
    		}
    		
			textarea {
    			font-size: 15px;
				line-height: 28px;
				resize: none;
				width: 100%;
    		}
    	}

		.c-respond_button {
			margin: 20px 0 0 auto;
        	@extend .btn-2;
			font-size: 15px;
        	display: block;
        	@include m1024 {
            	margin: 20px 0 0 auto;
        	}
			transition: opacity 200ms;

			&.disabled {
				opacity: 0.6;
			}
		}
    }

    &_list {
    	margin-bottom: 50px;
    }

    &_more-list {
    	opacity: 0;
	    max-height: 0;
	    overflow: hidden;
	    transition: all .3s ease-out;
	    position: relative;

	    &.show-- {
	    	max-height: 99999px;
    		opacity: 1;

    		&:first-child {
    			margin-bottom: 30px;
    		}
	    }

	    .c-commentItem:first-child {
	    	border-top: 1px solid rgba($purple, 0.3);
			padding-top: 40px;
			margin-top: 40px;
	    }
    }
}
