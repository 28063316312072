.c-wysiwyg {
    h1 {
        @extend .title-2;
        margin: 5px 0;
        @include m1024 {
            margin-top: 20px;
        }
    }
    h2 {
        font-size: 17px;
        line-height: 1.2;
        @include black;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 20px;
        @include m1024 {
            font-size: 24px;
        }
    }
    h3 {
        font-size: 15px;
        line-height: 1.2;
        @include black;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 10px;
        @include m1024 {
            font-size: 18px;
        }
    }
    h4 {
        font-size: 14px;
        line-height: 1.2;
        @include extraBold;
        margin-top: 10px;
        margin-bottom: 10px;
        @include m1024 {
            font-size: 18px;
        }
    }
    p {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 1.2;
        @include m1024 {
            font-size: 18px;
        }
    }
    ul, ol {
        margin: 20px 0 0 20px;
        li {
            margin: 0 0 12px 0;
            font-size: 16px;
            line-height: 1.16;
            list-style-type: disc;
            @include m1024 {
                font-size: 18px;
            }
        }
    }
    ol {
        li {
            list-style: decimal;
        }
    }
    p, ul, ol {
        a {
            text-decoration: underline;
            text-underline-position: under;
            @include m1024 {
                transition: all .3s ease-in-out;
                &:hover {
                    text-underline-color: transparent;
                }
            }
        }
    }
}