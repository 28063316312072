.c-listArticles {
    position: relative;

    @include m1023 {
        overflow: hidden;
    }
    h2 {
        @extend .section-title;
        @include m1023 {
            font-size: 19px;
        }
    }
    &__slider {
        @include m1023 {
            margin-left: -9px;
            margin-right: -9px;
        }
        &.swiper-container {
            padding-top: 15px;
            padding-bottom: 20px;
            overflow: visible;
            @include m1024 {
                margin-right: -10px;
                padding-right: 10px;
            }
        }

        .swiper-wrapper {
            align-items: initial;
        }
        .swiper-slide {
            // max-width: 320px;
            // max-width: 229px;
            max-width: calc(25% - 10px);
            min-width: 158px;
            height: auto;
            @include m374 {
                min-width: 138px;
            }

            &:not(:last-child) {
                margin-right: 14px;
            }

            &.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
                opacity: 0.5;
            }
        }
        .c-article {
            // width: calc(100% - 12px);
            height: 100%;
        }
    }
    &__list {
        @include flex(flex-start, initial, row, wrap);
        margin-top: 16px;
        // margin-left: -6px;
        // margin-right: -6px;

        .c-article {
            width: calc(100% / 4 - 12px);

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}