.c-stepText {
    &_title {
        @extend .title-2;
        margin-bottom:20px;
    }
    &_visuel {
        width:calc(50% - 8px);
        height:auto;
        text-align: center;
        @include m1024 {
            width:32.37%;
        }
        @include m767 {
            width: calc(50% - 8px);
        }
        img {
            margin: 0 auto 0 auto;
        }
    }

    &_content {
        padding-bottom: 25px;
        @include flex(space-between, flex-start, row-reverse, nowrap);
        @include m1024 {
            padding-bottom: 60px;
        }
        @include m767 {
            display: block;

            .t-landingPage_container &{
                display: flex;
            }
        }
        &:nth-child(odd) {
            flex-direction: row;
            .c-stepText_visuel {
                float: left;
                padding-right: 10px;
            }
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            .c-stepText_visuel {
                float: right;
                padding-left: 10px;
            }
        }
        .c-wysiwyg {
            width:calc(50% - 8px);
            @include m1024 {
                width:64.31%;
            }
            @include m767 {
                width: 100%;
            }
        }
    }
    @include m1023 {
        &_container {
            &.column-- {
                .c-stepText {

                    &_content {
                        flex-direction:column;
                        .c-wysiwyg {
                            width:100%;
                        }
                    }
                    &_visuel {
                        align-self: center;
                    }
                }
            }
        }
    }
}