.t-landingPage_container{
    .c-table{
        &:not(:first-child) {
            margin-top: 0;
            @include m1024 {
                margin-top: 0;
            }
        }
    }
}
.c-table {
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 35px;
    background-color: $grey-very-light;
    padding-bottom: 30px;
    &:not(:first-child) {
        margin-top: 30px;
        @include m1024 {
            margin-top: 48px;
        }
    }
    &_content{
        &:hover{
            cursor: move;
        }
    }
    &_title{
        @extend .title-2;
    }
    &_bottom{
        height: 20px;
    }
    &_scroll{
        width: 100%;
        height: 10px;
        background-color: #F4F4F4;
        position: sticky;
        bottom: 0px;
        z-index: 1;
        border-bottom: solid 20px white;
        border-top: solid 20px white;
        box-sizing: content-box;

        &--mobile{
            display: none;
        }

        @include m1023 {
            //top: 50px;
            bottom: 60px;
            z-index: 2;

            // &--mobile{
            //     display: block;
            // }

            // &--desktop{
            //     display: none;
            // }
        }

        &_regle{
            position: absolute;
            width: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            background-color: $purple;
            border-radius: 10px;
        }
    }
    table {
        position: relative;
        overflow: hidden;
        width: 100%!important;
        border: none;
    }
    caption {
        display: none;
    }
    td, th {
        vertical-align: middle;
        white-space: nowrap;
        user-select: none;
    }
    thead, tbody {
        tr {
            th, td {
                border: none;
                padding-left: 8px;
                padding-right: 8px;
                text-align: left;
                &:first-child {
                    padding-left: 32px;
                }
                &:last-child {
                    padding-right: 32px;
                }
            }
            th {
                padding-top: 36px;
                padding-bottom: 27px;
                background-color: $purple-light;
                @include black();
                font-size: 13px;
                color: $white;
                text-transform: uppercase;
                @include m1024 {
                    font-size: 18px;
                }
            }
        }
    }
    thead {
        tr:first-child {
            th {
                &:first-child {
                    border-top-left-radius: 35px;
                }
                &:last-child {
                    border-top-right-radius: 35px;
                }
            }
        }
    }
    tbody {
        border-top: 15px solid $grey-very-light;
        border-bottom: 0px solid $grey-very-light;
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
        tr {
            border-left: 30px solid $grey-very-light;
            border-right: 30px solid $grey-very-light;
            @include m1024 {
                border-left: 46px solid $grey-very-light;
                border-right: 46px solid $grey-very-light;
            }
            td, th {
                height: 40px;
                font-size: 13px;
                @include semiBold;
                color: $purple;
                padding-left: 15px !important;
                padding-right: 15px !important;
                @include m1024 {
                    height: 44px;
                    font-size: 18px;
                }
                &:first-child {
                    @include extraBold;
                    text-transform: uppercase;
                    margin-left: 35px;
                }
            }
            &:nth-child(even) {
                td, th {
                    z-index: 1;
                    position: relative;
                    background-color: $white;
                    &:first-child,
                    &:last-child {
                        &:before {
                            z-index: -1;
                            position: absolute;
                            top: 0;
                            content: '';
                            display: block;
                            width: 25px;
                            height: 100%;
                            background-color: $white;
                        }
                    }
                    &:first-child {
                        border-top-left-radius: 22px;
                        border-bottom-left-radius: 22px;
                        &:before {
                            left: -16px;
                            border-top-left-radius: 22px;
                            border-bottom-left-radius: 22px;
                        }
                    }
                    &:last-child {
                        border-top-right-radius: 22px;
                        border-bottom-right-radius: 22px;
                        &:before {
                            right: -16px;
                            border-top-right-radius: 22px;
                            border-bottom-right-radius: 22px;
                        }
                    }
                }
            }
        }
    }

}