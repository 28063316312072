* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
img{
  display: block;
  max-width: 100%;
  height: auto;
  flex-shrink: 0; // don't stretch img on ie11 with flex box
}
svg{
  fill: currentColor;
}