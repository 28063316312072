.t-univers {
    h1 {
        @extend .title-2;
    }
    .c-search {
        display: none;
    }
    .error--.msg-- {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}