.c-communityCarouselUploads {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($purple-dark, 0.75);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    &_container {
        background-color: $grey-very-light;
        max-width: 960px;
        width: 90%;
        border-radius: 40px;
        padding: 50px 0px 0%;
        position: relative;
        @include m1024 {
            padding: 100px 0px 0%;
        }
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 25px;
            height: 25px;
            transition: transform 0.2s linear;
            @include m1024 {
                width: 33px;
                height: 33px;
                top: 35px;
                right: 35px;
                &:hover {
                    transform: rotate(90deg);
                    transition: transform 0.2s linear;
                }
            }
            &:before, &:after {
                content: "";
                width: 100%;
                height: 4px;
                background-color: $purple;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
            }
            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
        .swiper-container {
            max-height: 50vh;
            padding: 0px 30px;
            @include m1024 {
                padding: 0px 100px;
                max-height: 700px;
            }
            .slide-img {
                width: 100%;
                height: 40vh;
                margin-bottom: 50px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                @include m1024 {
                    height: 550px;
                    margin-bottom: 100px;
                }
            }
            .slide-video {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40vh;
                @include m1024 {
                    height: 550px;
                    margin-bottom: 100px;
                }
                video {
                    width: 100%;
                    height: auto;
                }
            }
            .swiper-pagination-bullets {
                bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include m1024 {
                    bottom: 55px;
                }
                .swiper-pagination-bullet{
                    width: 10px;
                    height: 10px;
                    @include m1024 {
                        width: 18px;
                        height: 18px;
                        background-color: rgba($purple, 0.4);
                        transition: background-color 0.2s ease;
                    }
                    &-active {
                        transition: background-color 0.2s ease;
                        background-color: $purple;
                    }
                }
            }
        }
        .swiper-arrow {
            position: absolute;
            width: 20px;
            height: 20px;
            top: calc(50% - 25px);
            z-index: 1;
            cursor: pointer;
            &.swiper-button-disabled {
                cursor: initial;
                display: none;
                &:before, &:after {
                    background: $grey-light;
                }
            }
            &:before, &:after {
                content: "";
                position: absolute;
                display: block;
                width: 90%;
                height: 4px;
                left: 50%;
                background: $grey-medium;
                border-radius: 50px;
            }
            &:before {
                top: 3px;
                transform: translate(-50%,-50%) rotate(45deg);
            } 
            &:after {
                bottom: 3px;
                transform: translate(-50%,-50%) rotate(-45deg);
            } 
            &.prev-- {
                transform: rotate(180deg);
                left: 5px;
                @include m1024 {
                    left: 35px;
                }
            }
            &.next-- {
                right: 5px;
                @include m1024 {
                    right: 35px;
                }
            }
        }
    }
}