.t-404 {
    @include m1024 {
        background-color: $white;
    }
    .c-search {
        display: none;
    }
    &_content {
        @include m1024 {
            @include flex(center, center, row-reverse, nowrap);
            margin: 110px 0;
            min-height: calc(100vh - 433px - 220px);
        }
    }
    &_visuel {
        margin: 60px 0 10px 0;
        img {
            width: 100%;
        }
        @include m1024 {
            width: calc(54% + 20px);
            margin: 20px 0 0 -20px;
        }
    }
    &_capsule {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        background-color: $white;
        padding: 32px 38px 24px;
        margin-bottom: 85px;
        @extend .rounded;
        @include m1024 {
            margin-bottom: 0;
            width: 100%;
            max-width: 471px;
            padding: 35px 53px 29px 35px;
        }
        h1 {
            @extend .title-2;
            font-size: 27px;
            margin-bottom: 5px;
        }
        p {
            &:last-of-type {
                margin-top: 20px;
                @include m1024 {
                    margin-top: 58px;
                }
            }
        }
        .btn-3 {
            @include m1023 {
                width: 100%;
            }
            @include m1024 {
                padding-left: 36px;
                padding-right: 36px;
            }
        }
    }
    .c-footer {
        @include m1024 {
            //   border-top: 2px solid $grey-very-light;
            box-shadow: 2.1px 3.4px 13px 0 rgba(71, 72, 72, 0.13);
        }

    }
}