.c-video {
    width:100%;
    //height:235px;
    background: $black;
    margin-bottom:55px;//(pour enlever le -55px de outils qui superpose)
    @include m1024 {
        max-width: 1050px;
        //height:370px;
        margin:0 auto 30px auto;//(pour enlever le -55px de outils qui superpose)
    }

    &_iframeCtn {
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 56.25%;
        }
    }
    &_iframe {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        @include flex(center, center, column, nowrap);
    }
    &_button {
        width:65px;
        height:65px;
        font-size: 65px;
        color:$white;
        @include m1024 {
            width:129px;
            height:129px;
            font-size: 129px;
        }
    }
    &_cookie {
        width:100%;
        height:100%;
        @include flex(center, center, column, nowrap);
        background: rgba($color: $black, $alpha: .25);
        color:$white;
    }
}