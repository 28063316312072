.btnScrollToTop {
    color: red;
    position: fixed;
    bottom: 150px;
    right: 50px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.32);
    background-color: rgba(255, 255, 255, .8);
    transition: all 0.35s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 1;

    &--visible {
        opacity: 1;
        pointer-events: all;
    }

    &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border-top: solid 2px #ccc;
        border-left: solid 2px #ccc;
        transform: translateY(25%) rotate(45deg);
    }

    span {
        color: #5f205d;
        font-size: 0.85em;
        white-space: nowrap;
        position: absolute;
        top: calc(100% + 10px);
        opacity: 0;
        transition: opacity 0.35s ease;
        font-weight: bold;
    }

    &:hover {
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.64);

        span{
            opacity: 1;
        }
    }

    @include m1023 {
        bottom: 150px;
        right: 20px;
        width: 60px;
        height: 60px;

        span{
            opacity: 0 !important;
        }
    }
}