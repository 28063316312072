.c-communitySuccessForm {
    text-align: center;
    margin: 40px auto;
    min-height: 60vh;
    @include m1024 {
        margin: 120px auto;
        min-height: 40vh;
        text-align: left;
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &-title {
        font-size: 18px;
        line-height: 1.3;
        @include black;
        margin-bottom: 30px;
        text-transform: uppercase;
        @include m1024 {
            font-size: 24px;
            margin-bottom: 40px;
            align-self: flex-start;
        }
    }
    &-text {
        font-size: 16px;
        line-height: 1.1;
        @include bold;
        margin-bottom: 50px;
        @include m1024 {
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 90px;
            align-self: flex-start;
        }
    }
}