.goBack {
	@include m1024 {
        margin-left:55px;
    }
        
    &_btn {
    	font-size:11px;
        @include extraBold;
        @include m1024 {
            font-size: 15px;
        }

        &:before {
            display: inline-block;
            font-size: 9px;
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            margin: 0 5px;
            transform: rotate(180deg);
            transition: transform 0.3s ease;
            @include m1024 {
                font-size: 13px;
                margin: 0 8px;
            }
        }

        &:hover {
        	&:before {
	        	transform: translateX(-5px) rotate(180deg);
	        }
        }
    }
}