.t-operationsPage {
    background-color: $white;

    .c-wysiwyg h1,
    .c-wysiwyg .title {
        margin-bottom: 35px;
    }
    .c-wysiwyg p {
        line-height: 1.6;
    }

    &__upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        .error {
            color: $red;
            margin-top: 10px;
        }
        &-format {
            margin-top: 20px;
        }
        &.selected {
            box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
            border-radius: 35px;
            padding: 30px 20px;
            @include m1024 {
                padding: 50px 60px;
            }
            .t-operationsPage__upload-file-btn {
                background: transparent;
                color: $purple;
                border: 2px solid $purple;
            }
        }
        label {
            @include bold;
            font-size: 16px;
            margin-bottom: 12px;
            @include m1024 {
                font-size: 18px;
                width: 38%;
                margin-top: 50px;
            }
        }
        &-file {
            position: relative;
            input[type="file"] {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                margin-bottom: 0px;
            }
            &-name {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                width: 100%;
                p {
                    font-size: 12px;
                    @include bold;
                    line-height: 1.35;
                    display: inline-block;
                    padding-right: 20px;
                    @include m1024 {
                        font-size: 18px;
                    }
                }
                i {
                    cursor: pointer;
                    font-size: 20px;
                    @include m1024 {
                        font-size: 25px;
                    }
                }
            }
            &-btn {
                padding: 13px 20px;
                background: $purple;
                border-radius: 40px;
                font-size: 17px;
                color: $white;
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: left;
                @include m1024 {
                    padding: 19px 30px;
                    font-size: 20px;
                }
                i {
                    font-size: 20px;
                    margin-right: 15px;
                    @include m1024 {
                        font-size: 25px;
                    }
                }
            }
        }
    }

    .c-form {
        @include m1024 {
            margin-bottom: 20px;
        }

        &_line {
            margin-bottom: 20px;
            @include m1024 {
                margin-bottom: 40px;
            }
        }
        label {
            @include m1024 {
                font-size: 24px;
            }
        }
        &_checkbox {
            min-height: 25px;
            @include m1024 {
                min-height: 35px;
            }

            label {
                @include m1024 {
                    font-size: 22px;
                    padding-top: 7px;
                }
            }
        }
    }
    
    &_form-box {
        background-color: $grey-very-light;
        border-radius: 35px;
        box-shadow: 0 0 32px 0 rgba(141, 141, 141, 0.15);
        padding: 50px 40px 30px;
        margin-top: 30px;
        margin-bottom: 80px;

        .c-form {
            input {
                &::placeholder {
                    color: $purple;
                }
            }

            .address {
                @extend .btn-1;
                border-radius: 10px;

                &_line {
                    border-bottom: 1px solid rgba(112,112,112, 0.3);
                    margin-bottom: 15px;

                    p {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .c-visuelLegend_button {
        margin-bottom: 60px;
    }

    .c-table {
        p {
            font-size: 22px;
            font-weight: bold;
        }

        table {
            margin-top: 30px;

            // tbody {
            //     background-color: $white;
            //     border-color: $white;

            //     tr {
            //         border-color: $white;
            //     }

            //     tr:nth-child(even) td,
            //     tr:nth-child(even) th {
            //         &,
            //         &:first-child:before,
            //         &:last-child:before {
            //             background-color: #f9f8f8;
            //         }
            //     }
            // }
        }
    }

    &_blockquote {
        @extend .title-1;
        text-align: center;
        margin-bottom: 30px;
        @include m1024 {
            margin-bottom: 50px;
        }
    }

    &_cta {
        text-align: center;
        margin-bottom: 30px;
        @include m1024 {
            margin-bottom: 50px;
        }

        &--morespace{
            margin-top: -50px;
            margin-bottom: 50px;
        }
    }
}