.c-profil {
    &_information {
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.16);

        &:not(:nth-last-child(2)) {
            margin-bottom: 20px;
            @include m1024 {
                margin-bottom: 30px;
            }
        }

        .swiper-button-prev {
            display: none;
        }
        .swiper-button-next {
            color: $purple;
            height: 100%;
            top: 0;
            margin: 0;
            @include m1024 {
                left: calc(50% + 330px);
                right: auto;
                width: 150px;
                text-align: left;
            }

            &::after {
                margin-right: auto;
                @include m767 {
                    font-size: 25px;
                }
            }
        }

        .c-profil_information-slide {
            height: auto;
        }

        .c-maxiLink {
            height: 100%;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &_container {
                justify-content: flex-start;
            }
            &_container.noLink:after {
                opacity: 0;
            }
        }

        &.no-carousel .c-maxiLink {
            // &_container:after { opacity: 1; }
        }
    }

}