.c-communityHp-search {
    .container_search {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        background-color: $white;
        margin-bottom: 20px;
        @include m1024 {
            margin-bottom: 60px;
            padding-top: 40px;
        }
    }
    .container.results-- {
        min-height: 50vh;
        margin-bottom: 50px;
        @include m1024 {
            margin-bottom: 150px;
        }
    }
    &-title {
        @include black;
        font-size: 18px;
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 20px;
        @include m1024 {
            font-size: 24px;
            margin-bottom: 25px;
        }
    }
    &-form {
        background-color: $white;
        box-shadow: 0 0 16px 1.5px rgba(140, 140, 140, 0.2);
        border-radius: 40px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-bottom: 25px;
        @include m1024 {
            margin-bottom: 45px;
            padding: 0px 18px;
        }
        button {
            font-size: 20px;
            font-weight: bold;
            @include m1024 {
                font-size: 24px;
            }
        }
    }
    &-input {
        padding: 10px 15px;
        @include semiBold;
        color: $purple;
        width: 100%;
        font-size: 14px;
        line-height: 1;
        @include m1024 {
            font-size: 18px;
            padding: 20px 17px;
        }
        &::placeholder {
            color: $purple;
            font-style: italic;
        }
    }
    .search-thematics {
        position: relative;
        margin-bottom: 45px;
        @include m1024 {
            margin-bottom: 80px;
        }
        &.astuce-- {
            .search-thematics-title {
                color: $purple-light;
            }
            .search-thematics-slider-item {
                background-color: $purple-light;
            }
        }
        &.question-- {
            .search-thematics-title {
                color: $orange;
            }
            .search-thematics-slider-item {
                background-color: $orange;
            }
        }
        .swiper-button-next, .swiper-button-prev {
            color: $purple;
            top: auto;
            bottom: 40px;
            //display: none;
            @include m1024 {
                display: block;
            }
            &.swiper-button-disabled {
                opacity: 0;
            }
        }
        .swiper-button-prev {
            left: -10px;
            @include m1024 {
                left: -50px;
            }
            @include m1023 {
                transform: translateY(16px);
            }
        }
        .swiper-button-next {
            right: -10px;
            @include m1024 {
                right: -30px;
            }
            @include m1023 {
                transform: translateY(16px);
            }
        }
        &-title {
            @include extraBold;
            color: $purple-light;
            font-size: 20px;
            line-height: 1.5;
            margin-bottom: 20px;
            @include m1024 {
                font-size: 30px;
                margin-bottom: 0px;
            }
        }
        &-slider {
            position: relative;
            overflow: hidden;
            @include m1024 {
                overflow: hidden;
                padding: 18px 50px;
                margin-left: -50px;
            }
            &:after, &:before {
                content: "";
                position: absolute;
                top: 0;
                width: 50px;
                height: 100%;
                z-index: 2;

                @include m1024 {
                    display: block;
                }
            }
            &:before {
                background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
                left: 0;
            }
            &:after {
                background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
                right: 0;
            }
            &-slide {
                height: 90px;
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-item {
                border-radius: 35px;
                padding: 5px;
                text-align: center;
                font-size: 16px;
                line-height: 1.2;
                @include extraBold;
                color: $white;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                &.active {
                    background-color: $white!important;
                    box-shadow: 0px 6px 14px 2px rgba(72, 72, 72, 0.1);
                    color: $purple;
                }
                @include m1024 {
                    font-size: 20px;
                    line-height: 1.3;
                    padding: 10px;
                    
                }
            }
        }
    }
    .search-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 1.1;
        @include m1024 {
            font-size: 22px;
        }
        &.astuce-- {
            color: $purple-light;
        }
        &.question-- {
            color: $orange;
        }
        &-btn {
            text-transform: uppercase;
            @include extraBold;
            padding-bottom: 15px;
            width: calc(50% - 20px);
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
            @include m1024 {
                padding-bottom: 35px;
            }
            &.active {
                color: $purple;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    height: 5px;
                    width: calc(100% + 40px);
                    transform: translateX(-50%);
                    bottom: 0;
                    left: 50%;
                    right: 0;
                    background-color: $purple;
                    @include m1024 {
                        height: 7px;
                    }
                }
            }
        }
    }
    &-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        @include regular;
        margin: 40px auto 0px;
        @include m1024 {
            margin: 60px auto 0px;
        }
        li {
            font-size: 20px;
            line-height: 1.4;
            margin: 0px 5px;
            padding: 3px;
            border-radius: 4px;
            cursor: pointer;
            @include m1024 {
                font-size: 22px;
            }
            &.selected {
                background-color: $purple;
                color: $white;
            }
            &.disabled {
                opacity: 0;
                transition: opacity 0.2s ease;
            }
        }
    }
}