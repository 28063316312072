body {
    font-family: $main-font;
    font-size: 14px;
    //font-size: 16px;
    color: $purple;
    background: $grey-very-light;
    margin-top: 50px;
    text-underline-position: under;
    @include m1024 {
        font-size: 18px;
        margin-top: 75px;
    }
}

.app {
    max-width: 100vw;
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    @include m1024 {
        padding: 0 70px;
    }
    &--mini {
        max-width: 520px;
        @include m1024 {
            max-width: 850px;
        }
    }
}

.scrollLocked {
    @include m1099 {
        position: relative;
        overflow: hidden;
    }
}

