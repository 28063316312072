.c-outils {
    color:$pink;
    min-height:45px;
    @include flex(space-between, center, row, nowrap);
    @include m1024 {
        min-height:55px;
    }
    &_content {
        @include flex(flex-start, center, row, nowrap);
    }
    &_time {
        font-size: 13px;
        line-height: 1.21;
        @include extraBold;
        @include flex(center, center, row, nowrap);
        margin-right:10px;
        @include m1024 {
            font-size: 14px;
            margin-right:14px;
        }
        &:before {
            content:$icon-time;
            @extend .icomoon;
            font-size:17px;
            margin-right:6px;
            @include m1024 {
                font-size: 21px;
            }
        }
    }
    &_category {
        background:$pink;
        color:$white;
        padding:8px 14px;
        border-radius: 20px;
        font-size: 9px;
        @include black;
        text-transform: uppercase;
        margin-right:5px;
        @include m1024 {
            font-size: 13px;
        }
    }
    &_print {
        font-size:20px;
        margin-right:6px;
        @include m1024 {
            font-size: 24px;
            cursor: pointer;
        }
    }
}


.c-share {
    position:relative;
    font-size:20px;
    @include flex(center, center, row, nowrap);
    @include m1024 {
        font-size: 23px;
    }
    &_button {
        cursor:pointer;
    }
    .c-share_button+.c-share_pop:hover, .c-share_button:hover+.c-share_pop {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    &_arrow
    {
        width: 40px;
        height: 12px;
        position: absolute;
        bottom: 100%;
        right: -15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        overflow: hidden;
        @include m1024 {
            right:inherit;
            left: 50%;
        }
        &:after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background: #fff;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            top: 15px;
            left: 50%;
            box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: .16);
            z-index: 5;
        }
    }
    &_pop {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        padding: 03px 0;
        position: absolute;
        z-index: 6;
        top: calc(100% + 15px);
        right: -15px;
        width: 100%;
        min-width: 120px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: .16);
        transition: all .35s ease-in-out;
        font-size:20px;
        @include m1024 {
            right: -50px;
        }
        li:not(.c-share_arrow) {
            padding:5px 8px;
            font-size:14px;
            a {
                width:100%;
                @include flex(flex-start, center, row);
                font-family: $main-font!important;
                font-size:16px;
                color:$purple;
                transition: color ease-in-out .2s ;
                &:before {
                    @extend .icomoon;
                    width:25px;
                    margin-right:5px;
                    font-size:19px;
                }
                &.icon-share-email:before {
                    font-size:14px;
                }
                &:hover {
                    @include m1024 {
                        color:$purple-dark;
                    }
                }
            }
        }
    }
}