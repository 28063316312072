.t-accountEconomy {
    .c-search {
        display: none;
    }

    &_wrapper {
        margin: 30px 0 50px;
        @include m1024 {
            margin: 50px 0 80px;
        }
    }

    h1 {
        @extend .title-2;
        margin-bottom: 15px;
        @include m1024 {
            margin-bottom: 20px;
        }
    }
    &_content {
        background: $green;
        text-align: center;
        @extend .rounded2;
        color: $white;
        font-size: 14px;
        @include m1024 {
            font-size: 22px;
        }

        &-wrapper {
            position: relative;
            margin-bottom: 30px;
            @include m1024 {
                max-width: 470px;
                margin: 0 auto 50px auto;
            }
        }

        &-inner {
            padding: 20px;

            &:not(:last-child) {
                border-bottom: 1px solid $white;
            }
        }

        strong {
            font-size: 22px;
            @include black;
            @include m1024 {
                font-size: 36px;
            }
        }

        &-mention {
            color: $purple;
            font-size: 14px;
            text-align: left;
            padding: 0 15px;
            margin-top: 10px;
            @include m1024 {
                font-size: 18px;
            }
        }
    }

    &_table-wrapper {
        @extend .rounded2;
        @include m1024 {
            background-color: $white;
            padding: 30px;
        }
    }

    &_table-maintitle {
        font-size: 19px;
        @include bold;
        margin-bottom: 15px;
        @include m1024 {
            font-size: 22px;
        }
    }
    &_overflow {
        width:100%;
        overflow-x: auto;
    }
    &_tableContent {
        @extend .rounded2;
        background-color: $white;
        padding: 15px 0;
        margin-bottom:15px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        th {
            width:50%;
            padding:10px 13px;
            border-right: 1px solid $purple;
            font-weight: bold;
        }
        td {
            width:50%;
            padding:10px 13px;
        }
    }
    &_table {
        min-width: 100%;

        thead {
            border-bottom: 1px solid $purple;

            th {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right:5px;
            }
        }

        tbody {
            tr:first-child td {
                padding-top: 30px;
            }
        }

        td {
            @include semiBold;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right:5px;
        }
    }

    .status {
        &::before {
            content: '';
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: $orange-dark;
            margin-right: 10px;
            @include m767 {
                width: 7px;
                height: 7px;
                margin-right: 6px;
            }
        }

        &.accepted--,
        &.paid-- {
            &::before {
                background-color: $green;
            }
        }

        &.rejected--::before,
        &.refused--::before {
            background-color: $red;
        }
    }
}