.title-1 {
    font-size: 19px;
    text-transform: uppercase;
    @include black;
    &.lowercase-- {
        text-transform: initial;
    }
    @include m1024 {
        font-size: 23px;
    }
}

.title-2 {
    font-size: 17px;
    text-transform: uppercase;
    @include black;
    @include m1024 {
        font-size: 23px;
    }
}

.title-3 {
    font-size: 14px;
    @include semiBold;
    @include m1024 {
        font-size: 18px;
    }
}

.title-30 {
    font-size: 14px;
    @include black;
    @include m1024 {
        font-size: 30px;
        @include extraBold;
    }
}



.sep {
    margin-top: 5px;
    margin-bottom: 25px;
    height: 1px;
    background-color: $purple;
    @include m1024 {
        margin-bottom: 50px;
    }
}

.section-title {
    font-size: 22px;
    @include extraBold;
    text-transform: none;
    margin-bottom: 20px;
    @include m1024 {
        font-size: 30px;
    }
}

[class^="btn-"], [class*=" btn-"] {
    .loader {
        margin: 0 auto;
        padding: 0;
        .lds-ellipsis {
            height: 20px;
            div {
                top: 3px;
                background-color: $white;
            }
        }
    }
}

.btn-1 {
    padding: 14px 20px;
    border-radius: 45px;
    box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.05);
    background-color: $white;
    line-height: 1.25;
    border: 1px solid $white;

    &::placeholder {
        color: $grey-dark;
        opacity: 1; /* Firefox */
    }
    &:-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: $grey-dark;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $grey-dark;
    }
    @include m1024 {
        padding: 17px 30px;
        font-size: 18px;
    }
}

.btn-2 {
    display: inline-block;
    padding: 14px 28px;
    border-radius: 45px;
    background-color: $purple;
    font-size: 17px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
    color: $white;
    @extend .effetHoverScale;
    @include m1024 {
        font-size: 20px;
        //padding: 19px 30px;
        padding: 15px 35px;
    }
}

.btn-3 {
    @extend .btn-2;
    padding: 13px 20px;
    @include m1024 {
        padding: 19px 30px;
    }

    &--disabled{
        pointer-events: none;
        background-color: #e6e4e4;
    }
}

.btn-4{
    text-decoration: underline;
    font-weight: 900;
    font-family: $main-font ;

    &:hover{
        text-decoration: none;
    }

    @include m1024 {
    }
}

.c-button {
    margin-bottom: 20px;

    &_rs {
        @include flex(flex-start, stretch, column, nowrap);
        @include m1024 {
            @include flex(space-between, center, row, nowrap);
        }
        li {
            @include m1024 {
                width: calc((100% - 30px) / 3);
            }
        }
        &Item {
            @extend .btn-1;
            @extend .effetHoverScale;
            padding: 10px;
            width: 100%;
            margin-bottom: 8px;
            @include flex(flex-start, center, row, nowrap);
            @include m1024 {
                text-align: left;
            }
            &:before {
                content: '';
                position: relative;
                width: 20px;
                height: 24px;
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 13px;
                @include m1024 {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
    &_facebook {
        &:before {
            @extend .icomoon;
            content: $icon-facebook-rounded;
            font-size: 23px;
            color: #1877f2;
        }
    }
    &_google {
        &:before {
            background-image: url('#{$imgPath}picto-google.png');
        }
    }
    &_apple {
        &:before {
            @extend .icomoon;
            content: $icon-apple;
            color: $black;
            font-size: 24px;
        }
    }
    &.save-- {
        display: flex!important;
        align-items: center;
        position: sticky;
        z-index: 9;
        top: 30px;
        width: 185px;
        padding: 15px 32px!important;
        font-size: 18px!important;
        margin-left: auto !important;
        margin-right: -10px !important;
        transform: translate(100%, 143px);
        &:hover {
            transform: translate(100%, 143px) scale(1.08)!important;
        }
       
        &::before {
            content: "";
            display: inline-block;
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 8px;
            background-image: url('../../assets/img/save.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
        @include m1023 {
            transform: none;
            width: 56px;
            height: 56px;
            margin-right: 0px !important;
            margin-bottom: 0px !important;
            padding: 0px!important;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(45px);
            &:hover {
                transform: translateY(45px)!important;
            }
            &::before {
                margin-right: 0px;
            }
         span {
            display: none;
            transform: none;
         }   
        }
    }

}

.rounded {
    border-radius: 20px;
    @include m1024 {
        border-radius: 40px;
    }
}

.rounded2 {
    border-radius: 20px;
    @include m1024 {
        border-radius: 35px;
    }
}

.effetHoverScale {
    @include m1024 {
        transition: transform .2s ease-out;
        &:hover:not(:disabled) {
            transform-origin: center center;
            transform: scale(1.08);
        }
    }
}

.grey-- {
    background: $grey-light;
}

.text-center {
    text-align: center;
}

.d-none {
    display: none;
}

@media print {
    body {
        margin-top: 0 !important;
    }

    .no-print,
    .c-stickyNav,
    .c-header_tools,
    .c-header_right,
    .c-comments_write,
    .c-article_visuel:before,
    .c-carouselArticles .swiper-button-next,
    footer {
        display: none !important;
    }

    .print-image {
        display: block !important;
    }

    .c-header {
        position: relative !important;
    }

    .c-carouselCashback {
        margin-bottom: 0 !important;
        .swiper-wrapper {
            flex-wrap: wrap !important;
        }
        .swiper-container {
            margin-bottom: 0 !important;
        }
        .swiper-slide {
            width: calc(50% - 20px) !important;
        }
        .swiper-pagination {
            display: none !important;
        }
    }

    .c-stepText_content {
        align-items: center !important;
    }

    .c-carouselArticles .swiper-wrapper {
        flex-wrap: wrap !important;
    }
}

[data-cognigy-webchat-root] div{
    opacity: 0;
    pointer-events: none;

    body.withChatBox & {
        opacity: 1;
        pointer-events: all;
    }
}

// html{
//     // scroll-padding-top: 100px; //don't supported by safari
// }

a[name]{
    padding-top: 100px;
    margin-top: -100px;
}


.withUppercase{
    text-transform: uppercase;

    &::placeholder{
        text-transform: none;
    }
}