.c-bannerBrand {
    margin-top: 30px;
    margin-bottom: 30px;
    &__box{
        @include flex(flex-start, flex-start, row, nowrap);
        position: absolute;
        top: 15px;
        bottom: 15px;
        left: 14px;
        right: 14px;

        > * {
            position: relative;
            z-index: 1;
        }

        @include m1024 {
            top: 14px;
            bottom: 14px;
            left: 20px;
            right: 20px;
        }
    }
    &Link {
        position: relative;
        overflow: hidden;
        @include flex(flex-start, flex-start);
        //min-height: 110px;
        //padding: 15px 14px;
        border-radius: 17px;
        box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
        background-color: $grey-dark;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include m1023 {
            margin-left: -9px;
            margin-right: -9px;
        }
        @include m1024 {
            //padding: 14px 20px;
            //min-height: 190px;
            border-radius: 35px;
        }
        // &:before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: linear-gradient(90deg, rgba($white, .4) 0%, rgba($white, .4) 30%, rgba($white, 0) 60%, rgba($white, 0) 100%);
        // }
    }
    &__logo {
        max-width: 46px;
        margin-right: 8px;
        width: 8vw;
        @include m1024 {
            max-width: 65px;
            margin-right: 22px;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    &__content {
        max-width: 215px;
        margin-top: 5px;
        font-family: $main-font;
        font-size: 14px;
        color: $blue-dark;
        @include m1024 {
            margin-top: 18px;
            font-size: 21px;
        }
        &-title {
            font-size: 15px;
            font-weight: 900;
            line-height: 1;
            @include m1024 {
                font-size: 28px;
            }
        }
    }
}