.c-communityPostDetails {
    .c-comments {
		background: $white;
		max-width: 100%;
		box-shadow: 0 0 16px 1px rgba(140, 140, 140, 0.1);
		margin-top: 25px;
		@include m1024 {
			margin-top: 50px;
		}
    }
    .title-comments-counter {
        font-size: 18px;
        margin-top: 55px;
        @include black;
        @include m1024 {
            font-size: 30px;
            @include extraBold;
        }
    }
    .c-carouselCashback {
        overflow: visible;
    }
}