.c-accordion {
    &_content {
        overflow: hidden;
        transition: all .3s ease-out;
        img {
            margin-bottom:14px;
            width:100%;
            height: auto;
            @include m1024 {
                margin-bottom:20px;
            }
        }
        h3 {
            text-transform: initial;
            @include extraBold;
            color:$blue;
        }
        &>div {
            @include m1024 {
                margin-bottom:16px;
            }
        }
    }
    &_title {
        padding:14px 0;
        @include flex (space-between, center, row, nowrap);
        @include m1024 {
            cursor: pointer;
        }
        h2 {
            @extend .title-2;
            margin:0!important;
        }
    }
    &_button {
        font-size:13px;
        @include m1024 {
            font-size:18px;
        }
        &.up-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform:rotate(90deg);
            transition: all .1s ease-out;
        }
        &.down-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform:rotate(-90deg);
            transition: all .1s ease-out;
        }
    }
}