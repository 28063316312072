.c-form {
    &_line {
        margin-bottom: 5px;
        input {
            width: 100%;
            &:not([disabled]) {
                cursor: pointer;
            }
        }
        @include m1024 {
            @include flex(space-between, flex-start, row, nowrap)
        }
        &.padding-- {
            padding: 20px 0 20px 0;
        }
    }
    &_lineEltLarge {
        @include m1024 {
            width: 100%;
        }
    }
    &_lineElt {
        position: relative;
        @include m1024 {
            width: calc(50% - 10px)
        }

        &--more{
            @include m1024 {
                width: calc(52% - 10px)
            }
        }

        &[data-error="true"] {
            .c-form_select__control {
                border: 1px solid $red !important;
            }
        }
        &.iban-field {
            @include m1024 {
                min-width: 390px;
            }
        }
    }
    label {
        font-size: 16px;
        @include bold;
        line-height: 1.09;
        display: block;
        width: 100%;
        margin-bottom: 10px;
        @include flex(flex-start);
        @include m1024 {
            font-size: 18px;
        }
        p {
            display: inline;
            text-align: left;
        }
        sup {
            top: initial;
            line-height: 1;
        }
    }
    input[type=email], input[type=password], input[type=text], input[type=tel] {
        @extend .btn-1;
        &[data-error=true] {
            border-color: $red;
        }

        &.has-tooltip {
            padding-right: 58px;
        }
    }
    input[type=password]{
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
    }
    textarea {
        @extend .btn-1;
        height: 225px;
        &[data-error=true] {
            border-color: $red;
        }
    }
    button[type=submit] {
        @extend .btn-2;
        &:disabled {
            opacity: .5;
        }
    }

    &.is-grey-- {
        input[type=email], input[type=password], input[type=text], input[type=tel] {
            background-color: #EDEDED;
        }
    }

    &_radio {
        @include flex(space-between, center, row);
        @include m1024 {
            min-width: 210px;
        }
        &Item {
            position: relative;
            width: calc(50% - 10px);
            @extend .btn-1;
            padding: 0;
            overflow: hidden;
            @include m1024 {
                width: calc(50% - 5px);
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $white;
                z-index: 0;
            }

            input[type=radio] {
                display: none;
            }
            input[type=radio]:checked + label {
                color: $white;
            }
            input[type=radio]:checked + label:after {
                transform: scale(1);
                opacity: 1;
            }

            input[type=radio]:disabled:checked + label:after {
                opacity: .5;
            }

            input[type=radio]:disabled + label {
                cursor: default;
            }
            label {
                position: relative;
                @include regular;
                padding: 12px;
                margin: 0;
                z-index: 2;
                transition: color .2s ease-out;
                text-align: center;
                justify-content: center;
                color: $grey-dark;
                @include m1024 {
                    padding: 20px 20px 19px 20px;
                    // &:hover {
                    //     color:$white;
                    //     &:after {
                    //         background:$purple;
                    //         transform:scale(1);
                    //         opacity: 1;
                    //     }
                    // }
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 45px;
                    background: $purple;
                    transform: scale(0);
                    transform-origin: center center;
                    opacity: 0;
                    transition: opacity .3s ease-out, transform .2s ease-out;
                    z-index: -1;
                }
            }
        }
    }
    &_radio2, &_radio3 {

        @include flex(flex-start, center, row, wrap);
        
        &.twice-- {
            .c-form_radio2Item {
                width: 50%;
                @include m1024 {
                    width: calc(100% / 3);
                }
            }
        }
        &Item {
            position: relative;
            width: 100%;
            @include m1024 {
                width: 50%;
            }
        }

        input[type="radio"] {
            // display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
        }
        input[type="radio"] + label {
            position: relative;
            z-index: 1;
            font-size: 15px;
            min-height: 28px;
            @include flex(flex-start, center, row, wrap);
            padding-left: 40px;
            margin-bottom: 20px;
            margin-left: 0px;
            
        }
        input[type="radio"] + label:before,
        input[type="radio"] + label:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            content: '';
            width: 28px;
            height: 28px;
            border-radius: 100%;
            margin-right: 14px;
            background-color: $white;
        }
        input[type="radio"] + label:after {
            width: 28px;
            height: 28px;
            transform: scale(0);
            transform-origin: center center;
            opacity: 0;
            transition: opacity .3s ease-out, transform .2s ease-out;
            background: $purple;
        }
        input[type="radio"]:checked + label:after {
            transform: scale(1);
            opacity: 1;
        }
        input[type="radio"]:checked:disabled + label:after {
            opacity: .5;
        }




        input[type="checkbox"] {
            // display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
        }
        input[type="checkbox"] + label {
            position: relative;
            z-index: 1;
            font-size: 15px;
            min-height: 28px;
            @include flex(flex-start, center, row, wrap);
            padding-left: 40px;
            margin-bottom: 20px;
            margin-left: 0px;
            
        }
        input[type="checkbox"] + label:before,
        input[type="checkbox"] + label:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            content: '';
            width: 28px;
            height: 28px;
            border-radius: 0%;
            margin-right: 14px;
            background-color: $white;
        }
        input[type="checkbox"] + label:after {
            width: 28px;
            height: 28px;
            transform: scale(0);
            transform-origin: center center;
            opacity: 0;
            transition: opacity .3s ease-out, transform .2s ease-out;
            background: $purple;
        }
        input[type="checkbox"]:checked + label:after {
            transform: scale(1);
            opacity: 1;
        }
        input[type="checkbox"]:checked:disabled + label:after {
            opacity: .5;
        }
    }
    &_radio3 {
        &Item {
            margin-top: 30px;
            @include m1024 {
                width: 100%;
                margin-top: 48px;
            }
            input[type="radio"] + label {
                font-size: 14px;
                @include bold;
                min-height: 1px;
                padding-left: 0;
                padding-right: 40px;
                margin-bottom: 0;
                @include m1024 {
                    font-size: 20px;
                }
            }

            input[type="radio"] + label:before,
            input[type="radio"] + label:after {
                top: 50%;
                left: auto;
                right: 0;
                width: 28px;
                height: 28px;
                margin-right: 0;
                @include m1024 {
                    width: 46px;
                    height: 46px;
                }
            }
            input[type="radio"] + label:before {
                margin-top: -14px;
                background-color: $grey-light;
                border: 2px solid $grey-light;
                @include m1024 {
                    margin-top: -23px;
                }
            }
            input[type="radio"] + label:after {
                width: 18px;
                height: 18px;
                right: 5px;
                margin-top: -9px;
                background-color: $purple;
                @include m1024 {
                    width: 30px;
                    height: 30px;
                    right: 8px;
                    margin-top: -15px;
                }
            }
            input[type="radio"]:checked + label:before {
                border-color: $purple;
                background-color: $white;
            }


            



            input[type="checkbox"] + label {
                font-size: 14px;
                @include bold;
                min-height: 1px;
                padding-left: 0;
                padding-right: 40px;
                margin-bottom: 0;
                @include m1024 {
                    font-size: 20px;
                }
            }

            input[type="checkbox"] + label:before,
            input[type="checkbox"] + label:after {
                top: 50%;
                left: auto;
                right: 0;
                width: 28px;
                height: 28px;
                margin-right: 0;
                @include m1024 {
                    width: 46px;
                    height: 46px;
                }
            }
            input[type="checkbox"] + label:before {
                margin-top: -14px;
                background-color: $grey-light;
                border: 2px solid $grey-light;
                @include m1024 {
                    margin-top: -23px;
                }
            }
            input[type="checkbox"] + label:after {
                width: 18px;
                height: 18px;
                right: 5px;
                margin-top: -9px;
                background-color: $purple;
                @include m1024 {
                    width: 30px;
                    height: 30px;
                    right: 8px;
                    margin-top: -15px;
                }
            }
            input[type="checkbox"]:checked + label:before {
                border-color: $purple;
                background-color: $white;
            }
        }
    }
    &_checkbox {
        &.alt-- {
            margin-top: 20px;
            margin-bottom: 20px;
            input[type=checkbox] {
                &:checked + label:after {
                    transform: translateY(-50%) scale(1);
                }
            }
            label {
                padding-left: 39px;
                @include m1024 {
                    padding-left: 66px;
                }
                &:before, &:after {
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 29px;
                    height: 29px;
                    border-radius: 7px;
                    text-align: center;
                    line-height: 29px;
                    @extend .icomoon;
                    color: $white;
                    @include m1024 {
                        width: 47px;
                        height: 47px;
                        border-radius: 15px;
                        line-height: 47px;
                    }
                }
                &:after {
                    content: '\e933';
                    background-color: $purple-light;
                    transform: translateY(-50%) scale(0);
                }
            }
        }

        input[type=checkbox] {
            display: none;
            &:checked + label:after {
                transform: scale(1);
                opacity: 1;
                @include m1023 {
                    transform: translateY(-50%) scale(1);
                }
            }
        }
        label {
            position: relative;
            padding-left: 50px;
            margin-bottom: 0;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 34px;
                height: 34px;
                display: block;
                background: $white;
                border-radius: 33.5px;
                box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.05);
                z-index: 0;
                @include m1023 {
                    top: 50%;
                    transform: translateY(-50%);
                }

                .is-grey-- & {
                    background-color: #EFEFEF;
                }
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 34px;
                height: 34px;
                display: block;
                background: $purple;
                border-radius: 33.5px;
                transform: scale(0);
                transform-origin: center center;
                opacity: 0;
                transition: opacity .3s ease-out, transform .2s ease-out;
                z-index: 1;
                @include m1023 {
                    top: 50%;
                    transform: translateY(-50%) scale(0);
                }
            }
        }
    }

    &_select {
        &__control {
            padding: 10px 20px 9px 20px !important;
            border-radius: 45px !important;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.05);
            background-color: $white !important;
            line-height: 1.25;
            border: 1px solid $white !important;
            box-shadow: none !important;
        }
        &.errorBorder-- {
            .c-form_select__control {
                border: 1px solid $red !important;
            }
        }
        &__single-value {
            //color: $purple !important;
            color: $grey-dark !important;
        }
        &__value-container {
            padding: 0 !important;
        }
        &__indicator-separator {
            display: none;
        }
        &__indicator {
            padding: 0 !important;
            color: $purple !important;
        }
        &__menu {
            width: calc(100% - 40px) !important;
            left: 20px;
            margin: 0 !important;
            border-radius: 0 !important;
        }
        &__menu-list {
            padding: 0 !important;
        }
        &__option {
            cursor: pointer;
            //font-size: 13px !important;
            font-size: 16px !important;
            color: $grey-dark !important;
            //padding: 10px !important;
            padding: 12px 22px 11px !important;
            background-color: transparent !important;
            transition: background-color .3s ease-in-out;
        }
        &__option--is-focused {
            background: $purple !important;
            color: $white !important;
        }
        &__option--is-selected {
            @include black;
            color: $purple !important;
            &.c-form_select__option--is-focused {
                color: $white !important;
            }
        }
    }

    &_optin {
        @include m1024 {
            @include flex(space-between, center, row, nowrap);
            .c-form_radio {
                flex-grow: 2;
            }
            & > label {
                @include m1024 {
                    margin: 0 30px 0 0;
                }
            }
        }
    }
    &_advantages {
        .c-form & .c-form_line {
            margin-bottom: 20px;
            @include m1024 {
                margin-bottom: 30px;
            }
        }

        .c-form_checkbox label {
            @include m1024 {
                max-width: 68%;
            }
        }
    }
    &_icon {
        & > label {
            @include flex(flex-start, center, row, nowrap);
            span {
                flex-shrink: 100;
            }
            &:before {
                content: '';
                margin-right: 15px;
                @include m1024 {
                    flex-grow: 100;
                }
            }
            &.email-- {
                &:before {
                    display: inline-block;
                    width: 34px;
                    height: 35px;
                    background: url('#{$imgPath}form-email.jpg') no-repeat center;
                    background-size: contain;
                    @include m1024 {
                        width: 44px;
                        height: 29px;
                    }
                }
            }
            &.mail-- {
                &:before {
                    display: inline-block;
                    width: 37px;
                    height: 30px;
                    background: url('#{$imgPath}form-mail.png') no-repeat center;
                    background-size: contain;
                    @include m1024 {
                        width: 43px;
                        height: 44px;
                    }
                }
            }
            &.sms-- {
                &:before {
                    display: inline-block;
                    width: 37px;
                    height: 25px;
                    background: url('#{$imgPath}form-sms.png') no-repeat center;
                    background-size: contain;
                    @include m1024 {
                        width: 45px;
                        height: 37px;
                    }
                }
            }

        }
    }
    &_recaptcha {
        margin-left: 20px;
    }
    &_info {
        position: relative;
    }
    &_tooltip {
        position: absolute;
        top: 0;
        right: 12px;
        height: 100%;
        @include flex(center, center, row, nowrap);
        cursor: pointer;
        color: $grey-dark;
        .icon-eye,
        .icon-eye-no {
            font-size: 13px;
            margin-right: 5px;
            opacity: .8;
        }
        .icon-eye-no {
            font-size: 16px;
            position: relative;
        }
        .icon-what {
            font-size: 16px;
            opacity: .8;
        }
    }

    &_tooltip2 {
        position: relative;
        display: inline-block;
        cursor: pointer;
        line-height: 0;
    }
    &_showPassword {
        cursor: pointer;
    }

    &_avatar {
        form {
            @include flex(space-between, center, row);
            margin: 24px 0px 50px;
            gap: 40px;
            @include m1024 {
                justify-content: center;
                margin-bottom: 70px;
            }
        }
        &Button {
            width: calc(100% - 100px);
            @include m1024 {
                width: auto;
                margin-left: 180px;
            }
        }
        &Content {
            width: 100px;
            height: 100px;
            @include flex(center, center, column);
            position: relative;
            > div {
                width: 100px;
                height: 100px;
                border-radius: 100%;
                background-size: cover;
                background-position: center center;
                // background-color: $white;
                // margin-bottom: 11px;
            }
            p {
                font-size: 13px;
                @include bold;
                margin: 0 !important;
                text-align: center;
            }
            .pictoprofil__name {
                width: 100%;
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                border: 10px solid $grey-very-light;
                border-radius: 50%;
                z-index: -1;
            }
        }
        button[type=button] {
            @extend .btn-2;
            width: 100%;
            @include m1024 {
                width: auto;
                // margin-left: 180px;
            }
        }
        input[type=file] {
            display: none;
        }
    }

    &_modif {
        position: absolute;
        right: 0px;
        z-index: 5;
        font-weight: 400;
        font-size: 14px;
        &-picto {
            @extend .icomoon;
            @extend .icon-modify;
            font-size: 20px;
            margin-left: 10px;
        }
    }
    .error-- {
        font-size: 12px;
        //font-weight: bold;
        line-height: 1.59;
        text-align: left;
        color: $red;
        //height: 20px;
        //padding: 2px 0 0 15px;
        padding: 2px 15px 0 15px;
    }
    .errormore--{
        text-align: center;
        margin-bottom: 30px;
    }
    .succes-- {
        font-size: 12px;
        //font-weight: bold;
        line-height: 1.59;
        text-align: left;
        color: $green;
        //height: 20px;
        //padding: 2px 0 0 15px;
        padding: 2px 15px 0 15px;
    }
    .success-- {
        font-size: 17px;
        //font-weight: bold;
        line-height: 1.59;
        color: $white;
        margin-top: 20px;
        @include bold;
        background-color: #60c584;
        //height: 20px;
        //padding: 2px 0 0 15px;
        padding: 10px 15px;
        width: 100%;
        @extend .rounded2;
        text-align: center;
    }

    &_password {
        position: relative;
        width: calc(100% - 36px);
        margin-left: 18px;
        height: 20px;
        @include m1024 {
            width: calc(100% - 42px);
            margin-left: 24px;
        }
        .progressBar {
            display: block;
            width: 20%;
            height: 2px;
            background: $level1;
            transition: all .3s ease-out;
            span {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
                background: $level1;
                color: $white;
                padding: 2px 4px 3px 4px;
                &:empty {
                    display: none;
                }
            }

        }
        &.level2-- {
            .progressBar {
                width: 40%;
                background: $level2;
                span {
                    background: $level2;
                }
            }
        }
        &.level3-- {
            .progressBar {
                width: 60%;
                background: $level3;
                span {
                    background: $level3;
                }
            }
        }
        &.level4-- {
            .progressBar {
                width: 80%;
                background: $level4;
                span {
                    background: $level4;
                }
            }
        }
        &.level5-- {
            .progressBar {
                width: 100%;
                background: $level5;
                span {
                    background: $level5;
                }
            }
        }
    }
    &_file {
        text-align: center;
        @include m1023 {
            &[data-imported="true"] {
                padding-left: 39px;
            }
        }
        button {
            @include flex(center, center);
            position: relative;
            margin-left: auto;
            margin-right: auto;
            @include m1023 {
                padding-left: 19px;
                padding-right: 19px;
                font-size: 14px;
            }
            @include m374 {
                padding-left: 15px;
                padding-right: 15px;
                font-size: 12px;
            }
            &[data-imported="true"] {
                margin-right: auto;

                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: calc(100% + 11px);
                    content: '\e933';
                    display: block;
                    width: 28px;
                    height: 28px;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 28px;
                    background-color: $green;
                    @extend .icomoon;
                    @include m1024 {
                        right: calc(100% + 30px);
                        width: 47px;
                        height: 47px;
                        border-radius: 15px;
                        line-height: 47px;
                    }
                }
            }
            &[data-error="true"] {
                border: 1px solid $red;
            }
            i {
                margin-right: 9px;
                font-size: 22px;
                @include m1024 {
                    font-size: 28px;
                }
                @include m374 {
                    font-size: 18px;
                }
            }
        }
        input {
            display: none;
        }
        .error-- {
            padding-left: 0;
            text-align: center;
        }
    }
    &_submit {
        margin-top: 25px;
        text-align: center;
        @include m1024 {
            margin-top: 40px;
        }
    }
}

.c-formEdit {
    button[type=submit] {
        display: none !important;
    }
    &_more {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-out;

        .editing-- & {
            overflow: inherit;
        }
    }
    .light {
        display: none;
    }
    &.editing-- {
        button[type=submit] {
            display: block !important;
        }
        .light {
            display: initial;
        }
        .c-form_modif {
            opacity: 0;
            pointer-events: none;
        }
        .c-formEdit_more {
            max-height: 500px;
        }
    }
    .loader {
        display: none;
    }
    &.loading-- {
        .loader {
            display: block;
        }
    }
}

.c-formAccordion {
    margin-bottom: 40px;
    &_container {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-out;
        &.open-- {
            max-height: 3000px;
            transition: max-height 3s ease-out;
            @include m1024 {
                max-height: 1500px;
                transition: max-height 2s ease-out;
            }
        }
        label {
            margin-bottom: 20px;
        }
    }
    &_content {
        margin: 35px 0 15px 0;
    }
    &_title {
        position: relative;
        font-size: 14px;
        @include black;
        padding: 30px 0 7px 0;
        @include flex (flex-start, center, row, nowrap);
        border-bottom: 1px solid $purple;
        @include m1024 {
            cursor: pointer;
        }
        .c-form_modif {
            right: 0;
        }
        h3 {
            font-size: 18px;
            @include m1024 {
                font-size: 22px;
            }
        }
        p {
            margin: 0px 0px 0px 20px!important;
            font-weight: 600;
            white-space: nowrap;
            font-size: 16px;
            @include m1024 {
                font-size: 18px;
            }
        }
    }
    &_item {
        &:first-of-type {
            .c-formAccordion_title {
                padding-top: 0;
            }
        }
    }
    &_button {
        font-size: 13px;
        margin-left: 15px;
        @include m1024 {
            font-size: 18px;
        }
        &.up-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform: rotate(90deg);
            transition: all .1s ease-out;
        }
        &.down-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform: rotate(-90deg);
            transition: all .1s ease-out;
        }
    }
}