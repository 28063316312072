.t-cart-cashback {
    .error--.error--step {
        margin-top: 18px;
        padding: 4px 15px;
        //@extend .rounded2;
        border-left: 2px solid $red;
        font-size: 16px;
        line-height: 1.59;
        text-align: left;
        color: $red;
    }
    .error--.error--slowconnection{
        margin-bottom: 20px;
        opacity: 0;
        animation: fadeInDelay 0.35s ease 5s;
        animation-fill-mode: both;
    }
    @keyframes fadeInDelay {
        0%{
            opacity: 0;
        }

        100%{
            opacity: 1;
        }
    }
    .title-2 {
        margin-bottom: 20px;
        max-width: 500px;
        @include m1024 {
            margin-bottom: 12px;
        }
    }
    .c-formContent > p,
    .title-2 + p {
        font-size: 17px;
    }
    .c-formContent {
        margin-top: 45px;
    }
    p + .c-form_line {
        margin-top: 17px;
    }
    .c-form_line + p {
        margin-top: 20px;
        @include m1024 {
            margin-top: 38px;
        }
    }
    .c-form_line {
        margin-bottom: 20px;
        @include m1024 {
            margin-bottom: 30px;
        }
    }
    .cartSteps {
        padding-bottom: 48px;
    }
    .mentions {
        p {
            margin-top: 38px;
            @include m1024 {
                margin-top: 100px;
            }
            & + p {
                margin-top: 15px;
                @include m1024 {
                    margin-top: 30px;
                }
            }
        }
    }
    .cart__products {
        &-header {
            margin-top: 23px;
            @include flex(space-around, center);
            font-size: 13px;
            padding-right: 22px;
            @include m1024 {
                padding-right: 0;
                margin-top: 45px;
                font-size: 19px;
            }
            > div {
                flex: 1;
                text-align: center;
                &:last-child {
                    @include m1024 {
                        flex: 2;
                    }
                }
            }
        }
        &-list-item {
            margin-top: 15px;
            @include m1024 {
                margin-top: 34px;
            }
            .itemProduct {
                @extend .rounded2;
                background-color: $white;
                @extend .rounded2;
                box-shadow: -1.1px 5.9px 16.9px 1.1px rgba(141, 141, 141, 0.13);
                transition: box-shadow .4s ease-in-out;
                @include m1024 {
                    box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
                }
                @include m1023 {
                    margin-left: -10px;
                    margin-right: -10px;
                }

                &:hover {
                    @include m1024 {
                        box-shadow: 2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.5);
                        .c-product__actions {
                            &-select {
                                text-decoration-color: transparent;
                                /*add those for opera and mozilla support*/
                                -webkit-text-decoration-color: transparent;
                                -moz-text-decoration-color: transparent;
                            }
                        }
                    }
                }

                &[data-error="true"] {
                    border: 1px solid $red;
                }
                p {
                    strong {
                        @include black;
                    }
                }
                &__variable {
                    position: relative;
                    z-index: 1;
                    margin-top: -10px;
                    padding: 0 12px 15px;
                    @include m1024 {
                        padding: 0 30px 30px;
                    }

                    [data-initialexpanded="true"],
                    [data-initialexpanded="false"] {
                        transition: max-height .5s ease-in-out;
                    }
                    [data-expanded="true"],
                    [data-expanded="false"] {
                        overflow: hidden;
                        max-height: 6000px;
                        transition: max-height .35s ease-in-out;

                    }
                    [data-expanded="true"] {
                        max-height: 6000px;
                    }
                    [data-expanded="false"] {
                        max-height: 0;
                        transition: none;
                    }

                    &-toggle {
                        display: block;
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                        @include m1023 {
                            font-size: 14px;
                        }
                    }

                    p + .toggle__arrow {
                        margin-top: 14px;
                        @include m1024 {
                            margin-top: 24px;
                        }
                    }

                    .toggle__arrow {
                        i {
                            display: inline-block;
                            font-size: 25px;
                            transform: rotate(90deg);
                            transform-origin: center;
                            @include m1024 {
                                font-size: 51px;
                            }
                            &[data-expanded="true"] {
                                transform: rotate(-90deg);
                            }
                            &:before {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }

                    .c-form {
                        @include m1024 {
                            padding: 0 55px 60px 0;
                        }
                    }

                }
            }
        }

    }
    .cart__button {
        margin-top: 40px;
        text-align: center;
        @include m1023 {
            margin-left: -5px;
            margin-right: -5px;
        }

        @include m1024 {
            margin-top: 30px;
        }
        button {
            @include m1023 {
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 20px;
            }
        }
        button + button {
            @include m1024 {
                margin-left: 20px;
            }
        }
    }
    .cart-step0 {
        padding-bottom: 45px;

        .title-30 {
            max-width: 545px;
        }
        .cartFlex {
            @include flex(flex-start, center);
            @include m1023 {
                margin-left: -10px;
                margin-right: -10px;
            }
            .cart__products-header {
                padding-right: 0;
                margin-top: 0 !important;
            }
            &.cart__products-list-item {
                margin-top: 16px !important;
                @include m1024 {
                    margin-top: 32px !important;
                }
            }
        }
        .cartPrefix {
            width: 65px;
            @include m1024 {
                width: 150px;
            }
        }
        .cart__products-list.refund-- {
            background-color: transparent;
            box-shadow: none;

        }
        .cart__refurb.refund-- {
            @include m1024 {
                max-width: 875px;
            }
        }
        .cart__products-button {
            @include bold;
            padding: 8px 6px 10px;
            border-radius: 35px;
            box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
            background-color: $white;
            font-size: 10px;
            transition: all .35s ease-in-out;
            @include m1024 {
                padding: 15px 21px 21px;
                font-size: 19px;
                &:hover {
                    box-shadow: 0 2px 10px 0 rgba(141, 141, 141, 0.43);
                }
            }
        }
        .cart__products-select {
            @include flex(center, center);
            width: 29px;
            height: 29px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 7px;
            box-shadow: 3.5px 4.9px 16.9px 1.1px rgba(141, 141, 141, 0.13);
            background-color: $white;
            color: $white;
            @include m1024 {
                width: 46px;
                height: 46px;
                border-radius: 15px;
            }
            &.selected {
                background-color: $purple-light;
            }
        }
        .cart__refurb.refund-- {
            display: block;
            @include m1024 {
                padding-right: 161px;
            }
            p + p {
                margin-top: 16px;
            }
            p:last-child {
                @include flex();
            }
        }
        .cartContainer {
            flex: 1;
            max-width: calc(100% - 65px);
            @include m1024 {
                max-width: 725px;
            }
            .itemProduct {
                @include m1023 {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .c-product[data-cart="true"] .c-product__img {
                @include m374 {
                    min-width: 52px;
                }

            }
            .c-product[data-cart="true"] .c-product__desc {
                @include m1023 {
                    margin-left: 5px;
                    min-width: 80px;
                }
            }
            .c-product[data-cart="true"] .c-product__content {
                @include m1023 {
                    margin-right: 5px;
                }
            }
        }
        .c-listProducts {
            margin-top: 36px;
            @include m1024 {
                margin-top: 60px;
            }
        }
    }
    .cart-step3 {
        .c-formContent {
            @include m1023 {
                margin-top: 20px;
            }
        }
        .c-formContent > p {
            @include m1023 {
                font-size: 14px;
            }
        }
    }
    .cart-step0,
    .cart-step4 {
        .title-2 {
            @include m1023 {
                text-align: center;
            }
        }
        .cart__products-listCtn {
            position: relative;
            &[data-load="true"] {
                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($white, .8);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @extend .rounded2;
                }
            }
            .loader {
                z-index: 2;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin-top: 0;
            }
        }
        .cart__products-list,
        .cart__refurb,
        .cart__personnalInfo-box {
            @extend .rounded2;
            background-color: $white;
            @extend .rounded2;
            box-shadow: -1.1px 5.9px 16.9px 1.1px rgba(141, 141, 141, 0.13);
            @include m1024 {
                box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
            }
        }
        .cart__products {
            margin-top: 32px;
            &-list-item {
                margin-top: 19px;
                &:not(:first-child) {
                    margin-top: -34px;
                    @include m1024 {
                        margin-top: -48px;
                    }
                }
                .itemProduct {
                    border-radius: 0;
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }
        .cart__refurb,
        .cart__personnalInfo-box {
            @include m1023 {
                margin-left: -10px;
                margin-right: -10px;
            }
        }
        .cart__refurb {
            @include flex(space-between, center);
            margin-top: 15px;
            padding: 15px 55px 15px 25px;
            font-size: 15px;
            @include bold;
            @include m1024 {
                margin-top: 30px;
                padding: 44px 90px 44px 40px;
                font-size: 21px;
            }
            @include m374 {
                padding-left: 15px;
                font-size: 13px;
            }
            span:last-child {
                font-size: 18px;
                @include extraBold;
                @include m1024 {
                    font-size: 26px;
                }
            }
        }
        .cart__personnalInfo {
            margin-top: 30px;
            @include m1024 {
                margin-top: 46px;
            }
            &-box {
                margin-top: 15px;
                padding: 17px 15px;
                line-height: 1.3;
                @include m1024 {
                    margin-top: 20px;
                    padding: 38px 40px;
                }
            }
        }
        .cart__alert{
            display: none;

            @include m1023 {
                margin-left: -10px;
                margin-right: -10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 15px;
                padding: 25px 55px 25px 25px;
                font-size: 15px;
                font-family: "Nunito Sans", sans-serif;
                font-weight: 700;
                background-color: #fff;
                box-shadow: -1.1px 5.9px 16.9px 1.1px hsla(0, 0%, 55.3%, .13);
                border-radius: 20px;
                align-items: flex-start;

                i{
                    font-size: 48px;
                    margin-right: 30px;
                }

                .cart__alert__title{
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .cart-step5 {
        @include m1023 {
            text-align: center;
        }
        p {
            max-width: 440px;
            margin-top: 30px;
            @include m1024 {
                margin-top: 35px;
            }
            @include m1023 {
                font-size: 17px;
            }
        }
        p + p {
            margin-top: 17px;
            @include m1024 {
                margin-top: 26px;
            }
        }
        .cart__button {
            margin-top: 35px;
            @include m1024 {
                margin-top: 66px;
            }
        }
    }
}

.buying-evidence-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
    .buying-evidence {
        position: relative;
        margin: 0;
        @media screen and (min-width: 576px) {
            margin: auto;
        }
        padding: 20px;
        border-radius: 25px;
        background: $white;
        box-shadow: -0.8px 3.9px 6.6px 0.4px rgba(141, 141, 141, 0.04);
        transition: transform .35s ease-in-out;
        display: flex;
        @media screen and (min-width: 576px) {
            gap: 40px;
        }

        margin-right: auto;

        &:before {
            position: absolute;
            top: 22%;
            right: calc(100% - 230px);
            @media screen and (min-width: 375px) {
                right: calc(100% - 280px);
            }
            @media screen and (min-width: 576px) {
                top: 50%;
                right: calc(100% + 11px);
            }
            transform: translateY(-50%);
            content: '\e933';
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 8px;
            text-align: center;
            color: $white;
            line-height: 28px;
            background-color: $green;
            @extend .icomoon;
            @include m1024 {
                right: calc(100% + 30px);
                width: 47px;
                height: 47px;
                border-radius: 15px;
                line-height: 47px;
            }
        }
        .buying-evidence-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            .buying-evidence-name {
                font-weight: 700;
                text-align: left;
                @media screen and (max-width: 576px) {
                    width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .buying-evidence-import {
                background: $white;
                border: 2px solid $purple;
                color: $purple;
            }
        }
        .buying-evidence-delete {
            align-self: auto;
        }
    }
}