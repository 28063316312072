.c-listUniverse {
    @include flex(space-between, inherit, row, wrap);
    margin-bottom: 20px;

    &_wrapper {
        margin-bottom: 30px;

        .c-listUniverse_slider {
            padding-top: 15px;
            padding-bottom: 15px;
            margin-top: -15px;
            @include m1024 {
                padding-left: 15px;
                padding-right: 15px;
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }

    &_item {
        // width:95px;
        width: 190px;
        @extend .rounded2;
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.16);
        background: $blue;
        overflow: hidden;
        margin-right:10px;
        padding: 30px 15px 15px;
        margin-bottom:20px;
        cursor: pointer;
        transition: transform .2s ease-out;
        
        &:hover {
            @include m1024 {
                transform: scale(1.08);
            }
        }
        
        @include m1024 {
            &, &.swiper-slide {
                // width: 230px;
                width: calc(25% - 13px);
            }
        }
        @include m1023 {
            max-width: 30%;
        }

        a {
            @include flex(center, center, column);
            height: 100%;
        }

        &.active-- {
            background-color: $white;
        }
        &.disabled-- {
            pointer-events: none;
            opacity: .4;
        }
    }
    &_visuel {
        width:100%;
        height: 70px;
        margin-bottom:10px;
        overflow: hidden;
        @include flex (center, center);
        @include m1024 {
            margin-bottom:15px;
            height: 110px;
        }
        img {
            width:auto;
            height:auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &_title {
        color:$white;
        text-align: center;
        @include extraBold;
        @include m1024 {
            font-size: 20px;
        }

        .active-- & {
            color: $purple;
        }
        
    }
}