.c-carouselArticles {
    position: relative;
    margin: 0 -20px 0 0px;
    padding-bottom: 30px;
    overflow: hidden;
    @include m1024 {
        margin: 0 -70px 0 -70px;
        padding-left:70px;
        padding-bottom: 40px;
    }

    .c-article_item {
        margin: 12px 0 12px 10px;
        @include m1024 {
            margin: 20px 0 20px 0;
        }
    }
    .swiper-container {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 5px;
        padding-bottom: 20px;
        overflow: visible;
        @include m1024 {
            padding-right: 10px;
        }
    }
    .swiper-wrapper {
        @include flex(flex-start, stretch);
    }

    .swiper-slide {
        // font-size: 18px;
        // background: #fff;
        // width: 40%;
        // height:auto;
        width: 230px;
        height: auto;
        @include m1024 {
            // width:calc((100% - 120px)/4);
            width: calc(25% - 28px);
        }
        @include m1023 {
            max-width: 45%;
        }

        &.swiper-slide-prev  {
            opacity: 0.4;
        }

        &.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
            opacity: 0.4;
        }
        @include m991 {
            &.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
                opacity: 0.4;
            }
        }
        @include m767 {
            &.swiper-slide-active + .swiper-slide + .swiper-slide {
                opacity: 0.4;
            }
        }

        .c-article {
            height: 100%;
        }
    }

    .swiper-slide:first-of-type {
        //margin-left: 20px;
        //@include m1024 {
        //    margin-left: 70px;
        //}
        margin-left: 0;
    }
    .swiper-slide:last-of-type {
        margin-right: 20px;
        @include m1024 {
            margin-right: 70px;
        }
    }

    .swiper-button-prev {
        @extend .icomoon;
        @extend .icon-arrow;
        // display: none;

        @include m1023 {
            display: none;
        }
        @include m1024 {
            position: absolute;
            top: 19px;
            left: 0px;
            width: 56px;
            height: calc(100% - 38px);
            margin: 0;
            color: $purple;
            font-size: 44px;
        }
        &:before {
            transform: translateX(-50%) rotate(180deg);
            text-shadow: -0.7px 1.9px 8px rgba(0, 0, 0, 0.26);
            position: absolute;
            top: 25%;
            left: 50%;
        }
        &:after {
            content: '';
            width: 56px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // background:white;
            // opacity:.4;
            border-top-right-radius: 35px;
            border-bottom-right-radius: 35px;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
    .swiper-button-next {
        @extend .icomoon;
        @extend .icon-arrow;

        @include m1023 {
            display: none;
        }
        @include m1024 {
            position: absolute;
            top: 19px;
            right: 0;
            width: 56px;
            height: calc(100% - 38px);
            margin: 0;
            color: $purple;
            font-size: 44px;
        }
        &:after {
            content: '';
            width: 56px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // background:white;
            // opacity:.4;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
        }
        &:before {
            text-shadow: -0.7px 1.9px 8px rgba(0, 0, 0, 0.26);
            position: absolute;
            top: 25%;
            right: 50%;
            transform: translateX(50%);
        }
        &.swiper-button-disabled {
            display: none;
        }
    }

    // No carousel
    &.no-carousel {
        @include m1024 {
            margin-right: 0;
        }

        .swiper-slide {
            @include m1024 {
                width: calc(25% - 10px);

                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
    &-noresults {
        margin-bottom: 40px;
    }
}