.t-accountFavorite {
    .c-search { display: none; }

    &_wrapper {
        margin: 30px 0 50px;
        @include m1024 {
            margin: 50px 0 80px;
        }
    }

    h1 {
        margin-bottom: 20px;
    }
    &_list {
        @include flex(flex-start, stretch, row, wrap);
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 50px;

        .c-article {
            width: calc(50% - 16px);
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
            @include m768 {
                width: calc(33.33333% - 16px);
            }
            @include m1024 {
                width: calc(25% - 16px);
            }

            //&_favorite { display: none; }
        }
    }
    &_item {
        width:calc((100% - 20px)/2);
        margin-bottom:20px;
        margin-right:20px;
        &:nth-of-type(2n) {
            @include m1023 {
                margin-right:0;
            }
            @include m1024 {

            }
        }
        &:nth-of-type(4n) {
            @include m1024 {
                margin-right:0;
            }
        }
        @include m1024 {
            width:calc((100% - 60px)/4);
        }
    }
    &_filter {
        margin-bottom:10px;
        .c-filter_select {
            max-width:120px;
            @include m1024 {
                max-width: 140px;
            }
        }
    }
    &_noresultts {
        margin:30px 0;
        font-size: 18px;
        @include extraBold;
    }
}