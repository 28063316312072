.t-communityHp {
    &_wrapper {
        max-width: 795px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
    }
    &-cardsList {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        @include m1024 {
            justify-content: space-evenly;
        }
        &-item {
            width: 48%;
            margin-bottom: 30px;
            @include m1024 {
                width: 44%;
            }
            &:nth-child(2) {
                .c-communityHpCard {
                    background-color: $purple-light;
                    color: $white;
                }
            }
        }
    }
}