.c-pictoProfil {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 40px;
    @include m1023 {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 24px;
    }

    &_info {
        @include m1024 {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__wrapper {
            gap: 16px;
            display: flex;
            flex-direction: column;
            @include m1023 {
                flex-direction: row;
                flex-wrap: wrap;
            }
            .only-mobile {
                display: none;
                @include m1023 {
                    display: block;
                    width: 72px;
                    height: 72px;
                    min-width: 72px;
                }
            }
        }
        &__container {
            width: calc(100% - 88px);
        }
    }
    &_title {
        font-size: 38px;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 24px;
        @include m1023 {
            font-size: 28px;
        }
    }
    p {
        line-height: normal;
    }

    &_text {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: normal;
        @include m1023 {
            font-size: 22px;
        }
    }

    &_container {
        background-color: $white;
        padding: 80px 0px;
        margin-bottom: 80px;
        @include m1023 {
            padding: 24px 0px;
            margin: 24px auto 40px;
        }
    }

    &_img {
        width: 167px;
        height: 167px;
        min-width: 167px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 50%;
        @include m1023 {
            &:not(.only-mobile) {
                display: none;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &_progressBar {
        width:100%;
        height: 12px;
        margin-top: 44px;
        position: relative;
        @include m1023 {
            margin-top: 20px;
        }
        img {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            transform: translateX(-50%);
            width: 28px;
        }
        .bar {
            width:100%;
            background-color: #D9C9D8;
            border-radius: 24px;
            height: 100%;
            position: relative;
            overflow: hidden;
            .progress {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background-color: $purple;
            }
        }
    }
}

.pictoprofil {
    display: inline-block;
    @include m1023 {
        position: relative;
    }

    &__name {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        @include m1024 {
            font-size: 13px;
        }
        @include m1023 {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }
}