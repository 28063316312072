// Comment
.c-comment {
	position: relative;
	color: $purple;

	// In options
	.options-- & {
		&:not(:last-child) {
			border-bottom: 1px solid rgba($purple, 0.3);
			padding-bottom: 30px;
			margin-bottom: 30px;
		}
	}

	&_content {
		padding-left: 60px;
    	@include m1024 {
			padding-left: 100px;
			padding-right: 50px;
		}

		&.options-- {
			margin-top: 30px;
			padding-right: 0;
		}

		.options-- & {
			@include m1024 {
				padding-left: 100px;
			}
		}
	}

	&_picture {
		width: 40px;
		height:40px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		overflow: hidden;
		@include m1024 {
			width: 80px;
			height:80px;
		}
		&.noPicture {
			background:$grey;
			color:$white;
			@include flex(center, center);
			font-size: 20px;
			@include m1024 {
				font-size: 35px;
			}
		}
		// img {
		// 	display: inline-block;
		// 	
		// 	max-width: 100%;
		// 	height: auto;
		// 	@include m1023 {
		// 		width: 40px;
		// 	}
		// }

		.options-- & {
			@include m1024 {
				width: 80px;
				img { width: 50px; }
			}
		}
	}

	&_title {
		font-size: 16px;
		@include black;
		margin-bottom: 5px;
		@include m1024 {
			font-size: 18px;
		}
	}
	&_subtitle {
		font-size: 13px;
		color: $grey-dark;
		@include bold;
		@include m1024 {
			font-size: 16px;
		}
	}

	&_text {
		// word-break: break-all;
		text-align: justify;
		margin-top: 25px;
		font-size: 14px;
		white-space: pre-line;
		@include semiBold;
		@include m1024 {
			font-size: 18px;
		}

		&:not(:last-child) {
			margin-bottom: 25px;
		}

		a{
			text-decoration: underline;
		}
	}
	&_like {
		font-size: 14px;
		line-height: 1;
		color: $purple;
		@include extraBold;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: 5px;
		@include m1024 {
			font-size: 18px;
			margin-right: 0px;
		}
		i {
			font-size: 20px;
			margin-left: 5px;
			width: 20px;
			height: 30px;
			@include m1024 {
				font-size: 25px;
				margin-left: 8px;
				transition: font-size 0.2s ease;
				cursor: pointer;
				&:hover {
					font-size: 28px;
    				transition: font-size 0.2s ease;
				}
			}
			&.active {
				font-weight: 900;
			}
		}
	}
}