.c-communitySearch {
    &-title {
        text-align: center;
        margin: 45px auto 30px;
        @include m1024 {
            margin: 50px auto;
        }
        &-number {
            @include black;
            font-size: 18px;
            line-height: 1.1;
            text-transform: uppercase;
            @include m1024 {
                font-size: 24px;
                line-height: 1.3;
            }
        }
        &-text {
            @include regular;
            font-size: 14px;
            line-height: 1.2;
            @include m1024 {
                font-size: 18px;
            }
        }
    }
    &-results {
        background-color: $white;
        padding: 30px 0px;
        margin-bottom: 40px;
        @include m1024 {
            padding: 45px 0px;
            margin-bottom: 50px;
        }
    }
    &-btn {
        text-align: center;
        margin-bottom: 55px;
        @include m1024 {
            margin-bottom: 80px;
        }
    }
}