//
// Modals
// --------------------------------------------------

// .c-modal-open      - body class for killing the scroll
// .c-modal           - container to scroll within
// .c-modal-dialog    - positioning shell for the actual modal
// .c-modal-content   - actual modal w/ bg and corners and shit

// Container that the modal scrolls within
.c-modal {
    @include flex(center, flex-start);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 600;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    @include m1024 {
        align-items: center;
    }

    &.show-- {
        display: flex;
        .c-modal_dialog {
            transform: translate(0, 0);
        }
    }

    &.fade-- .c-modal_dialog {
        transition: transform .3s ease-out;
    }

    .title-1 {
        justify-content: center;
        text-transform: uppercase;
    }

    &_overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba($purple-dark, .75);
        cursor: pointer;
        &.fade-- {
            opacity: 0;
        }
        &.show-- {
            opacity: 1;
        }
        &.noClick-- {
            cursor: default;
        }
    }
    &_container {
        position: relative;
        z-index: 2;
        width: calc(100% - 40px);
        max-width: 960px;
        margin: 15px auto;
        @include m1024 {
            margin: 0;
            //  margin: 80px auto;
        }
    }
    

    &_content {
        position: relative;
        @include flex(center, center, column);
        width: 100%;
        pointer-events: auto;
        outline: 0;
        box-shadow: 0 0 32px 0 rgba($black, 0.15);
        background-color: $grey-very-light;
        @extend .rounded;
    }
    &_close {
        z-index: 3;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 16px;
        transition: transform .3s ease-in-out;
        @include m1024 {
            top: 40px;
            right: 40px;
            font-size: 32px;
        }
        i {
            color: $purple;
        }
        &:hover {
            @include m1024 {
                transform: rotate(90deg);
            }
        }
    }
    &_body {
        position: relative;
        width: 100%;
        max-height: 92vh;
        overflow: auto;
    }
    &_offerBasket, &_pageviews{
        align-items: center;
        &-intro {
            max-width: 520px;
            margin: 0 auto;
        }
        &-img {
            margin: 0px auto 30px;
            max-width: 100%;
            height: auto;
        }
    }
    &_password,
    &_deleteAccount,
    &_offerBasket .c-offerBasket,
    &_pageviews .c-pageviews {
        text-align: center;
        padding: 60px 35px 45px 35px;
        @include m1024 {
            padding: 56px 13% 89px;
        }
        p {
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 20px;
        }
        .c-login_form {
            margin-bottom: 0;
        }
        .c-login_form button[type=submit] {
            @include m1024 {
                margin-top: 0;
            }
        }
        &-intro {
            max-width: 520px;
            margin: 0 auto;
        }
        &-img {
            margin: 0px auto 30px;
            max-width: 100%;
            height: auto;
        }
    }

    &_change-password {
        form.c-form {
            align-items: center;

            input {
                width: 100%;
            }

            button[type=submit] {
                @include m1024 {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &_deleteAccount {
        button {
            display: inline-block;
            margin: 0 5px;
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 1;
            @extend .effetHoverScale;
        }
    }

    &_cashback {
        padding: 60px 30px 50px 30px;
        @include m1024 {
            padding: 50px;
        }
        h2 {
            font-size: 14px;
            text-align: center;
            margin-bottom: 25px;
            @include m1024 {
                font-size: 18px;
                margin-bottom: 50px;
            }
            strong {
                font-weight: 900;
                font-size: 16px;
                text-transform: uppercase;
                @include m1024 {
                    font-size: 24px;
                }
            }
        }
        &-list {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 35px;
            @include m1024 {
                margin-bottom: 75px;
            }
            &:before {
                content: '........................................................................................................';
                position: absolute;
                top: 0;
                left: 23px;
                width: 100%;
                height: 3px;
                z-index: 0;
                font-size: 14px;
                transform: rotate(90deg);
                transform-origin: top left;
                @include m1024 {
                    left: 0;
                    top: 10px;
                    transform: none;
                    font-size: 26px;
                    letter-spacing: 3.9px;
                }
            }
            ul {
                @include flex(flex-start, flex-start, column);
                @include m1024 {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    gap: 10px;
                }
            }
            li {
                position: relative;
                @include flex(flex-start, center, row, nowrap);
                padding: 15px 0;
                font-size: 14px;
                @include m1024 {
                    flex-direction: column;
                    justify-content: center;
                    font-size: 16px;
                    flex: 1;
                }
                strong {
                    text-transform: uppercase;
                    font-weight: 900;
                    @include m1024 {
                        font-size: 15px;
                    }
                }
                &:first-of-type, &:last-of-type {
                    &:before {
                        content: '';
                        width: 100%;
                        height: 50%;
                        background: $grey-very-light;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        @include m1024 {
                            width: 50%;
                            height: 100%;
                        }
                    }
                }
                &:last-of-type {
                    &::before {
                        top: inherit;
                        bottom: 0;
                        @include m1024 {
                            left: inherit;
                            right: -2px;
                        }
                    }
                }
                div {
                    z-index: 1;
                    @include m1024 {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                    }
                }
            }
        }

        &-picto {
            position: relative;
            width: 23px;
            @extend .icomoon;
            color: $purple;
            font-size: 20px;
            margin-right: 15px;
            background: $grey-very-light;
            z-index: 1;
            text-align: center;
            padding: 3px 0;
            @include m1024 {
                font-size: 29px;
                width: 38px;
                margin-bottom: 15px;
                margin-right: 0;
                padding: 0 3px;
            }
            &.picto {
                &-buy {
                    @extend .icon-caddie;
                }
                &-checkup {
                    @extend .icon-info;
                }
                &-select {
                    @extend .icon-check;
                }
                &-photo {
                    @extend .icon-photo;
                }
                &-refund {
                    @extend .icon-money;
                }
                &-print {
                    @extend .icon-print;
                }
                &-deliver {
                    @extend .icon-hand;
                    font-size: 11px;
                }
            }
        }
        &-button {
            text-align: center;
        }
    }
    .c-modal_postPreview {
        margin: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .c-communityPostCard {
            margin: 40px auto 30px;
            width: 90%;
        }
        .c-communityPostCard-content-likes {
            display: none;
        }
    }
}
