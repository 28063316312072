.home {
    background:$white;
    .c-breadcrumb {
        display:none;
    }
    .c-search {
        display: none;
    }
    .c-sliderLarge {
        @include m1023 {
            margin-bottom:0;
        }
        &_container {
            @include m1023 {
                margin-bottom:20px;
            }
        }
    }
}