.t-sitemap {
    h1 {
        margin-bottom:46px;
        text-transform: initial;
        @include m1024 {
            font-size:31px;
            margin-bottom: 70px;
        }
    }
    h2 {
        @extend .title-2;
        margin-bottom:15px;
        @include m1024 {
            font-size:18px;
        }
    }
    &_content {
        padding:0 15px;
        &:last-child {
            margin-bottom: 70px;
        }
        @include m1024 {
            @include flex(flex-start, flex-start, row, nowrap);
            max-width:740px;
            margin: 0 auto;
            padding:0;

            &:last-child {
                margin-bottom: 140px;
            }
        }
    }
    &_nav {
        @include m1024 {
            // width:calc(100% - (100% - 60px)/3);
            width: 66.666666666%;
            @include flex(flex-start, flex-start, row, wrap);
        }
        .t-sitemap_navItem {
            @include m1024 {
                width:calc(50% - 30px);
                margin-right:30px;
                margin-bottom:45px;
            }
        }
    }
    &_nav2 {
        @include m1024 {
            // width:calc((100% - 60px)/3);
            width: 33.33333333%;
        }
        .t-sitemap_navItem {
            @include m1024 {
                margin-bottom:10px;
            }
        }
    }
    &_navItem {
        margin-bottom: 40px;
        @include m1023 {
            margin-bottom: 20px;
        }
    }
    ul {
        li {
            font-size:16px;
            margin-bottom:15px;
            @include m1024 {
                margin-bottom:8px;
            }
        }
    }

    // Site map links
    &_nav.sitemap-links {
        width: 100%;

        .t-sitemap_navItem {
            @include m1024 {
                width: calc(33.33333333% - 30px);
                margin-bottom: 0;
            }
        }
    }
}