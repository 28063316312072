.t-thanks {
    //.c-breadcrumb, .c-search {
    //    display: none;
    //}
    .t-contentPage_container {
        padding: 28px;
        text-align: center;
        @include m1024 {
            padding: 40px 90px;
        }
        h2 {
            margin-bottom: 18px;
        }
        p + p {
            margin-top: 20px;
            @include m1024 {
                margin-top: 24px;
            }
        }
        .t-contentPage_container__button {
            margin-top: 20px;
            @include m1024 {
                margin-top: 24px;
            }
        }
    }
}