.t-accountCommunity {
    .c-search { display: none; }

    &_wrapper { 
        margin: 30px auto 50px;
        @include m1024 {
            margin: 50px auto 80px;
        }
    }
    
    h1 {
        margin-bottom: 20px;
        font-size: 18px;
        text-transform: unset;
        @include m1024 {
            font-size: 30px;
        }
    }
    &_content {
        width:calc(100% - 20px);
        height: 100%;
        margin:0 auto;
        background: $white;
        @extend .rounded2;
        @include flex(flex-start, center, row, nowrap);
        overflow: hidden;
        // margin-bottom:160px;
        @include m1024 {
            max-width:560px;
        }
    }
    &_profil {
        width:35%;
        height:100%;
        padding:20px;
        @include flex(center, center, column);
        font-size:16px;
        @include bold;
        text-align: center;
        @include m1024 {
            font-size:13px;
        }
        div {
            width:65px;
            height: 65px;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 14px;
            @include m1024 {
                width:55px;
                height: 55px;
            }
        }
    }
    &_infos {
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.08);
        width:70%;
        @include flex(stretch, stretch, column)
    }
    &_elt {
        position:relative;
        padding:20px 10px 20px 60px;
        font-size:14px;
        @include black;
        @include m1024 {
            font-size:22px;
            padding:25px 20px 25px 90px;
        }
        &:before {
            position: absolute;
            top:calc(50% - 18px);
            left:10px;
            width:36px;
            height:36px;
            display: inline-block;
            border-radius: 100%;
            border-bottom-left-radius: 0;
            @extend .icomoon;
            color:$white;
            text-align: center;
            @include m1024 {
                top:calc(50% - 25px);
                left:20px;
                width:50px;
                height:50px;
            }
        }
        strong {
            font-size:22px;
            @include m1024 {
                font-size:39px;
            }
        }
    }
    &_qr {
        background: $orange-light;
        &:before {
            @extend .icon-questions;
            font-size:15px;
            background: $orange;
            @include flex(center, center);
            @include m1024 {
                font-size:20px;
            }
        }
    }
    &_tips {
        background: $purple-light;

        &:before {
            @extend .icon-tips;
            font-size:21px;
            background: $purple;
            @include flex(center, center);
            @include m1024 {
                font-size:28px;
            }
        }
    }
    &_comments {
        &:before {
            content:'...';
            font-size:20px;
            @include bold;
            background: $grey;
            color:$purple;
            @include m1024 {
                font-size:28px;
            }
        }
    }
}