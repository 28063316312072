.c-search {
    &_form {
        width: 100%;
        margin: 0 auto 15px auto;
        @include m1024 {
            margin-bottom: 40px;
        }
    }
    &_line {
        background: $white;
        box-shadow: 0 0 18px 0 rgba(141, 141, 141, 0.09);
        @extend .rounded;
        height: 45px;
        padding: 10px 15px;
        @include flex(flex-end, center, row-reverse);
        @include m1024 {
            height: 55px;
        }
        #search-input {
            &::-webkit-search-cancel-button{
                -webkit-appearance: none;
                cursor: pointer;
                appearance: none;
                height: 10px;
                width: 10px;
                background-size: 10px 10px;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
                filter: invert(14%) sepia(34%) saturate(3297%) hue-rotate(275deg) brightness(101%) contrast(93%);
                @include m1024 {
                    height: 15px;
                    width: 15px;
                    background-size: 15px 15px;
                }
            }
        }
        button {
            width: 18px;
            height: 20px;
            @include flex (center, center);
            font-size: 18px;
            margin-right: 10px;
            @include m1024 {
                width: 24px;
                height: 24px;
                font-size: 24px;
            }
        }
        input, textarea {
            width: calc(100% - 28px);
            //height: 100%;
            height: auto;
            font-size: 14px;
            @include semiBold;
            font-style: italic;
            -webkit-appearance: none;
            @include m1024 {
                font-size: 18px;
            }
            &::placeholder {
                color: $purple;
                opacity: 1; /* Firefox */
            }
            &:-webkit-input-placeholder { /* Chrome, Safari, Opera */
                color: $purple;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $purple;
            }
        }
    }
}

.t-communitySearch-astuce, .t-communitySearch-question {
    .c-search {
        display: none;
    }
}