.t-diy {
    background: $white;
    &_container {
        position: relative;
        background: $white;
        margin: 0 -20px;
        padding:0 20px;
        z-index:1;
        @include m1024 {
            margin:0 auto;
            width:82.82%;
            padding:0 40px;
        }
        &.rounded-- {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: -55px -20px 0 -20px;
            @include m1024 {
                margin:-30px auto 0 auto;
            }
        }
    }
    &_contentMore {
        opacity:0;
        max-height: 0;
        overflow: hidden;
        transition:all .3s ease-out;
        &.show-- {
            max-height:99999px;
            opacity:1;
        }
    }
    &_more {
        margin:0 auto 25px auto;
        @extend .btn-2;
        display: block;
        @include m1024 {
            margin:0 auto 40px auto;
        }
        &.hide-- {
            display: none;
        }
    }
    &_grey {
        position:relative;
        background: $grey-light;
        margin-top:-20px;
        z-index:0;
        @include m1024 {
            margin-top:0px;
        }
    }
    &_articles {
        padding-top:60px;
        @include m1024 {
            padding-top:70px;
        }
    }
}