.c-timeline {
    position: relative;
    overflow: hidden;
    @include flex(space-between, center);
    margin-bottom: 39px;
    @include m1024 {
        margin-bottom: 54px;
    }
    &__item {
        position: relative;
        span {
            position: relative;
            z-index: 1;
            @include flex(center, center);
            width: 31px;
            height: 31px;
            border-radius: 50%;
            background-color: $purple-light;
            @include black;
            font-size: 14px;
            color: $white;
            @include m1024 {
                width: 62px;
                height: 62px;
                font-size: 28px;
            }
        }

        &:before {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background-color: $purple-light;
            @include m1024 {
                height: 6px;
            }
        }

        @for $i from 1 through 100 {
            &[data-width="#{$i}"] {
                &:before {
                    // container mini - 40px padding = 520 - 40 = 480
                    width: calc(480px * #{$i} / 100);
                    @media screen and (max-width: 519px) {
                        // container mini = screen width - 40px padding = 100vw - 40px
                        width: calc((100vw - 40px) * #{$i} / 100);
                    }
                    @include m1024 {
                        // container mini - 140px padding = 850 - 140 = 710
                        width: calc(710px * #{$i} / 100);
                    }
                }
            }
        }

        &[data-completed="true"] {
            span, &:before {
                background-color: $purple;
            }
        }
    }
}