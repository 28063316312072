.c-footer {
  padding: 30px 0 100px 0;
  background-color: $white;
  @include m1024 {
    padding:30px 0 40px 0;
  }
  &_top {
    @include m1024 {
      @include flex(flex-end, flex-start, row, wrap);
      margin-bottom:30px;
    }
  }
  .c-nl {
    margin-bottom: 25px;
    @include m1024 {
      max-width:225px;
      margin-right:25px;
      margin-bottom: 0;
      order:1;
      border-radius:27px;
      padding:25px 15px;
    }
    h2 {
      font-size:16px;
    }
    p {
      font-size:14px;
    }
  }
  h2 {
    @extend .title-2;
    margin-bottom:16px;
    @include m1024 {
      font-size:15px;
      margin-bottom: 10px;
    }
  }
  &_rs {
    margin-bottom:25px;
    @include m1024 {
      width:calc(100% - 250px);
      text-align: right;
      order:3;
      margin-top:-40px;
      margin-bottom: 0;
    }
    ul {
      @include flex(flex-start, center, row);
      @include m1024 {
        justify-content: flex-end;
      }
      li {
        margin-right:15px;
        &:last-of-type {
          margin-right: 0;
        }

        img {
          width: 40px;
          height: 40px;
          backface-visibility: hidden;
          transition: transform 0.3s ease;
        }

        a:hover img {
          transform: scale(1.1);
        }
      }
    }
    i {
      font-size:42px;
      @include m1024 {
        font-size: 40px;
      }
    }
    .icon-youtube {
      @include flex(center, center);
      color:$white;
      font-size:16px;
      width:42px;
      height:42px;
      background: $purple;
      border-radius: 100%;
      @include m1024 {
        width:40px;
        height:40px;
        font-size: 14px;
      }
    }
  }
  &_nav {
    @include m1024 {
      // width:calc(100% - 250px);
      width: 100%;
      order:2;
      margin:25px 0;
      @include flex(flex-start, flex-start, row, wrap);
    }
    &Item {
      margin-bottom:30px;
      @include m1024 {
        margin-bottom:30px;
        margin-right:15px;
        width:calc((100% - 45px)/4);
      }
      &:last-of-type {
        @include m1024 {
          margin-right:0px;
        }
      }
    }
    ul {
      margin-bottom:16px;
      li {
        margin-bottom:5px;
        @include m1024 {
          font-size:14px;
        }

        a {
          transition: color 0.3s ease;
          &:hover {
            color: $purple-dark;
          }
        }
      }
    }
  }


  &_mentions {
    border-top:1px solid $purple;
    padding-top:20px;
    @include m1024 {
      padding-top:30px;
    }
    p {
      font-size:10px;
      @include semiBold;
      margin-bottom:5px;
      line-height: 2.5;
      @include m1024 {
        line-height: 1.6;
      }
      strong {
        font-size:12px;
        text-transform: uppercase;
        @include bold;
      }
    }

    &-link {
      display: inline-block;
      transition: color 0.3s ease;

      &:not(:last-child)::after {
        content: '-';
        display: inline-block;
        margin: 0 3px;
      }

      &:hover {
        color: $purple-dark;
      }
    } 
  }
}