.t-contact {
    overflow: hidden;
    .c-form {
        .error--.error--step {
            font-size: 17px;
            line-height: 1.59;
            color: $white;
            margin-top: 20px;
            @include bold;
            background-color: $red;
            padding: 10px 15px;
            width: 100%;
            @extend .rounded2;
            text-align: center;
        }
    }
    &_container {
        position: relative;
        margin: -55px -20px 0 -20px;
        padding: 25px 20px;
        background: $white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 1;
        @include m1024 {
            max-width: 795px;
            margin-left: auto;
            margin-right: auto;
            padding: 23px 40px;
            min-height: 550px;
        }

        h1 {
            @extend .title-2;
            margin-bottom: 5px;
            text-align: center;
            @include m1024 {
                margin-bottom: 8px;

            }
        }
        h2 {
            text-align: center;
            @extend .title-2;
            margin-bottom: 9px;
            @include m1024 {
                margin-bottom: 19px;
            }
        }
    }
    &_intro, &_object {
        margin-bottom: 20px;
        @include m1024 {
            margin-bottom: 50px;
        }
        p {
            font-size: 16px;
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            @include m1024 {
                // padding:0 40px;
                text-align: center;
                font-size: 18px;
            }
        }
    }
    &_select {
        &:active {
            outline: 0;
        }
        @include m1024 {
            max-width: 470px;
            margin: 0 auto;
        }
        &__control {
            position: relative;
            border: none !important;
            z-index: 1;
            box-shadow: none !important;
            outline: 0 !important;
            @include m1024 {
                cursor: pointer !important;
            }
            &--menu-is-open {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    bottom: 0;
                    background: $grey-light;
                    z-index: -1;
                }
            }
        }
        &__indicators {
            display: none !important;
        }
        &__value-container {
            padding: 0 !important;
            background: $purple !important;
            color: $white;
            border-radius: 22px !important;
            box-shadow: none !important;
            padding: 19px !important;
            outline: 0;
            &--has-value {
                background: $grey-light !important;
                color: $purple;
                .t-contact_select__single-value {
                    color: $purple !important;
                }
            }
        }
        &__placeholder, &__single-value {
            color: $white !important;
            font-size: 18px !important;
            text-align: center;
            margin: 0 !important;
            width: calc(100% - 38px);
            box-sizing: border-box;
        }
        &__menu {
            background: #ede9e9 !important;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.05) !important;
            border-radius: 0 !important;
            border-bottom-left-radius: 22px !important;
            border-bottom-right-radius: 22px !important;
            border: none !important;
            margin-top: 0px !important;
            padding: 0px 0 0 0;
            z-index: 2 !important;
            &-list {
                padding: 0 !important;
            }
        }
        &__option {
            padding: 22px !important;
            border-bottom: 1px solid $white !important;
            text-align: center !important;
            font-size: 16px !important;
            &:last-of-type {
                border-bottom: none !important;
            }
            &--is-focused {
                background: transparent !important;
            }
            &--is-selected {
                color: $purple !important;
                background: transparent !important;
            }
        }
    }
    &_mode {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height .5s ease-out, opacity .5s ease-out;
        &.show-- {
            max-height: 3000px;
            opacity: 1;
        }
    }
    &_notice {
        text-align: center;
        margin: 0 auto;
        font-size: 11px;
        max-width: 640px;
        @include black();
    }
    &_callbloc {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &_doseur {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height .5s ease-out, opacity .5s ease-out;
        &.show-- {
            min-height: 300px;
            max-height: 2000px;
            opacity: 1;
        }
    }

    &_doseurform {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height .5s ease-out, opacity .5s ease-out;
        background: $grey-light;
        @extend .rounded2;
        padding: 30px 15px;
        @include flex(flex-start, center, column);
        z-index: 0;
        @include m1024 {
            max-width: 470px;
            margin-top: 10px;
            padding: 55px;
        }
        &.show-- {
            min-height: 300px;
            max-height: 2000px;
            opacity: 1;
        }
    }
    &_list {
        @include m1024 {
            @include flex(space-between, flex-start, row, nowrap)
        }
        &.column-- {
            @include m1024 {
                flex-wrap: wrap;
                flex-direction: column;
                width: 212px;
            }
            .t-contact_item {
                @include m1024 {
                    width: 100%;
                }
                &.active-- {
                    @include m1024 {
                        display: none;
                    }
                }
            }
        }
    }
    &_item {
        padding: 0 55px;
        margin-bottom: 25px;
        transition: padding .3s ease-out;
        @include m1024 {
            padding: 0;
            width: calc((100% - 80px) / 3);
        }
        &:last-of-type {
            margin-right: 0;
        }
        &.active-- {
            padding: 0;
            @include m1024 {
                width: 100%;
            }
            .btn-contact {
                @include m1023 {
                    pointer-events: none;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            @include m1023 {
                .t-contact {
                    &_itemInfo {
                        display: none;
                    }
                    &_more {
                        opacity: 1;
                        max-height: 5000px;
                        margin-bottom: 45px;
                    }
                }
            }
        }
        p {
            @include m1024 {
                font-size: 22px;
                padding: 0;
            }
        }
        strong {
            font-size: 26px;
            @include extraBold;
            @include m1024 {
                font-size: 25px;
            }
        }
        .btn-contact {
            position: relative;
            display: block;
            background: rgba($purple, .5);
            @extend .rounded2;
            width: 100%;
            @include flex(center, center, column);
            font-size: 25px;
            @include extraBold;
            line-height: 1;
            background-size: auto 110px;
            background-repeat: no-repeat;
            background-position: center 25px;
            padding: 150px 20px 25px 20px;
            @include m1024 {
                background-size: auto 100px;
                padding: 0;
                margin-bottom: 10px;
                // padding: 136px 25px 30px 25px;

            }
            &:after {
                @include m1024 {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }
            &.contactByCall {
                background-image: url("../../assets/img/contact-phone.svg")
            }
            &.contactByCallback {
                background-image: url("../../assets/img/contact-callback.svg");
            }
            &.contactByMail {
                background-image: url("../../assets/img/contact-mail.svg");
            }
            h3 {
                @include m1024 {
                    position: absolute;
                    bottom: 20px;
                    min-height: 46px;
                    font-size: 23px;
                }
            }
        }
    }
    &_itemInfo {
        font-size: 23px;
        @include bold;
        text-align: center;
        line-height: 1;
        margin-top: 7px;
        strong {
            @include black();
        }
    }
    &_more {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height .3s ease-out .2s, opacity .3s ease-out .2s, margin .3s ease-out .2s;
        background: $grey-light;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        & > div {
            padding: 20px 10px 20px;
            @include m1024 {
                padding: 30px 25px;
            }
        }
        p {
            font-size: 16px;
            @include bold;
            line-height: 1.1;
            margin-bottom: 8px;
            text-align: center;
            @include m1024 {
                font-size: 18px;
                max-width: inherit;
                margin-bottom: 10px;
            }
        }
    }
    &_form {
        @include flex(flex-start, center, column);
        @include m1024 {
            padding: 0 30px;
        }
        label:not(.labelRadio) {
            display: block;
            font-size: 16px;
            @include bold;
            line-height: 1.1;
            margin-bottom: 10px;
            text-align: left;
            @include m1024 {
                font-size: 18px;
            }
        }
        input[type=tel] {
            @extend .btn-1;
            width: 100%;
            -webkit-appearance: none;
        }
        input[type=text] {
            @extend .btn-1;
            width: 100%;
            -webkit-appearance: none;
        }
        textarea {
            @extend .btn-1;
            width: 100%;
            height: 225px;
            border-radius: 20px;
            -webkit-appearance: none;
        }
        button[type=submit] {
            display: inline-block;
            @extend .btn-2;
            margin: 0 auto;
            &:disabled {
                opacity: .5;
            }
        }
    }
    &_line {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
        &Elt {
            position: relative;
        }
    }
    &_tooltip {
        position: absolute;
        top: 0;
        right: 11px;
        height: 100%;
        @include flex(center, center, row, nowrap);
        cursor: pointer;
        color: $grey-dark;
        .icon-what {
            font-size: 16px;
            opacity: .8;
        }
        .c-tooltip_help {
            right: -20px;
            @include m1024 {
                right: -60px;
            }
        }
    }
    &_file {
        button:not(.icon-close) {
            background: $purple;
            @extend .rounded2;
            color: $white;
            font-size: 13px;
            width: 100%;
            padding: 20px 25px;
            @include flex(flex-start, center, row, nowrap);
            @include m1024 {
                font-size: 17px;
            }
            span {
                display: block;
                text-align: left;
            }
            strong {
                font-size: 14px;
                @include m1024 {
                    font-size: 16px;
                }
            }
            i {
                font-size: 31px;
                margin-right: 12px;
                @include m1024 {
                    font-size: 37px;
                    margin-right: 20px;
                }
            }
        }
        input {
            display: none;
        }
        .fileContent {
            padding: 0 10px;
            min-height: 10px;
            font-size: 12px;
            @include flex(space-betwenn, center, row, nowrap);
            button {
                color: red;
            }
        }
    }
    &_content {
        &.column-- {
            @include m1024 {
                @include flex(space-between, flex-start, row, nowrap)
            }
        }
    }
    &_callbackbloc {
        label {
            text-align: center !important;
        }
    }
    &_emailbloc {
        p {
            @include m1024 {
                margin-bottom: 30px;
            }
        }
    }
    &_right {
        display: none;
        &.show-- {
            display: block;
            width: calc(100% - 250px)
        }

        .t-contact_item {
            display: none;
            width: 100%;
            &.active-- {
                display: block;
            }
            .btn-contact {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                background-position: center 35px;
                margin-bottom: 0;
                &:after {
                    padding-bottom: 212px;
                }
                h3 {
                    min-height: 0;
                }
            }
            .t-contact_more {
                opacity: 1;
                max-height: 5000px;
                margin-bottom: 45px;
            }
        }
    }
    &_mode, &_doseurform {
        .t-contact_legal {
            margin-top: 30px;
            p {
                margin-bottom: 0;
                text-align: left;
                font-weight: 400;
                font-size: 13px;
                line-height: 1.2em;
                & + p {
                    margin-top: 10px;
                }
            }
            a {
                text-decoration: underline;
                text-underline-offset: -3px;
            }
        }
    }
}


