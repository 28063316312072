.c-article {
    position: relative;
    @extend .rounded2;
    overflow: hidden;
    background: $white;
    box-shadow: -0.8px 3.9px 6.6px 0.4px rgba(141, 141, 141, 0.04);
    @include m1024 {
        cursor: pointer;
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.16);
        transition: transform .35s ease-in-out;
        &:hover {
            transform: scale(1.02);
        }
    }
    // &.desactived-- {
    //     .c-article_visuel {
    //         &:after {
    //             position: absolute;
    //             content:'';
    //             width:100%;
    //             height: 100%;
    //             top:0;
    //             left:0;
    //             background:rgba(0, 0, 0, .2);
    //             z-index: 1;
    //         }
    //     }
    // }
    &_state {
        position: absolute;
        bottom:0;
        right:0;
        width:100%;
        height: 100%;
        font-weight: 600;
        color:#FFF;
        font-size:14px;
        @include flex(center, center);
        @include m1024 {
            font-size:18px;

        }
        span {
            background:$purple;
            padding:5px 10px;
        }
        &.passed-- {
            text-align: center;
            @include flex(center, center);
            span {
                position: absolute;
                top:10%;
                left:32%;
                width: 100%;
                transform: rotate(45deg);
                transform-origin: center center;
                @include m1024 {
                    top:12%;
                    left:35%;
                }
            }
        }
        &.incoming-- {
            padding:5px 5px;
            @include m1024 {

            }
            span {
                border-radius: 20px;
            }
        }
    }
    &_visuel {
        position: relative;
        width: 100%;
        background-size: cover;
        .t-tips &, .t-product &, .t-landingPage &, .t-article &, .t-economy &, .t-brands &{
            height: 150px;
        }
        @include flex(flex-start, flex-start, column);
        @include m1024 {
            .t-tips &, .t-product &, .t-landingPage &, .t-article &, .t-economy &, .t-brands &, .t-accountFavorite &{
                height: 210px;
            }
            flex-direction: row;
        }
    }
    &_img {
        position: relative;
        width: 100%;
        // height: 150px;
        background-size: cover;
        background-position: center center;
        padding: 12px 10px;
        @include m1024 {
            // height: 210px;
            flex-direction: row;
            padding: 16px 50px 16px 16px;
        }
        &.passed-- {
            -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
            filter: grayscale(100%);
            opacity: .8;
        }
        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 91.2%;
        }
    }
    &_category,
    &_favorite {
        position: absolute;
        top: 12px;
        @include m1024 {
            top: 16px;
        }
    }
    &_category {
        left: 10px;
        background: $pink;
        color: $white;
        padding: 8px 14px;
        border-radius: 20px;
        font-size: 9px;
        @include black;
        text-transform: uppercase;
        margin-right: 5px;
        margin-bottom: 3px;
        @include m1024 {
            left: 16px;
            font-size: 13px;
        }
    }
    &_favorite {
        z-index: 2;
        right: 10px;
        color: $white;
        @extend .icomoon;
        @extend .icon-favoris;
        font-size: 24px;
        &.favorite-- {
            @extend .icon-favoris-on;
        }
        @include m1024 {
            font-size: 29px;
            top: 16px;
            right: 16px;
        }
    }
    &_video {
        position: absolute;
        top: 55px;
        left: calc(50% - 25px);
        z-index: 9;
        color: $white;
        font-size: 51px;
        @extend .icomoon;
        @extend .icon-player;
        @include m1024 {
            top: 65px;
            left: calc(50% - 43px);
            font-size: 86px;
        }
    }
    &_content {
        padding: 15px;
        height: 100%;
        @include flex(space-between, flex-start, column);
        @include m1024 {
            min-height: 140px;
        }
        h3 {
            @extend .title-3;
            margin-bottom: 8px;
        }
    }
    &_container {
        height: calc(100% - 150px);
        @include m1024 {
            height: calc(100% - 210px);
        }
    }
    &_time {
        font-size: 11px;
        line-height: 1.21;
        width: 100%;
        @include extraBold;
        @include flex(flex-start, center, row, nowrap);
        margin-right: 5px;
        color: $pink;
        @include m1024 {
            font-size: 14px;
            margin-right: 14px;
        }
        &:before {
            content: $icon-time;
            @extend .icomoon;
            font-size: 15px;
            margin-right: 6px;
            @include m1024 {
                font-size: 21px;
            }
        }
    }
}