.c-filter {
    &_select {
        flex-shrink: 0;
        &__control {
            border: none !important;
            background: none !important;
            min-height: 30px !important;
            box-shadow: none !important;
        }
        &__value-container {
            width: 100% !important;
            overflow: hidden;
            padding: 0 7px !important;
            flex-wrap: nowrap!important;
            @include m374 {
                padding-left: 4px !important;
                padding-right: 4px !important;
            }
            @include m1024 {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
        &__placeholder, &__single-value {
            position: static !important;
            transform: none !important;
            font-size: 14px !important;
            color: $purple !important;
            @include extraBold;
            overflow: hidden;
            width: calc(100% - 15px);
            @include m374 {
                font-size: 12px !important;
            }
            @include m768 {
                width: auto;
                @include flex(flex-start, center, row, nowrap);
                overflow: initial !important;
            }
            @include m1024 {
                font-size: 18px !important;

            }
            &:after {
                position: absolute;
                top: calc(50% - 6px);
                right: 4px;
                @extend .icomoon;
                content: '\e91b';
                transform: rotate(90deg);
                display: inline-block;
                margin-left: 10px;
                font-size: 10px;
                margin-top: 2px;
                @include m768 {
                    position: relative;
                    top: inherit;
                    right: initial;
                }
                @include m1024 {
                    font-size: 10px !important;
                    margin-top: 5px;
                }
            }
        }
        &__indicators {
            display: none !important;
        }
        &__menu {
            padding: 0 20px;
            box-shadow: 2.4px 3.2px 11.8px 1.2px rgba(71, 72, 72, 0.13) !important;
            border: none !important;
            border-radius: 0 !important;
            z-index: 2 !important;
            &-list {
                padding: 0 !important;
            }
        }
        &__option {
            padding: 18px 0 !important;
            border-bottom: 1px solid rgba($purple, 0.3);
            font-size: 14px !important;
            @include extraBold;
            @include m1024 {
                font-size: 20px !important;
                cursor: pointer !important;
            }
            &:last-of-type {
                border-bottom: none;
            }
            &--is-focused, &--is-selected {
                background: transparent !important;
                color: $purple !important;
            }
        }
        &--is-disabled {
            opacity:.4;
        }
    }
}