.c-carouselBrands {
    position: relative;
    margin: 10px -20px 20px 0px;
    @include m1024 {
        margin: 0 -70px 20px -70px;
        padding-left: 70px;
        overflow: hidden;
    }
    &_item {
        position: relative;
        overflow: hidden;
        background: $white;
        border-radius: 100%;
        padding: 10px;
    }
    &_visuel {
        width: 100%;
        height: 100%;
        @include flex(center, center, row, nowrap)
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: visible;
    }
    .swiper-wrapper {
        @include flex(flex-start, stretch)
    }

    .swiper-slide {
        position: relative;
        font-size: 18px;
        background: #fff;
        width: 70px;
        height: 70px;
        @include m1024 {
            width: calc((100% - 190px) / 8);
            height: calc((100% - 190px) / 8);
        }
        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
        a {
            position: absolute;
            top: 10px;
            left: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            overflow: hidden;
            @include m1024 {
                top: 15px;
                left: 15px;
                width: calc(100% - 30px);
                height: calc(100% - 30px);
            }
        }
    }

    .swiper-button-prev {
        @extend .icomoon;
        @extend .icon-arrow;

        @include m1023 {
            display: none;
        }
        @include m1024 {
            position: absolute;
            top: 0;
            left: 0px;
            width: 70px;
            height: 100%;
            margin: 0;
            color: $white;
            font-size: 44px;
        }
        &:before {
            transform: rotate(180deg);
            text-shadow: -0.7px 1.9px 8px rgba(0, 0, 0, 0.26);
        }
        &:after {
            display: none;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
    .swiper-button-next {
        @extend .icomoon;
        @extend .icon-arrow;

        @include m1023 {
            display: none;
        }
        @include m1024 {
            position: absolute;
            top: 0;
            right: 0;
            width: 62px;
            height: 100%;
            margin: 0;
            color: $white;
            font-size: 44px;
            overflow: hidden;
            justify-content: flex-end;
        }
        &:before {
            text-shadow: -0.7px 1.9px 8px rgba(0, 0, 0, 0.26);
        }
        &:after {
            content: '';
            width: 106px;
            height: 106px;
            position: absolute;
            top: 0;
            left: 0;
            background: $white;
            opacity: .4;
            border-radius: 100%;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
}