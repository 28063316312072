.t-brands {
    background-color: $grey-very-light;
    &_container {
        position: relative;
        z-index: 1;
        @include m1024 {
            background: $white;
            border-radius: 40px;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
            margin: -40px auto 50px auto;
            padding: 40px;
            width: 82.82%;
        }
    }
    &_capsule {
        margin-bottom: 25px;
        @include m1023 {
            padding: 20px 40px;
            margin-top: -40px;
            background: $white;
            border-radius: 20px;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
            text-align: center;
        }
        @include m1024 {
            @include flex(space-between, center, row, nowrap);
            padding-bottom: 30px;
            border-bottom: 1px solid rgba($purple, .2);
            margin-bottom: 10px;
        }
        h1 {
            @extend .title-2;
            margin-bottom: 0;
        }
        h1 + p {
            margin-top: 15px;
            @include m1024 {
                margin-top: 0;
            }
        }
    }
    &_button {
        @extend .btn-2;
        background: $blue;
    }
    &_content {
        @include m1023 {
            background: $white;
            border-radius: 20px;
            margin: 0 -20px 40px -20px;
            padding: 20px;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        }
    }
    &_intro {
        h2 {
            margin-bottom: 14px;
        }
        img {
            margin-bottom: 14px;
        }
    }
    &_actu {
        @include m1024 {
            margin: 0 -70px;
            padding: 0 70px;
        }
    }
    &_products {
        @include m1024 {
            margin: 0 -70px;
            padding: 0 70px;
        }
    }

    &_articles {
        @include m1024 {
            margin: 0 -70px;
            padding: 0 70px;
        }
    }

    &_br {
        // @include m1024 {
        //     margin: 0 -70px;
        // }
        h2 {
            @extend .title-2;
            @include m1024 {
                padding: 0 70px;
            }
        }
    }

    &_grey {
        background-color: $grey-light;
    }
}