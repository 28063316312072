.c-communityForm {
    margin: 0px auto 40px;
    max-width: 720px;
    @include m1024 {
        margin: 0px auto 100px;
    }
    label, .form-text {
        @include bold;
        font-size: 16px;
        margin-bottom: 12px;
        @include m1024 {
            font-size: 18px;
        }
    }
    .form-text {
        margin-bottom: 50px;
    }
    &-error {
        @include regular;
        color: $red;
        margin-top: 20px;
        text-align: center;
        @include m1024 {
            margin-top: 25px;
        }
    }
    &-two-columns {
        display: block;
        @include m1024 {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .c-communityForm-select {
            width: 100%; 
            @include m1024 {
                width: calc(50% - 12px);
            }
        }
    } 
    .c-form_select {
        &__control {
            box-shadow: 0 0 18px 0 rgba(141, 141, 141, 0.1)!important;
        }
        &__single-value {
            color: $purple!important;
            @include semiBold;
        }
        &__multi-value {
            border-radius: 15px;
            font-size: 10px;
            @include black;
            text-transform: uppercase;
            margin-bottom: 5px;
            background-color: $grey;
            padding: 5px;
            @include m1024 {
                font-size: 12px;
                padding: 6px 10px;
                margin-right: 8px;
            }
            &__label {
                color: $purple;
            }
            &__remove {
                &:hover {
                    background: inherit;
                }
            }
        }
        &__menu {
            overflow: hidden;
            border-radius: 0px 0px 30px 30px!important;
            box-shadow: 0 20px 32px 0 rgba(36, 36, 36, 0.05)!important;
            &-list {
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, .5);
                    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
                }
            }
        }
    }
    &-input {
        display: flex;
        flex-direction: column;
        &.file-- {
            @include m1024 {
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                .community-input-file {
                    width: 60%;
                }
                label {
                    width: 38%;
                    margin-top: 50px;
                }
            }
        }
        input, textarea {
            background-color: $white;
            @include regular;
            box-shadow: 0 0 18px 0 rgba(141, 141, 141, 0.1);
            border-radius: 40px;
            padding: 15px 25px;
            font-size: 14px;
            margin-bottom: 30px;
            resize: none;
            @include m1024 {
                font-size: 18px;
                max-height: 260px;
            }
        }
        .community-input-file {
            position: relative;
            width: 100%;
            height: auto;
            margin-bottom: 30px;
            input[type="file"] {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
                margin-bottom: 0px;
                height: 120px;
            }
            &-btn {
                height: 120px;
                background: $purple;
                border-radius: 40px;
                color: $white;
                width: 100%;
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: left;
                i {
                    font-size: 30px;
                    margin-right: 15px;
                    font-weight: bold;
                }
            }
            &-list {
                margin: 10px 0px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @include m1024 {
                    margin: 16px 0px;
                }
                li {
                    font-size: 12px;
                    @include bold;
                    line-height: 1.35;
                    margin-bottom: 10px;
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    padding-right: 20px;
                    @include m1024 {
                        font-size: 18px;
                        padding-right: 25px;
                    }
                    &:before, &:after {
                        content: "";
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        width: 12px;
                        height: 2px;
                        background-color: $red;
                        @include m1024 {
                            width: 18px;
                        }
                    }
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        &-error {
            @include regular;
            color: $red;
            margin-top: -20px;
            margin-bottom: 20px;
            height: auto;
            width: 100%;
            @include m1024 {
                margin-top: -20px;
                margin-bottom: 25px;
            }
        }
    }
    &-select {
        .c-form_select {
            margin-top: 12px;
            margin-bottom: 30px;
        }
    }
    &-checkbox {
        margin-bottom: 30px;
        input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
            &:checked + label:after {
                width: 35px;
                height: 35px;
                transition: width 0.2s ease, height 0.2s ease;
            }
        }
        label{
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: calc(0% + 18px);
                transform: translate(-50%, -50%);
                border-radius: 50%;
                width: 0px;
                height: 0px;
                background-color: $purple;
                transition: width 0.2s ease, height 0.2s ease;
            }
            &:before {
                content:'';
                background-color: $white;
                box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.05);
                min-width: 35px;
                min-height: 35px;
                border-radius: 50%;
                display: inline-block;
                position: relative;
                cursor: pointer;
                margin-right: 15px;
                border: 1px solid $purple;
            }
        } 
    }
    &-btns_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        @include m1024 {
            justify-content: center;
        }
        .btn-2 {
            @include m1024 {
                margin: 0 10px;
            }
            &:disabled {
                opacity: 0.3;
            }
        }
    }
}