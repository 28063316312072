.c-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    overflow: hidden;
    z-index: 11;
    @include m1024 {
        height: 100px;
    }

    &_content {
        @include flex(space-between, center, row, nowrap);
        height: 50px;
        @include m1024 {
            @include flex(flex-end, center, row, nowrap);
            height: 100px;
        }
    }
    *::-ms-backdrop, .c-header_content {
        justify-content: space-between;
    }
    &_container {
        background: $white;
        height: 50px;
        box-shadow: 2.1px 3.4px 13px 0 rgba(71, 72, 72, 0.13);
        @include m1024 {
            height: 75px;
        }
        & > .container {
            padding: 0 15px;
            @include m1024 {
                padding: 0 25px;
            }
        }
    }
    &_logo {
        position: absolute;
        top: 0;
        left: calc(50% - 32px);
        width: 65px;
        height: 70px;
        z-index: 2;
        -webkit-filter: drop-shadow(2px 3.4px 4px rgba(71, 72, 72, 0.16));
        filter: drop-shadow(2px 3.4px 4px rgba(71, 72, 72, 0.16));
        @include m1024 {
            position: relative;
            top: initial;
            left: initial;
            width: 83px;
            height: 93px;
            margin-right: 17px;
            align-self: flex-start;
        }
        img {
            width: 100%;
        }
    }
    &_left {
        @include flex(flex-start, flex-start, row, nowrap);
        order: 2;
        @include m1024 {
            margin-right: auto;
            height: 100px;
            order: 1;
        }
    }
    *::-ms-backdrop, .c-header_left {
        @include m1024 {
            margin-left: 0;
        }

    }
    &_tools {
        @include flex(flex-end, center, row, nowrap);
        order: 3;
        @include m1024 {
            height: 75px;
            align-self: flex-start;
            order: 2;
        }
    }
    &_login {
        margin: 0 10px;
        &Btn {
            @extend .icomoon;
            @extend .icon-profil;
            font-size: 22px;
            width: 17px;
            height: 22px;
            &:hover {
                &:before {
                    @include m1024 {
                        content:$icon-profil-on;
                    }
                }
            }
            &.connected-- {
                @extend .icon-profil-on;
            }
        }

    }
    &_contact {
        margin: 0 10px;
        width: 22px;
        height: 22px;
        font-size: 22px;
        a {
            &:hover {
                @include m1024 {
                    color: $purple;
                    i.icon-what-2:before {
                        content:$icon-what-2-on;
                    }
                }
            }
            
        }
    }
    &_user {
        font-size: 12px;
        @include extraBold;
        margin: 0 20px 0 10px;
        @include m1024 {
            margin: 0 10px 0 0;
        }
        span {
            display: block;
        }
    }
    &_basket {
        position: relative;
        margin: 0 0 0 10px;
        width: 22px;
        height: 22px;
        font-size: 22px;
        &:hover {
            @include m1024 {
                .c-header_basketNumber {
                    font-size: 12px;
                    &:before {
                        transform: translate(-50%, -50%) scale(1.25);
                    }
                }
                .icon-basket:before {
                    content: $icon-basket-on;
                }
            }
        }
        &Number {
            z-index: 1;
            position: absolute;
            top: 4px;
            right: -2px;
            @include bold;
            font-size: 8.5px;
            text-align: center;
            color: $white;
            transition: font-size .3s ease-in-out;
            &:before {
                z-index: -1;
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $green;
                transition: transform .3s ease-in-out;
            }
        }
        & > div {
            @include m1024 {
                cursor: pointer;
            }
        }
    }
    &_right {
        @include flex(center, center);
        order: 1;
        @include m1024 {
            height: 75px;
            align-self: flex-start;
            order: 3;
        }
    }
    &_toggle {
        &Btn {
            @extend .icomoon;
            @extend .icon-burger-mobile;
            font-size: 17px;
            width: 23px;
            height: 22px;
            @include flex (center, center);
            @include m1024 {
                margin-left: 20px;
                font-size: 22px;
                content: $icon-burger-desktop;
            }
        }

    }

    &.navOpened-- {
        .c-nav {
            left: 0;
            opacity: 1;
            @include m1024 {
                top: 0;
            }
            @include m1023{
                //overflow-y: auto;
            }
        }
        .c-header {
            &_toggle {
                &Btn {
                    &:before {
                        content: $icon-close;
                        font-size: 22px;
                        @include m1024 {
                            font-size: 33px;
                        }
                    }
                }
            }
        }
    }
}

.c-stickyNav {
    height: 65px;
    @include flex(center, flex-end, row, nowrap);
    @include m1023 {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
        padding: 10px 20px;
    }
    @include m1024 {
        height: 75px;
        align-items: center;
    }
    ul {
        @include flex(space-between, flex-end, row, nowrap);
        @include m1023 {
            width: 100%;
            height: 100%;
        }
        li {
            position: relative;
            margin: 0 8px;
            height: 100%;
            color: $purple;
            transition: all .2s ease-out;
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
            // &.current-- {
            .c-stickyNav_link.selected {
                i.icono {
                    @include m1023 {
                        color: $white;
                        transform: translateY(-15px)
                    }
                }

                i.icono-bgd {
                    @include m1023 {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
            // }
            &.economy {
                &:hover {
                    @include m1024 {
                        color: $green;
                    }
                }
                .selected {
                    color: $green;
                }
                .c-stickyNav_link {
                    i.icono {
                        @extend .icon-economy;
                        font-size: 29px;
                    }
                    i.icono-bgd {
                        background-color: $green;
                    }
                }
            }
            &.tips {
                &:hover {
                    @include m1024 {
                        color: $pink;
                    }
                }
                .selected {
                    color: $pink;
                }
                .c-stickyNav_link {
                    i.icono {
                        @extend .icon-tips;
                        font-size: 30px;
                    }
                    i.icono-bgd {
                        background-color: $pink;
                    }
                }
            }
            &.brands {
                &:hover {
                    @include m1024 {
                        color: $blue;
                    }
                }
                .selected {
                    color: $blue;
                }
                .c-stickyNav_link {
                    i.icono {
                        @extend .icon-brands;
                        font-size: 26px;
                    }
                    i.icono-bgd {
                        background-color: $blue;
                    }
                }
            }
            &.community {
                &:hover {
                    @include m1024 {
                        color: $orange;
                    }
                }
                .selected {
                    color: $orange;
                }
                .c-stickyNav_link {
                    i.icono {
                        @extend .icon-community;
                        font-size: 27px;
                    }
                    i.icono-bgd {
                        background-color: $orange;
                    }
                }
            }

            &.comingsoon {
                .c-stickyNav_link {
                    i.icono {
                        font-size: 25px;
                        margin-right: 5px;
                        top: 2px;
                    }
                }
            }
        }
    }
    &_link {
        position: relative;
        font-size: 9px;
        line-height: .8;
        height: 100%;
        @include bold;
        text-align: center;
        @include flex(space-between, center, column);
        @include m1024 {
            font-size: 15px;
            @include flex(flex-start, flex-end, row);
            padding-bottom: 3px;
        }
        i.icono {
            position: relative;
            @extend .icomoon;
            z-index: 1;
            @include m1024 {
                margin-right: 3px;
                margin-bottom: -3px;
            }

        }
        i.icono-bgd {
            position: absolute;
            top: -35px;
            left: calc(50% - 35px);
            content: "";
            width: 70px;
            height: 70px;
            border-radius: 100%;
            border: 6px solid $white;
            opacity: 0;
            transform-origin: bottom;
            transform: scale(0);
            transition: all .3s ease-out;
            z-index: 0;
            @include m1024 {
                display: none;
            }
        }
    }
}

.c-nav {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background: $white;
    z-index: 8;
    transition: all .2s ease-out;
    @include m1024 {
        left: 0;
        top: -100vh;
        z-index: -1;
        padding-top: 100px;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($grey-light, .43);
        z-index: -1;
        pointer-events: none;
        @include m1023{
            position: fixed;
        }
    }
    &_header {
        width: 100%;
        background: $white;
        height: 50px;
        box-shadow: 2.1px 3.4px 13px 0 rgba(71, 72, 72, 0.13);
        .container {
            padding: 0 10px;
            @include flex(flex-start, flex-start, row, nowrap);
        }
        .c-header_user {
            height: 50px;
            @include flex(center, flex-start, column);
        }
    }
    &_right {
        height: 50px;
        margin-left: auto;
        @include flex(flex-end, center, row, nowrap);
    }
    .c-header {
        &_logo {
            position: relative;
            top: inherit;
            left: inherit;
        }
        &_toggle {
            margin-left: 10px;
            &Btn {
                &:before {
                    content: $icon-close;
                    font-size: 22px;
                }
            }
        }
    }

    &_content {
        overflow-y: auto;
        height: calc(100% - 50px);
        padding: 25px 0 20px 0;
        @include m1024 {
            padding-top: 0;
        }
        @include m1023{
            // overflow-y: initial;
            // height: auto;
            padding-bottom: 200px;
        }
    }
    &_list {
        // padding: 0 0 0 52px;
        @include m1024 {
            // width:calc((100% - 160px)/3);
            width: 40%;
            text-align: center;

            // ul {
            //     display: inline-block;
            // }
        }
    }
    &_newsletter {
        width: 100%;
        margin-top: 30px;
        @include m1024 {
            // width:calc((100% - 100px)/3);
            width: 40%;
            margin-top: 0;
            padding: 0 5%;
        }
    }
    &_line {
        @include m1024 {
            @include flex(center, flex-start, row, nowrap);
        }
    }
    &_item {
        margin-bottom: 15px;
        &.hasChildren-- {
            &[data-sub-opened="true"],
            &.is-open-- {
                .c-nav_sub {
                    max-height: 900px;
                    opacity: 1;
                }
            }
        }
        &.economy {
            i {
                @extend .icon-economy;
                background: $green;
                font-size: 26px;
                @include m1024 {
                    font-size: 31px;
                }
            }
        }
        &.tips {
            i {
                @extend .icon-tips;
                background: $pink;
                font-size: 31px;
                @include m1024 {
                    font-size: 36px;
                }
            }
        }
        &.brands {
            i {
                @extend .icon-brands;
                background: $blue;
                font-size: 28px;
                @include m1024 {
                    font-size: 32px;
                }
                &:before {
                    margin-bottom: 3px;
                }
            }
        }
        &.community {
            i {
                @extend .icon-community;
                background: $orange;
                font-size: 25px;
                @include m1024 {
                    font-size: 28px;
                }
                &:before {
                    margin-bottom: 3px;
                }
            }
        }
        &.account {
            i {
                @extend .icon-profil;
                font-size: 22px;
                @include m1024 {
                    font-size: 26px;
                }
            }
        }
        &.contact {
            i {
                @extend .icon-what-2;
                font-size: 22px;
                @include m1024 {
                    font-size: 28px;
                }
            }
        }
        &.what {
            i {
                @extend .icon-info;
                font-size: 22px;
                @include m1024 {
                    font-size: 26px;
                }
            }
        }
        &.basket {
            i {
                @extend .icon-basket;
                font-size: 22px;
                @include m1024 {
                    font-size: 26px;
                }
            }
        }

        &Elt {

            font-size: 17px;
            @include bold;
            i {
                @extend .icomoon;
                position: relative;
                background: $purple;
                color: $white;
                width: 45px;
                height: 45px;
                border-radius: 100%;
                margin-right: 13px;
                @include flex(center, center);
                @include m1024 {
                    width: 53px;
                    height: 53px;
                }
            }
        }
    }
    &_sub {
        max-height: 0;
        opacity: 0;
        transition: all .5s ease-out;
        padding-left: 58px;
        overflow: hidden;

        @include m1024 {
            padding-left: 66px;
        }
        &Menu {
            margin-bottom: 20px;
        }
        &Item {
            margin-bottom: 10px;
            font-size: 17px;
            text-align: left;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &Link {
            &:hover {
                cursor: pointer;
            }
            &::after {
                content: $icon-arrow-rounded;
                display: inline-block;
                font-family: 'icomoon';
                font-size: 12px;
                transform: rotate(90deg);
                margin-left: 10px;
            }
            &.visible {
                &::after {
                    transform: rotate(-90deg);
                }
            }
            &_sub {
                &Menu {
                    overflow: hidden;
                    transition: max-height 0.2s
                }
                &Item {
                    display: flex;
                    flex-direction: column;
                    a {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    &_link {
        @include flex(flex-start, center, row, nowrap);
        @include m1024 {
            &:not(.no-link) {
                cursor: pointer;
            }

            i {
                transition: transform .2s ease-out;
            }
            &:hover {
                i {
                    transform-origin: center center;
                    transform: scale(1.08);
                }
            }
        }
    }

    &_deconnexion {
        @extend .btn-1;
        width: 100%;
        font-size: 17px;
        @include bold;
        @extend .effetHoverScale;
        margin-top: 15px;
        max-width: 250px;
        @include m1024 {
            padding: 7px 10px;
        }
    }
}



