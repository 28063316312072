.c-carouselActus {
    position: relative;
    margin: 0 -20px 30px 0px;
    overflow: hidden;
    @include m1024 {
        margin:0 -70px 100px 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: $grey-dark;
    }
    &_item {
        position: relative;
        @extend .rounded2;
        overflow: hidden;
        background: $white;
        box-shadow: -0.8px 3.9px 6.6px 0.4px rgba(141, 141, 141, 0.04);
        @include m1024 {
            cursor: pointer;
            box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.16);
            margin: 20px 0 20px 0;
        }
    }
    &_visuel {
        width: 100%;
        // height: 150px;
        // background-size: contain;
        // background-position: center center;
        // background-repeat: no-repeat;
        // @include m1024 {
        //     height: 160px;
        // }
    }
    &_visuelContent {
        width: 100%;
        position: relative;
        
    }
    &_content {
        padding: 15px;
        min-height: 100px;
        @include m1024 {
            min-height: 140px;
        }
        h3 {
            @extend .title-3;
        }
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        position:relative;
        padding-bottom:25px;
    }
    .swiper-wrapper {
        @include flex(flex-start, stretch);
    }
  
    .swiper-slide {

        font-size: 18px;
        background: #fff;
        width: 87%;
        height: auto;
        @include m1024 {
            width: calc(50% - 45px);
            max-width:470px;
            width: calc(25% - 28px);
        }
        @include m1023 {
            max-width: 45%;
        }
    }
    .swiper-slide:first-of-type {
        margin-left:20px;
        @include m1024 {
            margin-left:70px;
        }
    }
    .swiper-slide:last-of-type {
        margin-right:20px;
        @include m1024 {
            margin-right:70px;
        }
    }

    .swiper-pagination {
        bottom:0;
        width:100%;
        .swiper-pagination-bullet {
            width:7px;
            height: 7px;
            background: $blue;
            opacity:0.3;
            margin: 0 4px;
            @include m1024 {
                width:12px;
                height: 12px;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
    }

    // No carousel
    &.no-carousel {
        @include m1024 {
            margin-right: 0;
            margin-bottom: 70px;

            .swiper-slide {
                width: calc(50% - 12px);

                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}