.t-accountHome {
    h2 {
        margin-bottom: 24px;
        text-transform: initial;
        @include flex(space-between, center, row, nowrap);
        @include m1023 {
            margin-bottom: 32px;
        }
    }

    .c-search {
        display: none;
    }

    .c-breadcrumb {
        @include m1023 {
            max-width: 60%;
        }
    }
    &_redirections {
        .mainRedirections {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            margin-bottom: 80px;
            &_card {
                width: calc(50% - 12px);
                background-color: $white;
                border-radius: 40px;
                padding: 40px;
                box-shadow: 0px 4px 20px 0px rgba($black, 0.10);
                @include m1023 {
                    width: 100%;
                    padding: 32px 24px;
                }
                h2 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 42px;
                    margin-bottom: 32px;
                    @include m1023 {
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 38px;
                        margin-bottom: 16px;
                    }
                }
            }
        }
        .questionsRedirections {
            h2 {
                font-size: 32px;
                font-weight: 800;
                line-height: normal;
                margin-bottom: 16px;
            }
            .c-univers {
                margin: 40px auto 80px;
                justify-content: flex-start;
                &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    box-shadow: 2.1px 3.4px 13px 0 rgba(71, 72, 72, 0.13);
                }
                &_container {
                    background-color: $white;
                    color: $purple;
                    .c-univers_icon {
                        &:before {
                            background-image: url('../../assets/img/account/picto-foyer.png');
                        }
                        &:after {
                            color: $purple;
                            opacity: 1;
                        }
                    }
                    &:nth-child(2n) {
                        .c-univers_icon {
                            &:before {
                                background-image: url('../../assets/img/account/picto-lessive.png');
                            }
                        }
                    }
                    &:nth-child(3n) {
                        .c-univers_icon {
                            &:before {
                                background-image: url('../../assets/img/account/picto-cheveux.png');
                            }
                        }
                    }
                    &:nth-child(4n) {
                        .c-univers_icon {
                            &:before {
                                background-image: url('../../assets/img/account/picto-maison.png');
                            }
                        }
                    }
                    h3 {
                        font-size: 26px;
                        font-weight: 800;
                        line-height: 1.1;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        margin-bottom: 4px;
                        @include m1023 {
                            font-size: 22px;
                        }
                    }
                    p {
                        font-size: 18px;
                        line-height: 1.2;
                        font-weight: 700;
                        @include m1023 {
                            font-size: 16px;
                        }
                        &:after {
                            content: "";
                            border: solid $white;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 4px;
                            transform: rotate(-45deg);
                            margin-left: 8px;
                        }
                    }
                }
                &_icon {
                    top: 16px;
                    right: 16px;
                    @include m1023 {
                        top: 12px;
                        right: 12px;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        max-width: 50px;
                        width: 70%;
                        height: 50px;
                        transform: translate(-50%, -50%);
                        top: calc(50% - 5px);
                        left: 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }
                }
                &_content {
                    padding: 24px;
                    @include m1023 {
                        padding: 16px;
                    }
                }
            }
        }
        .myactivities {
            .c-univers {
                justify-content: flex-start;
            }
        }
    }
}

.t-accountNotif {
    .c-search {
        display: none;
    }

    &_wrapper {
        margin: 30px 0 50px;
        @include m1024 {
            margin: 50px 0 80px;
        }
    }

    h1 {
        margin-bottom: 20px;
        text-transform: initial;
    }
    &_container {
        background: $white;
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.09);
        margin-bottom: 50px;
        @include m1024 {
            margin-bottom: 35px;
        }
    }
    &_content {
        margin: 0 -20px;
        @include m1024 {
            width: 74.48%;
            margin: 0 auto;
        }
    }
    &_item {
        padding: 20px;
        border-bottom: 1.5px solid rgba($color: $purple, $alpha: .24);
        @include flex(flex-start, center, row, nowrap);
        @include m1024 {
            padding: 40px 0;
        }
        &:last-of-type {
            border-bottom: none;;
        }
    }
    &_avatar {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: $purple;
        color: $white;
        margin-right: 12px;
        flex-shrink: 0;
        @include flex(center, center, row, nowrap);
        overflow: hidden;
        @include m1024 {
            width: 46px;
            height: 46px;
            margin-right: 16px;
        }
        &:before {
            @extend .icomoon;
            @extend .icon-profil;
            font-size: 20px;
            @include m1024 {
                font-size: 24px;
            }
        }
        & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    &_notif {
        p {
            margin-bottom: 8px;
            @include m1024 {
                margin-bottom: 0;
            }
            &:first-of-type {
                font-size: 11px;
                @include light;
                @include m1024 {
                    font-size: 13px;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
                font-size: 15px;
                @include m1024 {
                    font-size: 16px;
                }
            }
        }
    }
}