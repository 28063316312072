// Comment Item
.c-commentItem {
	position: relative;

	&:not(:first-child) {
		border-top: 1px solid rgba($purple, 0.3);
		padding-top: 40px;
		margin-top: 40px;
	}

	&_add-btn,
	&_see-link {
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
  		@include extraBold;
  		transition: color 0.2s ease;
  		@include m1024 {
			font-size: 18px;
			&:hover {
				color: $pink;
			}
		}

  		&:not(:last-child) {
  			margin-right: 20px;
  		}

  		
	}

	&_add-btn {
  		&::before {
  			content: $icon-comment;
  			font-family: 'icomoon';
  			display: inline-block;
  			vertical-align: middle;
  			font-size: 14px;
  			margin-right: 8px;
	  		@include m1024 {
				font-size: 20px;
			}
  		}
	}

	&_see-link {
		text-decoration: underline;
		transition: all 0.3s ease;

		&.hide-- {
			opacity: 0;
			visibility: hidden;
		}
	}

    &_more-content {
    	opacity: 0;
	    max-height: 0;
	    overflow: hidden;
	    transition: all .3s ease-out;
	    position: relative;

	    &.show-- {
	    	max-height: 99999px;
    		opacity: 1;

    		&:first-child {
    			margin-bottom: 30px;
    		}
	    }
    }
}