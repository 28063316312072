.t-product {
    background: $white;
    .c-accordion + .t-product_recycle{
        margin-top: 20px;
    }
    &_container {
        position: relative;
        z-index: 1;
        margin: 0px -20px 0;
        padding: 20px 20px 0 20px;
        @include m1023 {
            background: $white;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        }

        @include m1024 {
            margin: 20px auto 0;
            width: 82.605%;
            padding: 0 40px;
        }
        h2 {
            @extend .title-2;
            margin: 20px 0 5px 0;
        }
    }
    &_recycle {
        color: $blue;
        font-size: 14px;
        @include flex(flex-start, center, row, nowrap);
        @include bold;
        margin-bottom: 20px;
        @include m1024 {
            font-size: 22px;
        }
        img {
            margin-right:6px;
            @include m1024 {
                font-size: 9px;
            }
        }
        .icon-recycle {
            font-size: 30px;
            margin-right: 6px;
            @include m1024 {
                font-size: 44px;
            }
        }
    }
    &_grey {
        position: relative;
        background: $grey-light;
        margin-top: -20px;
        z-index: 0;
        @include m1024 {
            margin-top: 0px;
        }
    }
    &_buttonList {
        @include flex(flex-start, stretch, column);
        padding-bottom: 25px;
        margin-top: 15px;
        text-align: center;
        @include m1024 {
            margin-top: 45px;
            padding-bottom: 20px;
        }
        a {
            @extend .btn-3;
            background-color: $blue;
            width: 100%;
            display: block;
            margin-bottom: 10px;
            @include m1024 {
                width: auto;
                display: inline-block;
                margin-bottom: 30px;
            }
        }
    }
    &_articles {
        padding-top: 60px;
        @include m1024 {
            padding-top: 70px;
        }
    }
}