.c-nl {
    background: $purple;
    @extend .rounded;
    color:$white;
    padding:25px 20px;
    h2 {
        font-size:17px;
        text-transform: uppercase;
        @include extraBold;
        margin-bottom:8px;
        @include m1024 {
            font-size:20px;
        }
    }
    p {
        font-size:14px;
        margin-bottom: 12px;
        @include m1024 {
            font-size:16px;
        }
    }
    &_line {
        @include flex(space-between, center, row, nowrap);
        overflow: hidden;
        background: $white;
        @extend .rounded;
        padding:3px 3px 3px 15px;
        box-shadow: 6.9px 8.5px 19.3px 1.7px rgba(60, 60, 60, 0.14);
        input {
            width: calc(100% - 50px);
            height: 39px;
            font-size:14px;
            color:$purple;
        }
        button {
            @include flex(center, center);
            width:39px;
            height: 39px;
            border-radius: 100%;
            background: $purple;
            color:$white;
            @include extraBold;
            font-size:13px;
        }
    }
}