.popinAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    &__box {
        background: #fff;
        width: 720px;
        max-width: 96%;
        padding: 40px;
        position: relative;
    }

    &__close {
        position: absolute;
        top: 0px;
        right: 10px;
        transform: rotate(45deg);
        font-size: 40px;
    }

    &--invisible{
        display: none;
    }
}