.t-economy {
    .main {
        padding-bottom: 40px;
        @include m1024 {
            padding-bottom: 92px;
        }
    }
    .c-filter_select__menu {
        width:100%!important;
        @include m1024 {
            width: 150% !important;
        }
    }
    .c-filtersLeft .c-filter_select__menu {
        width:100%!important;
    }
    .c-filtersRight .c-filter_select__menu {
        right:0;
    }
    .c-filter_select__value-container {
        padding:0 4px!important;
    }
    // .css-b8ldur-Input {
    //     display: none;
    // }
}