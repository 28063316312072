.t-accountForm {
    margin: 80px 0 0 0;
    overflow-x: visible;
    // @include m1024 {
    //     margin-top:130px
    // }

    .c-search {
        display: none;
    }

    .title-1 {
        @include m1024 {
            font-size: 31px;
            font-weight: 800;
        }
    }
    .c-form_avatar + .title-1 {
        margin-top: 0;
    }
    
    &_form {
        margin-bottom: 50px;
        @include m1024 {
            max-width: 74.68%;
            margin: 0 auto 60px;
        }
        h2 {
            position: relative;
            border-bottom: 1px solid $purple;
            margin: 30px 0 39px 0;
            padding-bottom: 3px;
            &:first-of-type {
                // margin-top: 0;
            }
            @include m1024 {
                margin-top: 50px;
                margin-bottom: 30px;
            }
        }
        p {
            margin: 0 20px 30px 20px;
            @include m1024 {
                margin: 0 0 30px 0;
            }
            &:not(.mentions) {
                @include m1023 {
                    margin-left: 40px;
                    margin-right: 10px;
                }
            }
        }
        .c-form_line {
            margin: 0 20px 10px 20px;
            @include m1024 {
                margin: 0 0 10px 0;
            }
        }
        button[type=submit] {
            display: block;
            margin: 0 auto 30px auto;
        }
    }

    &_delete-section {
        border-top: 1px solid $purple;
        margin-top: 60px;
        padding-top: 60px;
    }

    &_delete-btn {
        font-weight: 800;
        font-size: 20px;
        @extend .effetHoverScale;
    }

    .c-form_radio2, .c-form_radio3 {
        input[type="radio"] + label:before,
        input[type="radio"] + label:after {
            outline-offset: 4px;
            outline: 1px solid $purple;
        }
        input[type="radio"] + label {
            @include m1024 {
                margin-left: 5px;
            }
        }
    }
}