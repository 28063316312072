.c-visuelLarge {
    position: relative;
    width: 100%;
    z-index: 0;
    @include m1024 {
        max-width: 1110px;
        margin: 0 auto;
    }
    &.capsule-- {
        margin-bottom: 15px;
        @include m1024 {
            magin-bottom: 40px;
        }
    }
   
    &_visuelSup {
        @include flex (center, center);
        height: 100%;
        &.bottom-- {
            align-items: flex-end;
        }
        &.left-- {
            justify-content: flex-start;
        }
        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 150px;
            @include m1024 {
                max-height: 280px;
            }
        }
    }
    &_visuel {
        width: 100%;
        height: 235px;
        &--truesize, .c-sliderLarge &--truesize{
            height: 63.33333334vw;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        // background-color:$black;
        @include m1024 {
            height: 370px;
        }
        &.contain-- {
            background-size: contain;
        }
    }
    &_capsule {
        @include m1024 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &_content {
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        background-color: $white;
        padding: 25px 40px;
        margin-top: -50px;
        @extend .rounded;
        @include m1024 {
            margin-top: 40px;
            width: 48.96%;
            padding: 35px;
        }
    }
}