.c-communityBlockRounded {
    &_container {
        position: relative;
        margin: -55px auto 30px;
        padding: 25px 35px;
        background: $white;
        border-radius: 40px;
        z-index: 1;
        box-shadow: 0px 0px 32px 0px rgba(36, 36, 36, 0.15);
        // max-width: 795px;
        @include m1024 {
            padding: 50px 35px;
        }
        .title {
            @include black;
            font-size: 17px;
            text-transform: uppercase;
            text-align: left;
            @include m1024 {
                font-size: 23px;
            }
        }
        .text {
            @include regular;
            font-size: 14px;
            line-height: 15px;
            margin-top: 10px;
            @include m1024 {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
    &-search {
        margin-top: 24px;
        margin-bottom: 50px;
        @include m1024 {
            margin-bottom: 32px;
        }
        .c-search_form {
            width: calc(100% + 30px);
            margin-left: -15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include m1024 {
                margin-bottom: 30px;
                width: 100%;
                margin-left: 0;
                flex-direction: row;
            }
            .c-search_line {
                width: 100%;
                box-shadow: 0 0 16px 1.4px rgba(141, 141, 141, 0.2);
            }
            .btn-2 {
                margin-top: 20px;
                @include m1024 {
                    margin-top: 0px;
                    margin-left: 20px;
                }
            }
        }
        .text {
            line-height: 1.4;
            margin-top: 30px;
            margin-bottom: 30px;
            &.label {
                margin-bottom: 10px;
                font-weight: 800;
                font-size: 14px;
                line-height: 15px;
                @include m1024 {
                    font-size: 16px;
                    line-height: 25px;
                }
            }            
        }
    }
    &-btn {
        margin: 20px auto 0px;
        text-align: center;
        @include m1024 {
            margin: 25px auto 0px;
        }
    }
}