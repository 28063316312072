.c-filters {
    &.flex-- {
        margin-left: -15px;
        margin-right: -15px;
        @include flex(space-between, center);
        @include m991 {
            flex-direction: column;
        }
    }
    &Left {
        @include flex(flex-start, center);
        width:75%;
        @include m991 {
            flex-wrap: wrap;
            width:100%;
        }
        .c-filter {
            &_select {
                width:calc((100% - 20px) / 2);
                margin-right:10px;
                @include m768 {
                    width:200px;
                }
            }
        }
    }
    &Right {
        @include flex(flex-end, center);
        width: 25%;
        @include m991 {
            width:100%;
            margin-top:-30px;
        }
        .c-filter {
            &_select {
                width:100%;
                @include m991 {
                    width:calc(50% - 10px);
                    margin-right:10px;
                }
                @include m768 {
                    width:auto;
                }
            }
        }
    }
}