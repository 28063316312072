.t-register {
    margin: 80px 0 0 0;

    .c-search { display: none; }

    &_intro {
        padding: 30px;
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        background-color: $white;
        margin:0 auto 45px auto;
        @extend .rounded;
        @include m1024 {
            width:82.81%;
            margin-bottom:25px;
        }
        h1 {
            font-size: 18px;
            margin-bottom:8px;
            @include m1024 {
                font-size: 24px;
            }
        }
        p {
            font-size:16px;
            line-height: 1.41;
            @include m1024 {
                font-size:18px;
            }
        }
    }
    &_rs {
        margin: 0 35px 40px 35px;
        @include m1024 {
            margin:0 auto 80px auto;
            max-width:74.68%;
        }
    }
    &_form, &_formAvantage {
        @include m1024 {
            max-width:74.68%;
            margin:0 auto;
        }
        h2 {
            border-bottom:1px solid $purple;
            margin:30px 0 39px 0;
            &:first-of-type {
                margin-top:0;
            }
            @include m1024 {
                margin-bottom:30px;
            }
        }
        p {
            margin:0 20px 30px 20px;
            @include m1024 {
                margin:0 0 30px 0;
            }
            &:not(.mentions) {
                @include m1023 {
                    margin-left:40px;
                    margin-right:10px;
                }
            }
        }
        .mentions {
            font-size: 14px;
        }
        .c-form_line {
            margin:0 20px 10px 20px;
            @include m1024 {
                margin:0 0 10px 0;
            }
        }
        button[type=submit] {
            display: block;
            margin: 0 auto 30px auto;
        }
    }
    &_formAvantage {
        margin-bottom:30px;
        input {
            text-transform: uppercase;
        }
        input::placeholder {
            text-transform: none;
        }
        button[type=submit] {
            margin-top:20px;
            @include m1024 {
                margin: 4px 0 0 0;
            }
        }
        .loader {
            margin:0;
            padding:0;
            @include m1024 {
                justify-content: flex-start;
            }
            .lds-ellipsis {
                height: 40px;
                div {
                    top:25px;
                }
            }
        }
    }
}

.t-register-confirm {
    .c-breadcrumb, .c-search { display: none; }
}