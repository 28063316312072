.t-search {
    &_count {
        margin-bottom:20px;
        @include m1024 {
            margin-bottom:40px;
        }
        h1 {
            font-size:14px;
            @include m1024 {
                font-size: 18px;
                text-align: center;
            }
            strong {
                font-size:17px;
                @include extraBold;
                @include m1024 {
                    font-size: 23px;
                }
            }
        }
    }
    &_results {
        background: $white;
        padding:20px 0;
    }
    &_category {
        box-shadow: 0px 6px 16.9px 1.1px rgba($black, 0.15);
        overflow: hidden;
        margin-bottom:15px;
        @extend .rounded;
        @include m1024 {
            width:74.48%;
            margin: 0 auto 50px auto;
        }
        h2 {
            position: relative;
            background: $purple;
            color:$white;
            font-size:17px;
            line-height: 1.04;
            @include extraBold;
            padding:17px 20px 16px 56px;
            @include flex (center flex-start row nowrap);
            margin-bottom: 10px;
            @include m1024 {
                padding:22px 40px 24px 95px;
                font-size:30px;
                margin-bottom:0;
            }
            &:before {
                @extend .icomoon;
                position:absolute;
                z-index: 0;
            }
            &:after {
                @extend .icomoon;
                content:$icon-bgd-1;
                position:absolute;
                top:7px;
                left:11px;
                font-size:36px;
                color:$white;
                opacity:.25;
                z-index: 0;
                @include m1024 {
                    font-size:60px;
                    left:26px;
                }
            }
        }
        &.economy-- {
            h2 {
                background:$green;
                &:before {
                    content:$icon-economy;
                    top:15px;
                    left:17px;
                    font-size:17px;
                    @include m1024 {
                        font-size: 29px;
                        top:20px;
                        left:38px;
                    }
                }
            }
        }
        &.tips-- {
            h2 {
                background:$pink;
                &:before {
                    content:$icon-tips;
                    top:13px;
                    left:18px;
                    font-size:20px;
                    @include m1024 {
                        font-size: 33px;
                        top:17px;
                        left:39px;
                    }
                }
            }
        }
        &.landing-- {
            h2 {
                background: $blue;
                &:before {
                    content:$icon-brands;
                    top:15px;
                    left:17px;
                    font-size:17px;
                    @include m1024 {
                        font-size: 30px;
                        top:18px;
                        left:37px;
                    }
                }
            }
        }
        &.brands-- {
            h2 {
                background: $blue;
                &:before {
                    content:$icon-brands;
                    top:15px;
                    left:17px;
                    font-size:17px;
                    @include m1024 {
                        font-size: 30px;
                        top:18px;
                        left:37px;
                    }
                }
            }
        }
        &.community-- {
            h2 {
                background: $orange;
                &:before {
                    content:$icon-community;
                    top:14px;
                    left:16px;
                    font-size:16px;
                    @include m1024 {
                        font-size: 28px;
                        top:18px;
                        left:35px;
                    }
                }
            }
        }
    }
    &_container {
        padding:0 15px;
        border-bottom:1.5px solid rgba($purple, 0.24);
        @include m1024 {
            padding:0 60px;
        }
        &:last-of-type {
            border: none;
        }
    }
    &_content {
        padding:20px 0;
        @include m1024 {
            padding:50px 0;
        }

        h3 {
            font-size:17px;
            @include black;
            line-height: 1.09;
            text-transform: uppercase;
            margin-bottom: 10px;
            @include m1024 {
                font-size:24px;
            }
        }
        p {
            font-size:16px;
            line-height: 1.2;
            @include m1024 {
                font-size:18px;
            }
        }
    }
    &_link {
        font-size:14px;
        @include extraBold;
        text-decoration: underline;
        margin-top:17px;
        @include m1024 {
            font-size:18px;
        }
    }
    &_more {
        margin:15px auto 27px auto;
        @extend .btn-2;
        display: block;
        @include m1024 {
            margin:20px auto 40px auto;
        }
        &.hide-- {
            display: none;
        }
    }
}