.c-carouselProducts {
    position:relative;
    margin:0 -20px 30px -20px;
    @include m1024 {
        margin:0 -70px 100px 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: $grey-dark;
    }
    &_item {
        position: relative;
        @extend .rounded2;
        overflow: hidden;
        background: $white;
        box-shadow: -1.1px 5.9px 16.9px 1.1px rgba(141, 141, 141, 0.15);
        margin-bottom: 12px;
        @include m1024 {
            cursor: pointer;
            box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.15);
            margin-bottom: 20px;
        }
        
    }
    &_visuel {
        // width:calc(100% - 30px);
        height:170px;
        background-size: cover;
        background-position: center center;
        // margin:10px 15px 0 15px;
        margin: 10px auto 15px;
        @include m1024 {
            height:210px;
            // margin:10px 10px 0 10px;
            // width:calc(100% - 20px);
            margin: 10px auto 0;
        }
    }
    &_content {
        padding:10px 15px 15px 15px;
        
        h3 {
            @extend .title-3;
            @include extraBold;
            color:$blue;
        }
    }
    &_note {
        position: absolute;
        top:15px;
        right:15px;
        font-size:11px;
        @include extraBold;
        @include m1024 {
            top:20px;
            right:20px;
            font-size:21px;
        }
        i {
            font-size:12px;
            margin-left:3px;
            @include m1024 {
                font-size: 26px;
                margin-left:4px;
            }
        }
    }
    

    .swiper-container {
        width: 100%;
        height: 100%;
        position:relative;
        padding-bottom:25px;
    }
    .swiper-wrapper {
        @include flex(flex-start, stretch);
    }
  
    .swiper-slide {
        font-size: 18px;
        background: #fff;
        width: 87%;
        height:auto;
        @include m1024 {
            width: calc(50% - 45px);
        }
    }
    .swiper-slide:first-of-type {
        margin-left:20px;
        @include m1024 {
            margin-left:70px;
        }
    }
    .swiper-slide:last-of-type {
        margin-right:20px;
        @include m1024 {
            margin-right:70px;
        }
    }
    
    .swiper-pagination {
        bottom:0;
        width:100%;
        .swiper-pagination-bullet {
            width:7px;
            height: 7px;
            background: $blue;
            opacity:0.3;
            margin: 0 4px;
            @include m1024 {
                width:12px;
                height: 12px;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
    }

    // No carousel
    &.no-carousel {
        @include m1024 {
            margin-right: 0;
            margin-bottom: 70px;

            .swiper-slide {
                width: calc(50% - 12px);

                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}