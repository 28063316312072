.c-sliderLarge {
    margin-bottom:20px;
    @include m1024 {
        max-width: 1050px;
        margin:0 auto 33px auto;
    }
    &_show {
        overflow: hidden;
        height: auto;
    }
    &_hide {
        overflow: hidden;
        height: 0;
    }
    &_item {
        position: relative;
    }
    &_visuel {
        width:100%;
        height:235px;
        background-color:$black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
        @include m1024 {
            height: 370px;
        }
    }
    &_container {
        margin-bottom: 35px;
        @include m1024 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
        }
    }
    &_content {
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        background-color: $white;
        padding:25px 40px;
        margin-top:-50px;
        @extend .rounded;
        @include m1024 {
            margin-top: 40px;
            width: 48.96%;
            padding:35px;
        }
        h2 {
            font-size: 17px;
            @include black;
            line-height: 1.28;
            text-transform: uppercase;
            margin-bottom: 17px;
            @include m1024 {
                font-size:23px;
                margin-bottom: 14px;
            }
        }
        p {
            font-size: 13px;
            line-height: 1.22;
            margin-bottom: 17px;
            @include m1024 {
                font-size:18px;
                margin-bottom: 14px;
            }
        }
    }
    &_button {
        @extend .btn-2;
        &.economy--, &.green-- {
            background:$green;
        }
        &.brands--, &.blue-- {
            background:$blue;
        }
        &.community--, &.peach-- {
            background:$orange;
        }
        &.tips--, &.pink-- {
            background:$pink;
        }

        &-wrapper {
            margin-top: 15px;
        }
    }

    .swiper-button-prev {
        top:31.66666666vw;
        color:$white;
        @extend .icomoon;
        @extend .icon-arrow;
        @extend .effetHoverScale;
        font-size:44px;
        left:18px;
        @include m1024 {
            top:50%;
        }
        @include m1100 {
            left:calc((100% - 1100px)/2 + 50px);
        }
        &:before {
            transform:rotate(180deg);
        }
        &:after {
            display: none;
        }
    }
    .swiper-button-next {
        top:31.66666666vw;
        color:$white;
        @extend .icomoon;
        @extend .icon-arrow;
        font-size:44px;
        right:18px;
        @extend .effetHoverScale;
        @include m1024 {
            top:50%;
        }
        @include m1100 {
            right:calc((100% - 1100px)/2 + 50px);
        }
        &:after {
            display: none;
        }
    }
    .swiper-pagination {
        bottom:initial!important;
        top: calc(63.333333333333vw - 80px);
        width:100%;
        @include m1024 {
            top:initial;
            bottom:20px!important;
        }
    }
    .swiper-pagination-bullet {
        background: $white;
        opacity:.28;
        width: 12px;
        height: 12px;
        margin: 0 5px;
        &:only-child {
            display: none;
        }
    }
    .swiper-pagination-bullet-active {
        opacity:1;
    }
    .swiper-button-disabled {
        display: none;
    }
}