.c-communityHpCard {
    max-width: 100%;
    height: 100%;
    background-color: #fed4be;
    border-radius: 40px;
    box-shadow: 0px 7px 12px 1.5px rgba(140, 140, 140, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include m1024 {
        transition: transform 0.2s ease;
        &:hover {
            transform: scale(1.03);
            transition: transform 0.2s ease;
        }
    }
    &-content {
        padding: 20px 20px 10px;
        @include m1024 {
            padding: 30px 30px 20px;
        }
    }
    &-title {
        font-size: 18px;
        margin-bottom: 15px;
        @include extraBold;
        @include m1024 {
            margin-bottom: 20px;
            font-size: 30px;
        }
    }
    &-text {
        @include extraBold;
        font-size: 14px;
        @include m1024 {
            font-size: 18px;
        }
    }
    &-img {
        width: 100%;
        margin: auto;
        margin-bottom: 0;
    }
}