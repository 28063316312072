.c-communityPostCard {
    border-radius: 35px;
    overflow: hidden;
    box-shadow: 0px 6px 17px 1px rgba(140, 140, 140, 0.15);
    max-width: 1100px;
    margin: 0px auto 20px;
    @include m1024 {
        margin: 0px auto 30px;
    }
    
    &.astuce-- {
        .c-communityPostCard-header {
            background-color: $purple-light;
            &-title {
                color: $white;
            }
            &-icon i{
                &.icono{
                    @extend .icon-tips;
                    font-size: 22px;
                    @include m1024 {
                        font-size: 32px;
                    }
                }
                &.icono-bgd {
                    color: $purple;
                }
            }
        }
    }
    &.question-- {
        .c-communityPostCard-header {
            background-color: $orange-light;
            &-title {
                color: $purple;
            }
            &-icon i{
                &.icono{
                    @extend .icon-questions;
                }
                &.icono-bgd {
                    color: $orange;
                }
            }
        }
    }
    &-header {
        display: flex;
        align-items: center;
        padding: 12px 15px;
        @include m1024 {
            padding: 10px 17px;
        }
        &-title {
            @include black;
            font-size: 14px;
            width: 100%;
            text-align: center;
            padding: 0px 17px;
            @include m1024 {
                font-size: 18px;
                text-align: left;
            }
        }
        &-icon {
            position: relative;
            width: 35px;
            height: 35px;
            @include m1024 {
                width: 60px;
                height: 60px;
            }
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @extend .icomoon;
                &.icono {
                    font-size: 18px;
                    color: $white;
                    z-index: 2;
                    @include m1024 {
                        font-size: 25px;
                    }
                }
                &.icono-bgd {
                    @extend .icon-bgd-2;
                    font-size: 35px;
                    z-index: 1;
                    @include m1024 {
                        font-size: 60px;
                    }
                }
            }
        }
    }
    &-content {
        background-color: $white;
        padding: 23px;
        display: flex;
        align-items: flex-start;
        @include m1024 {
            padding: 25px 30px;
        }
        &-profilePic {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;
            background-color: $grey;
            @include m1024 {
                width: 77px;
                height: 77px;
                min-width: 77px;
                min-height: 77px;
                margin-right: 20px;
            }
            img {
                position: absolute;
                object-fit: cover;
                height: 100%;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &-infos {
            margin-top: 5px;
            width: 100%;
            @include m1024 {
                margin-top: 18px;
            }
            .info-name {
                @include black;
                font-size: 16px;
                line-height: 18px;
                @include m1024 {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            .info-text {
                @include semiBold;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 20px;
                @include m1024 {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 25px;
                }
                .tags-- {
                    @include extraBold;
                    line-height: 1.8;
                    display: inline-block;
                    margin-right: 8px;
                }
                strong {
                    @include extraBold;
                }
            }
            .info-thematics-list {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                li {
                    background-color: $grey;
                    padding: 8px 16px;
                    @include black;
                    font-size: 10px;
                    text-transform: uppercase;
                    border-radius: 25px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    @include m1024 {
                        font-size: 12px;
                        padding: 10px 20px;
                        margin-right: 15px;
                    }
                }
            }
            .info-uploads-list {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 20px auto 50px;
                flex-wrap: wrap;
                
                @include m1024 {
                    margin: 20px auto 60px;
                }
                &-item {
                    margin-right: 3%;
                    width: 30%;
                    position: relative;
                    display: flex;
                    margin-bottom: 10px;
                    img, video {
                        width: 100%;
                        height: auto;
                    }
                    &.clickable {
                        cursor: pointer;
                        @include m1024 {
                            transition: transform 0.2s ease;
                            &:hover {
                                transform: scale(1.05);
                                transition: transform 0.2s ease;
                            }
                        }
                    }
                    .video-btn {
                        position: absolute;
                        background-color: rgba($black, 0.5);
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                        &:hover {
                            &:after {
                                width: 55px;
                                height: 55px;
                                transition: width 0.2s ease, height 0.2s ease;
                            }
                        }
                        &:before {
                            content: "";
                            display : inline-block;
                            height : 0;
                            width : 0;
                            border-top : 5px solid transparent;
                            border-bottom : 6px solid transparent;
                            border-left : 9px solid $white;
                            position: absolute;
                            top: 50%;
                            left: calc(50% + 1px);
                            transform: translate(-50%, -50%);
                            @include m1024 {
                                border-top : 9px solid transparent;
                                border-bottom : 10px solid transparent;
                                border-left : 15px solid $white;
                                left: calc(50% + 2px);
                            }
                        } 
                        &:after {
                            content: "";
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            border: 2px solid $white;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            @include m1024 {
                                width: 50px;
                                height: 50px;
                                transition: width 0.2s ease, height 0.2s ease;
                                &:hover {
                                    width: 55px;
                                    height: 55px;
                                    transition: width 0.2s ease, height 0.2s ease;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;
            @include m1024 {
                justify-content: flex-start;
                margin-bottom: 0px;
            }
            .redirection {
                width: 48%;
                font-size: 14px;
                line-height: 1;
                @include extraBold;
                @include m1024 {
                    font-size: 18px;
                    width: auto;
                    margin-right: 40px;
                }
                &.underline-- {
                    text-decoration: underline;
                }
                i {
                    margin-right: 5px;
                    font-weight: 600;
                    @include m1024 {
                        margin-right: 10px;
                    }
                }
                
            }
        }
        &-likes {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            @include extraBold;
            line-height: 1;
            @include m1024 {
                font-size: 18px;
            }
            i {
                font-size: 20px;
                margin-left: 5px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                &.active {
                    font-weight: 900;
                }
                @include m1024 {
                    font-size: 25px;
                    transition: font-size 0.2s ease;
                    &:hover {
                        font-size: 28px;
                        transition: font-size 0.2s ease;
                    }
                }
            }
        }
    }
}