.c-notification {
    position:relative;
    @extend .effetHoverScale;
    .icon-notif {
        font-size:26px;
        @include m1024 {
            font-size:31px;
        }
    }
    &_number {
        position: absolute;
        top:-4px;
        right:-9px;
        background:$purple;
        color: $white;
        font-size:8px;
        border-radius: 100%;
        width:17px;
        height:17px;
        @include flex(center, center);
        @include m1024 {
            width:18px;
            height: 18px;
            font-size: 10px;
        }
    }
}