.c-switchTab {
    margin-bottom: 25px;
    background-color: $white;
    @include m1024 {
        margin-top: 30px;
        margin-bottom: 46px;
    }
    .container {
        @include m1023 {
            padding: 0;
        }
    }
    #anchor-offer {
        display: block;
        position: relative;
        top: -70px;
        visibility: hidden;
        @include m1024 {
            top: -75px;
        }
    }
    &__list {
        @include flex(center, initial);
        &-item {
            overflow: hidden;
            position: relative;
            flex: 1 1 0;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                display: block;
                width: 100%;
                height: 5px;
                background-color: $green;
                transform: translateY(100%);
                transition: transform .35s ease-in-out;
                @include m1024 {
                    height: 7px;
                }
            }
            &:hover {
                @include m1024 {
                    &:after {
                        transform: translateY(0);
                    }
                }
            }
            &[data-active="true"] {
                &:after {
                    background-color: $purple;
                    transform: translateY(0);
                }
                > * {
                    color: $purple;
                }
                .c-switchTab__list-itemNumber:before {
                    background-color: $purple;
                }

            }
            > * {
                position: relative;
                box-sizing: content-box;
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding: 24px;
                font-size: 16px;
                @include extraBold;
                line-height: 1.14;
                text-align: center;
                text-transform: uppercase;
                color: $green;
                @include m1024 {
                    max-width: 160px;
                    padding: 34px;
                    font-size: 22px;
                }
            }
            &Number {
                z-index: 1;
                position: absolute;
                top: 20px;
                right: 18px;
                font-size: 13px;
                @include extraBold;
                color: $white;
                text-align: center;
                line-height: 22px;
                @include m1024 {
                    top: 30px;
                    right: 28px;
                    font-size: 18px;
                    line-height: 30px;
                }
                &:before {
                    z-index: -1;
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                    background-color: $green;
                    transition: transform .3s ease-in-out;
                    @include m1024 {
                        width: 27px;
                        height: 27px;
                    }
                }
            }
        }
    }
}