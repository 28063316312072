.c-login {
    text-align: center;
    padding:60px 35px 45px 35px;
    @include m1024 {
        padding: 56px 160px 89px 160px;
    }
    p {
        margin-bottom:20px;
        @include m1024 {
            font-size:18px;
        }
    }

    .forgotPwd {
        font-size: 14px;
        line-height: 1.59;
        text-align: right;
        margin-top:-5px;
        margin-bottom:5px;
        @include m1024 {
            padding-right:25px;
            margin-bottom:10px;
        }

        .c-modal-link {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .c-form_tooltip .icon-eye-no {
        top: -1px;
    }
    h2 {
        margin-bottom:20px;
    }
    &_form {
        margin-bottom:20px;
        @include m1024 {
            @include flex(space-between,flex-start, row, nowrap);
            margin-bottom:40px;
        }
        &>div {
            @include m1024 {
                position: relative;
                width:calc(50% - 10px);
                text-align: right;
            }
        }
        input[type=email] {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        button[type=submit] {
            @include m1024 {
                width:calc(50% - 10px);
                margin-top:45px;
                margin-bottom:0;
                min-height: 58px;
            }
        }
    }
    &_inscription {
        padding-top:20px;
        border-top: 1px solid $purple;
        margin-top: 20px;
        @include m1024 {
            padding-top:22px;
            @include flex(space-between, center, row, nowrap);
        }
    }
    &_globalError{
        &.error-- {
            margin-bottom:5px;
            text-align:center;
            padding:0;
            @include m1024 {
                text-align: left;
                padding: 0 0 0 15px;
            }
        }
    }

    // Loader
    .loader {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #dcdbdb73;
        margin: 0;
        border-radius: 40px;
        z-index: 5;
    }
}