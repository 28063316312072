.c-communityNotifications {
    .title-2{
        margin-bottom: 20px;
        font-size: 18px;
        text-transform: unset;
        @include m1024 {
            font-size: 30px;
        }
    }
    &__container {
        background-color: $white;
        box-shadow: 0 0 16px 1.5px rgba(140, 140, 140, 0.1);
        margin-bottom: 50px;
        @include m1024 {
            margin-bottom: 30px;
        }
    }
    &__list {
        display: inline-block;
        @include m1024 {
            max-width: 800px;
            display: block;
        }
        &-item {
            padding-bottom: 20px;
            padding-top: 20px;
            position: relative;
            font-size: 14px;
            line-height: 1.2;
            @include m1024 {
                font-size: 16px;
                padding-bottom: 40px;
                padding-top: 40px;
            }
            @include semiBold;
            &:not(:last-child) {
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    height: 2px;
                    bottom: 0;
                    width: calc(100% + 40px);
                    left: -20px;
                    background: rgba($purple, 0.25);
                    @include m1024 {
                        width: 100%;
                        left: 0px;
                    }
                } 
            }
        }
    }
    &__notif {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &-visuel {
            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: $orange;
            &.tip {
                background: $purple;
                .icono {
                    @extend .icon-tips;
                    font-size: 18px;
                    @include m1024 {
                        font-size: 22px;
                    }
                }
            }
            @include m1024 {
                width: 45px;
                height: 45px;
            }
            i {
                @extend .icomoon;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                &.icono {
                    font-size: 15px;
                    color: $white;
                    z-index: 2;
                    @extend .icon-questions;
                    @include m1024 {
                        font-size: 18px;
                    }
                }
            }
        }
        &-description {
            width: calc(100% - 55px);
            @include m1024 {
                width: calc(100% - 70px);
            }
            i {
                &.icon-up {
                    font-weight: bold;
                }
            }
        }
    }
}