.c-breadcrumb {
    padding: 20px 0 10px 0;
    @include m1024 {
        padding: 15px 0 25px 0;
    }
    ol {
        @include m1024 {
            margin-left: 55px;
        }
        li {
            margin-top: 4px;
            display: inline;
            &:before {
                font-size: 9px;
                @extend .icomoon;
                @extend .icon-arrow-rounded;
                margin: 0 5px;
                @include m1024 {
                    font-size: 13px;
                    margin: 0 8px;
                }
            }
            &:first-of-type {
                margin-top: 0;
                &:before {
                    display: none;
                }
                a, > span {
                    &:before {
                        @extend .icomoon;
                        @extend .icon-home;
                        font-size: 17px;
                        @include m1024 {
                            font-size: 25px;
                        }
                    }
                    span {
                        display: none;
                    }
                }
            }
            a, > span {
                font-size: 11px;
                @include extraBold;
                @include m1024 {
                    font-size: 15px;
                }
            }
        }
    }
}