.t-landingPage {
    background: $white;
    overflow: initial;

    &_container {
        position: relative;
        background: $white;
        margin: 0 -20px;
        padding: 0 20px;
        z-index: 1;

        @include m1024 {
            margin: 0 auto;
            width: 82.82%;
            padding: 0 40px;
        }

        &.rounded-- {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: -15px -20px 0 -20px;

            @include m1024 {
                margin: -30px auto 0 auto;
            }
        }
    }

    &_grey {
        position: relative;
        background: $grey-light;
        margin-top: -20px;
        z-index: 0;

        @include m1024 {
            margin-top: 20px;
        }
    }

    &_articles {
        padding-top: 60px;

        @include m1024 {
            padding-top: 70px;
        }
    }

    &_contact {
        @include m1024 {
            max-width: 705px;
            margin: 0 auto;
            @include flex(flex-start, flex-start, row-reverse, nowrap)
        }

        .c-nl {
            @include m1024 {
                max-width: 220px;
                flex-shrink: 0;
                margin-right: 50px;
            }
        }
    }

    &_iframe {
        div{
            width: 100%;
            left: 50%;
            position: relative;
            transform: translateX(-50%);        
            height: 1100px;
            overflow-x: hidden;
            max-width: calc(100vw - 30px);
        }

        &__content{
            div{
                width: 100%;
            }
        }

        &__full{
            div{
                width: calc(100vw - 30px);
            }
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}