.c-contactTwitter {
    position: relative;
    width:100%;
    background: $white;
    @extend .rounded;
    padding:40px 25px 25px 25px;
    margin-bottom:25px;
    @include m1024 {
        padding:45px 40px 40px 40px;
    }
    &:before {
        position: absolute;
        top:20px;
        right:25px;
        @extend .icomoon;
        @extend .icon-twitter;
        font-size:17px;
        @include m1024 {
            font-size: 26px;
        }
    }
    &_top {
        position: relative;
        @include flex(flex-start, flex-start, row, nowrap)
    }
    &_picture {
		width: 60px;
		text-align: center;
        flex-shrink: 0;
        padding-right: 15px;
		@include m1024 {
			width: 70px;
		}

		img {
			display: inline-block;
			border-radius: 50%;
			max-width: 100%;
			height: auto;
			@include m1023 {
				width: 40px;
			}
		}

		.options-- & {
			@include m1024 {
				width: 70px;
				img { width: 50px; }
			}
		}
	}

	&_title {
		font-size: 14px;
		@include black;
		@include m1024 {
			font-size: 20px;
		}
	}
	&_subtitle {
		font-size: 11px;
		@include light;
		@include m1024 {
			font-size: 18px;
		}
	}

	&_text {
		margin-top: 10px;
		font-size: 14px;
		@include semiBold;
		@include m1024 {
			font-size: 19px;
		}

		&:not(:last-child) {
			margin-bottom: 25px;
		}
	}
    &_visuel {
        margin-top:25px;
        width:100%;
        @include m1024 {
            margin-top:20px;
        }
    }
    &_date {
        margin-top:10px;
		font-size: 11px;
		@include light;
        @include m1024 {
            font-size: 18px;
        }
    }
}