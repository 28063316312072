.c-carouselCashback {
    position: relative;
    margin: 20px -20px 30px -20px;
    overflow: hidden;
    @include m1024 {
        margin: 30px -70px 80px 0;
    }
    &.noPadding-- {
        margin-top:0;
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: $grey-dark;
    }
    &__title {
        margin-bottom: 15px;
        text-transform: none;
        font-size: 17px;
        @include black;
        @include m1024 {
            width: 76.6%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            font-size: 26px;
        }
    }
    &__item {
        margin-bottom: 12px;
        @include m1024 {
            margin-bottom: 20px;
        }
    }
    .c-product {
        &Inner {
            box-shadow: -1.1px 5.9px 16.9px 1.1px rgba(141, 141, 141, 0.13);
        }
        &__img{
            min-width: 70px;
        }
        &__desc {
            margin-left: 4px;
            margin-right: 4px;  

            h3 {
                font-size: 14px;
                @include m1024 {
                    font-size: 16px;
                }
            }
            p {
                font-size: 13px;
                @include m1024 {
                    font-size: 16px;
                }
            }
        }
        &__content {
            padding: 10px;
            @include m1024 {
                padding: 15px;
            }
        }
        &__actions {
            padding: 5px 6px;
            width: 38%;
            @include m1024 {
                padding: 5px 10px;
                width: 40.2%;
            }
        }
        &__actions-type:before {
            width: 20px;
            height: 20px;
            min-width: 20px;
            font-size: 16px;
            @include m1024 {
                width: 25px;
                height: 25px;
                min-width: 25px;
                font-size: 20px;
            }
        }
    }

    &_button {
        text-align: center;
        button, a {
            @extend .btn-2;
            background: $green;
        }
    }
    .c-wysiwyg {
        margin: 0 -20px;
        padding: 0 20px;
        z-index: 1;
        @include m1024 {
            margin: 0 auto;
            width: 82.82%;
            padding: 0 40px;
        }
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        position: relative;
        padding-bottom: 35px;
        margin-bottom: 30px;
        overflow: visible;
    }
    .swiper-wrapper {
        @include flex(flex-start, stretch);
    }

    .swiper-slide {
        width: 95%;
        height: auto;
        @include m1024 {
            //width: calc((100% - 140px - 20px) / 2);
            width: calc((100% - 90px) / 2);
            //width: calc(87% - 45px);
            //width: calc(58% - 45px);
        }
    }
    // .swiper-slide:first-of-type {
    //     margin-left: 20px;
    //     @include m1024 {
    //         margin-left: 70px;
    //     }
    // }
    // .swiper-slide:last-of-type {
    //     margin-right: 20px;
    //     @include m1024 {
    //         margin-right: 70px;
    //     }
    // }

    .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            background: $green;
            opacity: 0.3;
            @include m1024 {
                width: 12px;
                height: 12px;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}