.t-accountMyInfo {
    .c-search { display: none; }

    &_wrapper { 
        margin: 30px 0 50px;
        @include m1024 {
            margin: 50px 0 80px;
        }
    }

    &_container {
    	width: 100%;
    	max-width: 700px;
    	margin: 70px auto;
        @include m1023 {
            margin-top: 50px;
        }
    }

    &_section {
    	margin-bottom: 30px;
    }

    .title-2 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .title-3 {
    	font-size: 15px;
        text-transform: uppercase;
        @include black;
        margin-top: 30px;
    	margin-bottom: 20px;
    }

    &_info {
    	font-size: 16px;
    	line-height: 1.5;

    	strong {
    		@include black;
    	}
    }
}