.mosaic {
    &_banner {
        margin-bottom: 40px;
    }

    &_grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 40px;
        @include m767 {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    &_grid-item {
        width: 100%;
        max-width: 33.333333%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        //cursor: pointer;
        @include m767 {
            max-width: 50%;
            padding-left: 8px;
            padding-right: 8px;
        }

        &.rect-- {
            max-width: 66.666666%;
            @include m767 {
                max-width: 100%;
            }
        }

        img {
            transition: transform 0.3s ease;
        }

        button, img {
            display: block;
            width: 100%;
        }

        &:hover img {
            transform: scale(1.05);
        }
    }

    &_detail-block {
        background-color: $white;
        border-radius: 30px;
        margin-bottom: 30px;
        position: relative;
    }

    &_detail-header {
        padding: 20px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &_detail-header-bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        opacity: 0.4;
    }

    &_detail-nb {
        border-radius: 50%;
        color: $white;
        font-size: 24px;
        line-height: 40px;
        font-weight: 800;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
    }

    &_detail-title {
        font-size: 18px;
        font-weight: 900;
        line-height: 1.2;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        max-width: calc(100% - 60px);
    }

    &_detail-bodyContent {
        position:relative;
        &.minheight-- {
            min-height: 300px;
        }
    }
    &_detail-body {
        padding: 30px;
        position: relative;
        z-index: 1;
        @include m768 {
            max-width: calc(100% - 230px);
        }

        p {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    &_detail-img {
        @include m768 {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @include m767 {
            // display: none;
            margin-left: auto;
        }
    }

    &_video {
        display: block;
        position: relative;
        z-index: 1;
        margin: 0 auto;
        max-width: 620px;
        padding-bottom: 50px;
        @include m767 {
            .c-video {
                margin-bottom: 0;
            }
        }
    }

    &_video + &_detail-img {
        @include m768 {
            position: relative;
            margin-left: auto;
            margin-top: -60px;
        }
    }
}