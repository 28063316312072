.c-listProducts {
    .c-product {
        margin: 7px 0;
        width: 100%;
        @include m1024 {
            margin: 12px 10px;
            width: calc(100% / 2 - 20px);
        }
    }
    &__title {
        margin-bottom: 3px;
        @include extraBold;
        font-size: 17px;
        text-transform: uppercase;
        @include m1024 {
            text-transform: none;
            font-size: 30px;
        }
    }
    &__list {
        @include flex(flex-start, initial, row, wrap);
        margin-left: -9px;
        margin-right: -9px;
        margin-bottom: 26px;
        @include m1024 {
            margin-left: -6px;
            margin-right: -6px;
            margin-bottom: 35px;
        }
    }
    &__buttonCtn {
        text-align: center;
        margin-bottom: 26px;

        @include m1024 {
            margin-bottom: 35px;
        }
    }
    &__noresults {
        margin: 40px 0 60px 0;
        text-align: center;
        @include m1024 {
            margin: 80px 0 120px 0;
        }
    }
    .swiper-container {
        padding-bottom: 35px;
        overflow: visible;
    }
    .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            background: $green;
            opacity: 0.3;
            @include m1024 {
                width: 12px;
                height: 12px;
            }
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}