@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap');

//300 Light
//400 Regular
//600 Semi bold
//700 Bold
//800 Extra Bold
//900 Black

$fontPath: '../../assets/fonts/';

@font-face {
    font-family: 'icomoon';
    src: url('#{$fontPath}/icomoon.eot?68sy00');
    src: url('#{$fontPath}/icomoon.eot?68sy00#iefix') format('embedded-opentype'),
    url('#{$fontPath}/icomoon.ttf?68sy00') format('truetype'),
    url('#{$fontPath}/icomoon.woff?68sy00') format('woff'),
    url('#{$fontPath}/icomoon.svg?68sy00#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"], .icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-coming-soon:before {
  content: "\e937";
}
.icon-share-email:before {
  content: "\e935";
}
.icon-share-fbk:before {
  content: "\e936";
}
.icon-twitter:before {
  content: "\e934";
}
.icon-check-2:before {
  content: "\e933";
}
.icon-bgd-1:before {
  content: "\e903";
}
.icon-bgd-2:before {
  content: "\e90f";
}
.icon-burger-mobile:before {
  content: "\e910";
}
.icon-burger-desktop:before {
  content: "\e911";
}
.icon-brands:before {
  content: "\e912";
}
.icon-economy:before {
  content: "\e913";
}
.icon-community:before {
  content: "\e914";
}
.icon-youtube:before {
  content: "\e915";
}
.icon-facebook-rounded:before {
  content: "\e916";
}
.icon-download:before {
  content: "\e917";
}
.icon-pin:before {
  content: "\e918";
}
.icon-facebook:before {
  content: "\e919";
}
.icon-money:before {
  content: "\e91a";
}
.icon-arrow-rounded:before {
  content: "\e91b";
}
.icon-cashback:before {
  content: "\e91c";
}
.icon-tips:before {
  content: "\e91d";
}
.icon-eye:before {
  content: "\e91e";
}
.icon-star:before {
  content: "\e91f";
}
.icon-talk:before {
  content: "\e920";
}
.icon-recycle:before {
  content: "\e921";
}
.icon-down:before {
  content: "\e922";
}
.icon-photo:before {
  content: "\e923";
}
.icon-questions:before {
  content: "\e924";
}
.icon-eye-no:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e926";
}
.icon-notif:before {
  content: "\e927";
}
.icon-favoris:before {
  content: "\e928";
}
.icon-share:before {
  content: "\e929";
}
.icon-what:before {
  content: "\e92a";
}
.icon-info-2:before {
  content: "\e92b";
}
.icon-player:before {
  content: "\e92c";
}
.icon-what-2:before {
  content: "\e92d";
}
.icon-comment:before {
  content: "\e92e";
}
.icon-up:before {
  content: "\e92f";
}
.icon-favoris-on:before {
  content: "\e930";
}
.icon-modify:before {
  content: "\e931";
}
.icon-delete:before {
  content: "\e932";
}
.icon-caddie:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e902";
}
.icon-hand:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-print:before {
  content: "\e906";
}
.icon-apple:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-basket:before {
  content: "\e909";
}
.icon-profil-on:before {
  content: "\e90a";
}
.icon-profil:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e90d";
}
.icon-time:before {
  content: "\e90e";
}
.icon-what-2-on:before {
  content: "\e938";
}
.icon-basket-on:before {
  content: "\e939";
}