.t-contentPage {
    &_container {
        position: relative;
        z-index: 1;
        @extend .rounded2;
        box-shadow: 0 0 32px 0 rgba(36, 36, 36, 0.15);
        margin:-20px -20px 0px -20px;
        z-index:1;
        background: $white;
        padding:20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        @include m1024 {
            background:$white;
            margin: -40px auto 0px auto;
            padding: 20px 40px 40px 40px;
            width: 82.82%;
        }
    }
}