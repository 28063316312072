.c-logout {
    &.on-top--,
    .on-top-- & {
        transform: translateY(calc(-100% - 25px));
        pointer-events: none;
        margin-bottom: -25px;
        @include m1023 {
            //display: none;
            transform: translateY(calc(-100% - 8px));
        }

        .c-logout_button {
            pointer-events: auto;
        }
    }

    &_button {
        margin-left: auto;
        @include flex(flex-start, center);
        color: $purple;
        font-size: 12px;
        @include extraBold;
        @include m1024 {
            font-size: 16px;
        }

        .icon {
            font-size: 20px;
            margin-right: 5px;
            @include m1024 {
                font-size: 23px;
            }
        }
    }

    .container {
        text-align: right;
    }
}