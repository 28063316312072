.t-article {
    background: $white;
    &_container {
        position: relative;
        margin: 0 -20px;
        padding: 0 20px;
        z-index: 1;
        @include m1024 {
            margin: 0 auto;
            width: 82.82%;
            padding: 0 40px;
        }
        &.rounded-- {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: -15px -20px 0 -20px;
            @include m1024 {
                margin: -30px auto 0 auto;
            }
        }
        &:not(.grey--) {
            background: $white;
        }
    }
    &_contentMore {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        // display: none;
        transition: all .3s ease-out;
        &.show-- {
            max-height: 99999px;
            opacity: 1;
            // display: block;
        }
    }
    &_more {
        margin: 25px auto 25px auto;
        @extend .btn-2;
        display: block;
        @include m1024 {
            margin: 25px auto 40px auto;
        }
        &.hide-- {
            display: none;
        }
    }
}