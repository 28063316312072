.c-listBrands {
    @include flex(flex-start, space-between, row, wrap);
    @include m1024 {
        margin-bottom:20px;
    }
    &_item {
        width:calc((100% - 20px) / 3);
        margin-bottom:10px;
        @extend .rounded2;
        box-shadow: 0 0 16.6px 1.4px rgba(141, 141, 141, 0.16);
        background: $white;
        overflow: hidden;
        margin-right:10px;
        @include m1024 {
            width:calc((100% - 68px) / 5);
            margin-bottom:20px;
            margin-right:17px;
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        @include m1023 {
            &:nth-child(3n) {
                margin-right:0;
            }
        }
    }
    &_visuel {
        width:100%;
        height: 100%;
        padding:15px;
    }
}