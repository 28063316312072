.c-subuniverse {
    margin: 0 -70px 40px 0;
    position: relative;
    @include m1023 {
        margin-right: -20px;
    }

    .swiped {
        .swiper-slide:first-of-type {
            @include m1024 {
                margin-left: 70px;
            }
        }

        .swiper-slide.swiper-slide-visible.swiper-slide-duplicate-prev {
            opacity: 0.5;
        }
    }

    .c-subuniverse &_slider {
        padding-bottom: 15px;

        &.swiped::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 15px;
            right: 0;
            width: 60px;
            z-index: 1;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(248,247,247,0) 0%, rgba(248,247,247,0.5) 50%, rgba(241,238,236,0.95) 100%);
            // border-top-left-radius: 35px;
            // border-bottom-left-radius: 35px;
            // @include m1023 {
            //     width: 12%;
            // }
        }
    }
    &_item {
        background-color: $white;
        @extend .rounded2;
        box-shadow: -2.1px 5.6px 9px 1px rgba(71, 72, 72, 0.05);;
        padding: 30px;
        text-align: center;
        max-width: 170px;
        height: auto !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $purple;
        @include m1023 {
            padding: 20px;
            // max-width: 140px;
            max-width: calc(20% - 23px);
        }
        @include m767 {
            width: 140px !important;
            max-width: 40%;
        }
        &.active-- {
            background-color: $purple;
            color:$white;
        }
        &.disabled-- {
            pointer-events: none;
            opacity: .4;
        }
    }
    &_title {
        text-align: center;
        font-size: 16px;
        @include extraBold;
        @include m1024 {
            font-size: 20px;
        }
    }

    .swiper-button-next {
        @include m1023 {
            display: none;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: #5f205d;
        font-size: 44px;
    }
}