.c-communityThematicsList {
    margin-bottom: 50px;
    margin-top: 45px;
    @include m1024 {
        margin-bottom: 60px;
        margin-top: 100px;
    }
    &-title {
        @include extraBold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        @include m1024 {
            font-size: 30px;
            line-height: 35px;
        }
    }
    &_container {
        background-color: $white;
        box-shadow: 0 0 17px 1.5px rgba(140, 140, 140, 0.1);
        .thematicsList {
            display: flex;
            flex-direction: column;
            &.reverse {
                flex-direction: column-reverse;
                .thematicsList-item {
                    &:first-child {
                        border-bottom: none;
                    }
                    &:last-child {
                        border-bottom: 2px solid rgba($purple, 0.3);
                    }
                }
            }
            &-item {
                padding: 25px 20px;
                margin: 0px -20px;
                border-bottom: 2px solid rgba($purple, 0.3);
                @include m1024 {
                    padding: 20px 0px;
                    margin: 0;
                }
                &:last-child {
                    border-bottom: none;
                }
                a {
                    display: flex;
                    align-items: center;
                }
                &-icon {
                    position: relative;
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 50%;
                    background-color: $orange;
                    margin-right: 15px;
                    @include m1024 {
                        width: 60px;
                        height: 60px;
                        min-width: 60px;
                        min-height: 60px;
                        margin-right: 20px;
                    }
                    &.question-- {
                        background-color: $orange;
                        i.icono {
                            font-size: 15px;
                            @extend .icon-questions;
                            @include m1024 {
                                font-size: 23px;
                            }
                        }
                    }
                    &.astuce-- {
                        background-color: $purple;
                        i.icono {
                            font-size: 22px;
                            @extend .icon-tips;
                            @include m1024 {
                                font-size: 32px;
                            }
                        }
                    }
                    i.icono {
                        position: absolute;
                        color: $white;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        @extend .icomoon;
                        @extend .icon-questions;
                    }
                }
                &-text {
                    @include black;
                    font-size: 16px;
                    @include m1024 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}