.c-visuelText {
    &_title {
        @extend .title-2;
        margin-bottom:20px;
        @include m1024 {
            margin-bottom: 5px;
        }
    }
    &_visuel {
        width:auto;
        max-width:100%;
        height:auto;
        text-align: center;
        img {
            margin:0 auto 15px auto;
        }
        @include m1024 {
            width:32.37%;
            padding-top:20px;
        }
    }
    &_content {
        padding-bottom: 25px;
        @include m1024 {
            @include flex(space-between, flex-start, row, nowrap);
        }
        .c-wysiwyg {
            @include m1024 {
                width:64.31%;
            }
        }
    }
}