.c-visuelLegend {
    position: relative;
    width:100%;
    z-index: 0;
    margin-bottom:70px;
    @include m1024 {
        max-width: 715px;
        margin:40px auto 60px auto;
    }
    @include m1023{
        .container > *:nth-child(1) &{
            margin-top: 40px;
        }
    }
    &_text {
        // font-size:16px;
        margin-bottom:27px;
        @include m1024 {
            margin-bottom: 65px;
        }
    }
    figure {
        img {
            margin-bottom: 20px;

            @include m1023{
                width: 100%;
            }
        }
        figcaption {
            font-size:16px;
            margin-bottom: 25px;
            @include m1024 {
                font-size:14px;
                margin-bottom: 60px;
            }
        }
    }
    &_button {
        text-align: center;

        .btn-3{
            @include m1023 {
                margin-bottom: 20px;
            }
        }
    }
}