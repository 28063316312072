.c-product {
    position: relative;

    &[data-cart="false"] {
        @extend .rounded2;
        box-shadow: -1.1px 5.9px 16.9px 1.1px rgba(141, 141, 141, 0.13);
        transition: box-shadow .4s ease-in-out;
        @include m1024 {
            box-shadow: -2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.13);
        }
        &:hover {
            @include m1024 {
                box-shadow: 2.1px 5.6px 16.9px 1.1px rgba(141, 141, 141, 0.5);
                .c-product__actions {
                    &-select {
                        text-decoration-color: transparent;
                        /*add those for opera and mozilla support*/
                        -webkit-text-decoration-color: transparent;
                        -moz-text-decoration-color: transparent;
                    }
                }
            }
        }

    }
    /* &[data-producttype="refund"] {
         .c-product__logo{

         }
     }*/
    /*  &[data-producttype="print"] {
          .c-product__logo {
              left: -2px;
              @include m1024 {
                  left: -5px;
              }
          }
      }*/

    &[data-producttype="print"] {
        .c-product__actions {
            &.skin-6 {
                background-color: #ff8200;
            }
            &.skin-7 {
                background: url('#{$imgPath}bgd-product.png') no-repeat;
                background-size: cover;

                h4, .c-product__actions-select {
                    color: $purple !important;
                }
            }

            &.skin-8 {
                background-color: #00B3CB;
            }

            &.skin-9 {
                background: url('#{$imgPath}bgd-product9.png') no-repeat;
                background-size: 100% 100%;

                h4, .c-product__actions-select {
                    color: $purple !important;
                }
            }

            &.skin-10 {
                background-color: #8F3977;

                .c-product__actions-type{
                    color: #fff;
                    &:before{
                        color: rgb(95, 32, 93);
                    }
                }

                &:after{
                    content: "";
                    position: absolute;
                    left: 75px;
                    top:0;
                    width: 140px;
                    height: 30px;
                    background: transparent url('#{$imgPath}offre-speciale.png') no-repeat center top;
                    background-size: contain;

                    @include m1023{
                        width: 100px;
                        height: 22px;
                    }
                }
            }
        }
    }

    &[data-producttype="refund"], &[data-producttype="print"] {
        .c-product__actions {

            &.skin-6 {
                background-color: #ff8200;
            }
            
            &.skin-7 {
                background: url('#{$imgPath}bgd-product.png') no-repeat;
                background-size: cover;

                h4, .c-product__actions-select {
                    color: $purple !important;
                }
            }

            &.skin-8 {
                background-color: #00B3CB;
            }

            &.skin-9 {
                background: url('#{$imgPath}bgd-product9.png') no-repeat;
                background-size: 100% 100%;

                h4, .c-product__actions-select {
                    color: $purple !important;
                }
            }

            &.skin-10 {
                background-color: #8F3977;

                .c-product__actions-type{
                    color: #fff;
                    &:before{
                        color: rgb(95, 32, 93);
                    }
                }

                &:after{
                    content: "";
                    position: absolute;
                    left: 75px;
                    top:0;
                    width: 140px;
                    height: 30px;
                    background: transparent url('#{$imgPath}offre-speciale.png') no-repeat center top;
                    background-size: contain;

                    @include m1023{
                        width: 100px;
                        height: 22px;
                    }
                }
            }
        }
    }

    &[data-cart="true"] {

        &[data-hasupdate="true"] {
            &[data-updated="true"],
            &[data-updated="false"] {
                .c-productInner {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background-color: $orange-dark;
                        @include m1024 {
                            top: 30px;
                            right: 30px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            &[data-updated="true"] {
                .c-productInner {
                    &:after {
                        background-color: $green;
                    }
                }
            }
            .c-product__content ~ * {
                @include m1023 {
                    position: relative;
                    top: -12px;
                }
            }
        }
        &[data-hasupdate="false"] {
            .c-product__content {
                @include m374 {
                    margin-right: 0;
                }
            }
        }

        .c-product {
            &Inner {
                overflow: visible;
                padding: 34px 14px;
                @include flex(space-between, center);
                @include m1023 {
                    padding: 19px 14px;
                    > * {
                        margin-left: 14px;
                        margin-right: 14px;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                @include m374 {
                    > * {
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
                @include m1024 {
                    cursor: default;
                    padding: 55px 75px 48px 70px;
                }
                &.select-- {
                    background-color: rgba($white, .5);
                    .loader {
                        .lds-ellipsis div {
                            background-color: $purple;
                        }
                    }
                }
            }
            &__content {
                max-width: 340px;
                justify-content: flex-start;
                padding: 0;
                flex: 0;
                margin: 0;
                margin-right: 14px;
                @include m1023 {
                    min-width: 115px;
                }
                .c-product__img {
                    margin: 0;
                    @include m1023 {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
                @include m1023 {
                    display: block;
                }
                @include m1024 {
                    flex: 1;
                    //flex: initial;
                    margin-left: -18px;
                    margin-right: -18px;
                    > * {
                        margin-left: 18px;
                        margin-right: 18px;
                    }
                }
            }

            &__img {
                width: 16%;
                max-width: 16%;
                @include m1023 {
                    min-width: 84px;
                    text-align: center;
                    h3 {
                        margin-top: 5px;
                        font-size: 14px;
                        @include bold;
                    }
                    img {
                        margin-right: auto;
                    }
                }
                @include m374 {
                    min-width: 62px;
                    h3 {
                        font-size: 12px;
                    }
                }
            }
            &__desc {
                @include m1023 {
                    min-width: 90px;
                    // margin-left: 12px;
                    margin: 8px 0 0 !important;
                    text-align: center;
                }
            }
            &__price {
                flex: 0 1 14%;
                text-align: center;
                font-size: 17px;
                @include extraBold;
                @include m1024 {
                    font-size: 26px;
                }
                span {
                    margin: 0;
                    display: block;
                    white-space: nowrap;
                    font-size: 9px;
                    @include semiBold;
                    @include m1024 {
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &Inner {
        position: relative;
        overflow: hidden;
        @include flex(space-between, initial);
        min-height: 118px;
        @extend .rounded2;
        background-color: $white;
        @include m1024 {
            min-height: 195px;
        }

        &.select-- {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //background-color: rgba($purple, .8);
            background-color: rgba(136, 100, 138, .8);
            transition: opacity .35s ease-in-out, visibility 0s ease-in-out .35s;
            &[data-selected="true"] {
                opacity: 1;
                visibility: visible;
                transition: opacity .35s ease-in-out;
            }
            .c-product__content {
                @include flex(center, center, column);
                color: $white;
                @include extraBold;
                text-align: center;
                @include m1024 {
                    font-size: 25px;
                }
            }
            .c-product__actions {
                @include flex(center, center, column);
                background-color: #88648a;
                //background-color: $purple-light;
            }
        }
        .loader {
            margin: 0;
            .lds-ellipsis div {
                background-color: $white;
            }
        }

    }
    &__content {
        position: relative;
        @include flex(center, center);
        // width: 62.6%;
        flex: 1;
        margin-left: -5px;
        margin-right: -5px;
        padding: 15px 8px;
        @include m1024 {
            margin-left: -7px;
            margin-right: -7px;
            padding: 22px 15px;
        }
        > * {
            margin-left: 5px;
            margin-right: 5px;
            @include m1024 {
                margin-left: 7px;
                margin-right: 7px;
            }
        }
    }
    &__logo {
        position: absolute;
        top: 8px;
        left: 8px;
        //max-width: 40px;
        @include m1024 {
            top: 15px;
            left: 15px;
            // max-width: 80px;

        }
        img {
            display: block;
            height: 100%;
            width: auto;
            //max-width: 40px;
            max-height: 20px;
            @include m1024 {
                // max-width: 80px;
                max-height: 40px;
            }
        }
    }
    &__img {
        // width: 115px;
        width: 20.6%;
        min-width: 73px;
        @include m1024 {
            width: 36%;
            min-width: 95px;
        }
        img {
            margin-right: auto;
            margin-left: auto;
            @include m1024 {
                margin-right: 0;
            }
            @include m1023 {
                max-height: 65px;
            }
        }
    }
    &__desc {
        h3 {
            font-size: 14px;
            @include bold;
            line-height: 1.07;
            word-break: break-word;
            @include m1024 {
                font-size: 17px;
            }
        }

        p {
            font-size: 10px;
            @include semiBold;
            line-height: 1.29;
            @include m1024 {
                font-size: 16px;
            }
        }
    }

    &__info {
        @include m1023 {
            // flex: 2;
            text-align: center;
        }
    }
    &__info,
    &__delete {
        cursor: pointer;
        .icon-info,
        .icon-delete {
            font-size: 17px;
            @include m1024 {
                font-size: 30px;
            }
        }
    }

    &__actions {
        @include flex(space-between, initial, column);
        width: 35.2%;
        max-width: 178px;
        padding: 5px 10px 5px 10px;
        background-color: $green;
        @include m1024 {
            width: 37.4%;
            padding: 10px;
        }
        h4, &-select {
            text-align: center;
        }

        h4 {
            font-size: 27px;
            color: $white;
            @include extraBold;
            @include m1024 {
                font-size: 33px;
                margin-bottom: 3px;
            }

            span {
                margin-top: -2px;
                margin-bottom: 10px;
                display: block;
                white-space: nowrap;
                font-size: 14px;
                @include m1024 {
                    margin-top: -4px;
                    font-size: 20px;
                }
            }
        }
        &-select {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
            color: $white;
            text-decoration: underline;
            font-size: 14px;
            @include semiBold;
            transition: all .25s ease-in-out;
            @include m1023 {
                border-bottom: 1px solid $white;
            }
            @include m1024 {
                margin-bottom: 15px;
                font-size: 20px;
                &:hover {
                    text-decoration-color: transparent;
                }
            }
        }
        &-type {
            @include flex(flex-start, center, row);
            font-size: 7px;
            text-transform: uppercase;
            @include semiBold;
            @include m1024 {
                font-size: 11px;
                cursor: pointer;
            }
            &:before {
                @include flex (center, center);
                min-width: 25px;
                width: 25px;
                height: 25px;
                margin-right: 2px;
                border-radius: 100%;
                background-color: $white;
                @extend .icomoon;
                @extend .icon-print;
                font-size: 20px;
                @include m1024 {
                    min-width: 35px;
                    width: 35px;
                    height: 35px;
                    margin-right: 5px;
                    font-size: 26px;
                }
            }
            &.print-- {
                &:before {
                    @extend .icon-print;
                }
            }
            &.refund-- {
                &:before {
                    @extend .icon-cashback;
                }
            }
        }
    }

    .error-- {
        margin-top: -30px;
        position: relative;
        z-index: 2;
        padding: 0 14px;
        @include m1024 {
            padding-left: 70px;
            padding-right: 75px;
        }
    }
}

.cart__products-list.refund-- {
    .c-product[data-cart="true"] .c-product__content {
        @include m1023 {
            min-width: 90px;
        }
    }
}