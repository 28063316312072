.t-mentions {
    
    h1 {
        margin-bottom: 25px;
        @include m1024 {
            margin-bottom:60px;
        }
    }
    &_accordion {
        margin-bottom:30px;
        @include m1024 {
            max-width: 710px;
            margin:0 auto 50px auto;
        }
        .c-accordion_item {
            margin-bottom:20px;
            @include m1024 {
                margin-bottom:30px;
            }
        }
        .c-accordion_title {
            padding:5px 0;
            border-bottom:1px solid $purple;
            margin-bottom:10px;
            @include flex (space-between, center, row, nowrap);
            @include m1024 {
                cursor: pointer;
            }
            h2 {
                font-size:17px;
                @include black;
                margin:0!important;
                text-transform: none;
                @include m1024 {
                    font-size:23px;
                }
            }
        }
    }
    &_content {
        overflow: hidden;
        transition: all .3s ease-out;
        h3 {
            font-size:16px;
            @include black;
            margin-bottom:7px;
        }
        &>div {
            @include m1024 {
                margin-bottom:16px;
            }
        }
        p {
            font-size:16px;
        }
        strong {
            @include black;
        }
    }
    &_title {
        padding:5px 0;
        border-bottom:1px solid $purple;
        margin-bottom:10px;
        @include flex (space-between, center, row, nowrap);
        @include m1024 {
            cursor: pointer;
            margin-bottom:15px;
        }
        h2 {
            font-size:17px;
            @include black;
            margin:0!important;
            @include m1024 {
                font-size:23px;
            }
        }
    }
    &_button {
        font-size:9px;
        @include m1024 {
            font-size:13px;
        }
        &.up-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform:rotate(90deg);
            transition: all .1s ease-out;
        }
        &.down-- {
            @extend .icomoon;
            @extend .icon-arrow-rounded;
            transform:rotate(-90deg);
            transition: all .1s ease-out;
        }
    }
}