// COLORS
$black: #000000;
$grey-dark: #969696;
$white: #ffffff;
$grey: #e6e4e4;
$grey-medium: #d5d5d5;
$grey-light: #f1eeee;
$grey-very-light: #f9f8f8;
$pink: #ffa8b1;
$orange: #fdb793;
$orange-light: #fdd8c4;
$orange-dark: #ff774f;
$red: #ea4335;
$purple: #5f205d;
$purple-light: #af8fae;
$purple-dark: #170616;
$blue: #15aabf;
$blue-dark: #1f2d74;
$green: #80d5b9;

$level1: #ea4335;
$level2: #ff774f;
$level3: #ffea4f;
$level4: #45c663;
$level5: #177d2f;

// FONTS
$main-font: 'Nunito Sans', sans-serif;

//IMAGES
$imgPath: '../../assets/img/';
// Use : background-image: url('#{$imgPath}menu-wave-mini.svg');

// ICOMOON
$icon-bgd-1: "\e903";
$icon-bgd-2: "\e90f";
$icon-burger-mobile: "\e910";
$icon-burger-desktop: "\e911";
$icon-brands: "\e912";
$icon-economy: "\e913";
$icon-community: "\e914";
$icon-youtube: "\e915";
$icon-facebook-rounded: "\e916";
$icon-facebook: "\e919";
$icon-download: "\e917";
$icon-pin: "\e918";
$icon-money: "\e91a";
$icon-arrow-rounded: "\e91b";
$icon-cashback: "\e91c";
$icon-tips: "\e91d";
$icon-eye: "\e91e";
$icon-star: "\e91f";
$icon-talk: "\e920";
$icon-recycle: "\e921";
$icon-down: "\e922";
$icon-photo: "\e923";
$icon-questions: "\e924";
$icon-eye-no: "\e925";
$icon-logout: "\e926";
$icon-notif: "\e927";
$icon-favoris: "\e928";
$icon-share: "\e929";
$icon-what: "\e92a";
$icon-info-2: "\e92b";
$icon-player: "\e92c";
$icon-what-2: "\e92d";
$icon-comment: "\e92e";
$icon-up: "\e92f";
$icon-favoris-on: "\e930";
$icon-modify: "\e931";
$icon-delete: "\e932";
$icon-caddie: "\e900";
$icon-arrow: "\e901";
$icon-home: "\e902";
$icon-hand: "\e904";
$icon-close: "\e905";
$icon-print: "\e906";
$icon-apple: "\e907";
$icon-search: "\e908";
$icon-basket: "\e909";
$icon-profil-on: "\e90a";
$icon-profil: "\e90b";
$icon-info: "\e90c";
$icon-check: "\e90d";
$icon-check-2: "\e933";
$icon-time: "\e90e";
$icon-twitter: "\e934";
$icon-share-email:"\e935";
$icon-share-fbk: "\e936";
$icon-coming-soon: "\e937";
$icon-what-2-on:"\e938";
$icon-basket-on:"\e939";