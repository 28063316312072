.t-tips {
    .main {
        padding-bottom: 92px;
    }

    .c-listUniverse {
    	&_item {
	    	background-color: $pink;
			margin-right: 0;
			width: 190px;
			transition: background-color 0.3s, transform 0.3s ease;

	    	@include m1024 {
				width: calc(25% - 13px);
			}
	    	@include m1023 {
				max-width: 30%;
			}

			&.active-- {
	            background-color: $white;
	        }
		}

		&_slider {
			@include m1023 {
				margin-right: -20px;
				margin-left: 0;
			}
			.c-listUniverse_item {
				height: auto;
			}
		}
    }
	.c-listArticle {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.c-article {
			margin-bottom:30px;
			margin-right:30px;
			width: calc(50% - 15px);
			height: auto;
			@include m1024 {
				width: calc(25% - 23px);
				&:nth-child(4n) {
					margin-right:0;
				}
			}
			
			@include m1023 {
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}

	.c-pagination {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top:30px;
		ul {
			display: flex;
			flex-direction: row;
			a {
				margin:0 5px;
				color:$purple;
				padding:3px;
				border-radius: 6px;
				transition: all .3s ease-in-out;
				@include m1024 {
					cursor: pointer;
				}
				&.current-- {
					cursor: default;
					background:$purple;
					color:$white;
				}
				&:hover {
					@include m1024 {
						background:$purple;
						color:$white;
					}
				}
			}
		}
		&_content {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 50px;
		}
		&_next {
			i {
				margin:0 10px;
			}
		}
		&_prev {
			i {
				display: inline-block;
				margin:0 10px;
				transform: rotate(180deg);
			}
		}
	}
}